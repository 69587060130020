import  { Component } from 'react';

class LimeCallClientClass extends Component {
    // constructor(props) {
    //     super(props);
    // }
    
    triggerTab = (tab) =>{
        /*
        clicktocall
        callnow
        calllater
        leavemessage
        */
        if(window.limeCallTemplate === 1){
            if(document.getElementById(tab).parentElement.style.display!=="none"){
                if(document.getElementById('limecall-widget-modal').style.display==="none"){
                  document.getElementById('limeCallWid').click();
                }
                document.getElementById(tab).click();
              }else{
                console.warn("Looks like "+tab+" tab isn't enabled for your widget")
              }
        }else{
            console.warn("Tab triggering works for Classic template only")
        }
    }

    triggerWidget = () =>{
        if(document.getElementById('limecall-widget-modal').style.display==="none"){
            document.getElementById('limeCallWid').click();
          }
    }
    
    renderLibrary = () =>{
      var limeCallClient = {
        triggerTab : this.triggerTab,
        triggerWidget : this.triggerWidget
      }
      return limeCallClient;
    }
  
    render() {
      return null;
    }
  }
  

export default LimeCallClientClass;
