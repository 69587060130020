import moment from "moment";
import{
  sendTrackingData
} from "../WidgetAPICalls";

export const widgetFormInitialState = {
  phoneNumberIsValid: false,
  phoneNumber: null,
  callId: 0,
  errors: false,
  countdownBegun: false,
  currentCallComplete: false,
  callStatusMessage: null,
  scheduleTime: null,
  scheduleDate: null,
  showFeedbackForm: false,
  statusCode: ""
};

export const initialState = {
  phoneNumberIsValid: false,
  countryDialCode: '',
  phoneNumber: null,
  countryData: {},
  feedbackIsOpen: false,
  rating: 0,
  email: "",
  callId: 0,
  apiStatus: null,
  errors: false,
  countdownBegun: false,
  apiCallComplete: false,
  serverMessage: null,
  workingFrom: null,
  workingTill: null
};

export const randomMessages = [
  { status: 'initiated', message: "Hold on! We are finding teams to place a call..." },
  { status: 'ringing', message: "We're calling you on the provided number..." },
  { status: 'failed', message: "We couldn't get hold of you now!" },
  { status: 'in-progress', message: "Hola! The call is in progress." },
  { status: 'completed', message: "Thanks for using limecall, please provide us your feedback & email!" },
  { status: 'no-answer', message: "Unfortunately, we were unable to talk to you. Please leave your feedback." },
  { status: 'error', message: "Unfortunately, we are unable to make this call!" }
];

export const callStatusMessages = [
  
  {
    customer_status:'customer_connected',
    agent_status: '',
    final_api_status: 'customer_connected',
    show_feedback: 0,
    show_reconnect: 0,
    message: "Looks like we have found you..."
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "agent_connected",
    final_api_status: "completed ",
    show_reconnect: 0,
    show_feedback: 0,
    message: "Thanks for connecting with us"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "connecting_agent",
    final_api_status: "connecting_agent",
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "connecting_agent",
    final_api_status: "customer_call_completed",
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "",
    final_api_status: "failed_to_connect_agent",
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "failed_to_connect_agent",
    final_api_status: "failed_to_connect_agent",
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "connecting_agent",
    final_api_status: "failed_to_connect_agent",
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:"customer_connected",
    agent_status: "agent_call_completed",
    final_api_status: "agent_connected",
    show_feedback: 1,
    show_reconnect: 0,
    message: "Thanks for connecting with us"
  },

  {
    customer_status:"customer_connected",
    agent_status: "agent_call_completed",
    final_api_status: "completed",
    show_feedback: 1,
    show_reconnect: 0,
    message: "Thanks for connecting with us"
  },
  {
    customer_status:"customer_call_completed",
    agent_status: "agent_call_completed",
    final_api_status: "completed",
    show_feedback: 1,
    show_reconnect: 0,
    message: "Your call has ended, please take time to rate it."
  },
  {
    customer_status:'connecting_customer',
    agent_status: null,
    final_api_status: 'connecting_customer',
    show_feedback: 0,
    show_reconnect: 0,
    message: "Hold on! We are finding teams to place a call..."
  },
  {
    customer_status:'failed_to_connect_customer',
    agent_status: '',
    final_api_status: 'failed_to_connect_customer',
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry, we are unable to reach your phone .  Try again or  Leave us a message"
  },
  {
    customer_status:'customer_connected',
    agent_status: 'no_agents_available',
    final_api_status: 'no_agents_available',
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:'customer_connected',
    agent_status: 'connecting_agent',
    final_api_status: 'connecting_agent',
    show_feedback: 0,
    show_reconnect: 0,
    message: "We are currently connecting you to our team"
  },
  {
    customer_status:'customer_connected',
    agent_status: 'failed_to_connect_agent',
    final_api_status: 'failed_to_connect_agent',
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry our agents are currently unavailable.  Schedule a call or Leave a message"
  },
  {
    customer_status:'customer_connected',
    agent_status: 'agent_connected',
    final_api_status: 'agent_connected',
    show_feedback: 0,
    show_reconnect: 0,
    message: "You are speaking with us. Leave your information (Name, Email etc)"
  },
  {
    customer_status:'customer_call_completed',
    agent_status: 'agent_connected',
    final_api_status: 'completed',
    show_feedback: 1,
    show_reconnect: 0,
    message: "Thanks For connecting with us"
  },
  {
    customer_status:'customer_connected',
    agent_status: 'agent_call_completed',
    final_api_status: 'completed',
    show_reconnect: 0,
    show_feedback: 1,
    message: "Thanks For connecting with us"
  },
  {
    customer_status:'customer_disconnected_call',
    agent_status: '',
    final_api_status: 'customer_disconnected_call',
    show_feedback: 0,
    show_reconnect: 0,
    message: "Sorry, we are unable to reach your phone .  Try again or  Leave us a message"
  },
  {
    customer_status:'customer_connected',
    agent_status: null,
    final_api_status: 'customer_connected',
    show_feedback: 0,
    show_reconnect: 0,
    message: "Looks like we have found you..."
  },
  {
    customer_status:'failed_to_connect_customer',
    agent_status: null,
    final_api_status: 'failed_to_connect_customer',
    show_feedback: 0,
    show_reconnect: 1,
    message: "Sorry, we are unable to reach your phone .  Try again or  Leave us a message"
  }
];

export const options = [
  { value: 'today', label: 'Today' },
  { value: 'tomorrow', label: 'Tomorrow' }
];

export const loadJSONP = (url, callback) => {
  const ref = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
  ref.parentNode.insertBefore(script, ref);
  script.onload = () => {
    script.remove();
  };
};

export const lookup = (callback) => {
  loadJSONP('https://ipinfo.io', 'sendBack');
  window.sendBack = (resp) => {
    const countryCode = (resp && resp.country) ? resp.country : '';
    callback(countryCode);
  };
};

export const getNextPossibleDateAndTime = (timeSlots) => {
  const dateTimes = [];
  let scheduleTime = null;
  let scheduleDate = null;
  Object.keys(timeSlots).map(day => timeSlots[day].map(time => dateTimes.push(`${day} ${time}`)));
  let nextDate = dateTimes.map(s => moment(s)).sort(m => m.valueOf()).find(m => m.isAfter(new Date(), "minute"));
  const isSameDay = true;//moment(nextDate).isSame(new Date(), "day");
  nextDate = moment(nextDate).format("YYYY-MM-DD HH:mm:ss");
  [scheduleDate, scheduleTime] = nextDate.split(" ");
  return ({
    scheduleDate,
    scheduleTime,
    isSameDay
  });
};

export const validateForms = (requiredFields, submittedValues) => {
  let formIsValid = true;
  for (let i = 0; i < requiredFields.length; i++) {
    if (!submittedValues[requiredFields[i]]) {
      formIsValid = false;
      break;
    }
  }
  return formIsValid;
};

export const showFeedbackFormForStatusCodes = ["no-answer", "error", "failed"];

export const getAllowedCountries = (countries) => {
  const allowedCountries = countries.map(countryCode => countryCode.toLowerCase());
  return allowedCountries;
};

export const processFloatLabels = () => {
  const FloatLabel = (() => {

    // add active class and placeholder 
    const handleFocus = (e) => {
      document.getElementById('poweredbyContainer').classList.add('hiddenVisibility');
      const target = e.target;
      target.parentNode.classList.add('active');
      target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
    };
    
    // remove active class and placeholder
    const handleBlur = (e) => {
      document.getElementById('poweredbyContainer').classList.remove('hiddenVisibility');
      const target = e.target;
      if(!target.value) {
        target.parentNode.classList.remove('active');
      }
      target.removeAttribute('placeholder');    
    };  
    
    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('input');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);    
    };
    
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.float-container');
      
      floatContainers.forEach((element) => {
        if (element.querySelector('input').value) {
            element.classList.add('active');
        }      
        
        bindEvents(element);
      });
    };
    
    return {
      init: init
    };
  })();
  const TextareaFloatLabel = (() => {

    // add active class and placeholder 
    const handleFocus = (e) => {
      document.getElementById('poweredbyContainer').classList.add('hiddenVisibility');
      const target = e.target;
      target.parentNode.classList.add('active');
      target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
    };
    
    // remove active class and placeholder
    const handleBlur = (e) => {
      document.getElementById('poweredbyContainer').classList.remove('hiddenVisibility');
      const target = e.target;
      if(!target.value) {
        target.parentNode.classList.remove('active');
      }
      target.removeAttribute('placeholder');    
    };  
    
    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('textarea');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);    
    };
    
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.textarea-float-container');
      
      floatContainers.forEach((element) => {
        if (element.querySelector('textarea').value) {
            element.classList.add('active');
        }      
        
        bindEvents(element);
      });
    };
    
    return {
      init: init
    };
  })();
  // console.log("triggering floats");
  setTimeout(
    function() {
        // console.log("loading float variables");
        FloatLabel.init();
        TextareaFloatLabel.init(); 
      },
      // .bind(this),
      2500
  );

}

export const processSmartFloatLabels = () => {
  const FloatLabel = (() => {

    // add active class and placeholder 
    const handleFocus = (e) => {
      const target = e.target;
      target.parentNode.classList.add('active');
      target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
    };
    
    // remove active class and placeholder
    const handleBlur = (e) => {
      const target = e.target;
      if(!target.value) {
        target.parentNode.classList.remove('active');
      }
      target.removeAttribute('placeholder');    
    };  
    
    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('input');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);    
    };
    
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.float-containerSmart');
      
      floatContainers.forEach((element) => {
        if (element.querySelector('input').value) {
            element.classList.add('active');
        }      
        
        bindEvents(element);
      });
    };
    
    return {
      init: init
    };
  })();
  const TextareaFloatLabel = (() => {

    // add active class and placeholder 
    const handleFocus = (e) => {
      const target = e.target;
      target.parentNode.classList.add('active');
      target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
    };
    
    // remove active class and placeholder
    const handleBlur = (e) => {
      const target = e.target;
      if(!target.value) {
        target.parentNode.classList.remove('active');
      }
      target.removeAttribute('placeholder');    
    };  
    
    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('textarea');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);    
    };
    
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.textarea-float-containerSmart');
      
      floatContainers.forEach((element) => {
        if (element.querySelector('textarea').value) {
            element.classList.add('active');
        }      
        
        bindEvents(element);
      });
    };
    
    return {
      init: init
    };
  })();
  // console.log("triggering floats");
  setTimeout(
    function() {
        // console.log("loading float variables");
        FloatLabel.init();
        TextareaFloatLabel.init(); 
      },
      // .bind(this),
      100
  );

}

export const triggerPublicEvent = (e,t) =>{
    var n, i, a;
    a = "limecall:" + e;
    if("function" == typeof window.CustomEvent){
      i =new CustomEvent(a, {
        detail: t
      }) 
     } else{
        if(document.createEvent) {
          n = document.createEvent("HTMLEvents")
          i = (document.initEvent(a, !0, !0), n)
        }else{
          if(document.createEventObject){
            ((n = document.createEventObject()).eventType = a, n).eventName = a
          }
        }  
     } 
     if(document.dispatchEvent) {
        document.dispatchEvent(i)
      } else{
        document.fireEvent && document.fireEvent("onlimecall" + e, i)
      } 
}

export const pushTrackingDataForLead = (lead_id,widget_settings) => {
  let userAgent = navigator.userAgent;
  let cookieName = "limecall_"+window.location.hostname;
  var browsingHistory = [];
  if(getCookie(cookieName)!==null){
    browsingHistory = JSON.parse(getCookie(cookieName));
  }
  let pageSessionStartTime = moment(widget_settings.pageSessionStartTime);
  let rightNow = moment();
  let lastInteraction = moment().format("YYYY-MM-DD HH:mm:ss");
  let timediff =  rightNow.diff(pageSessionStartTime,"seconds").toString();
  let trackingData = {
    lead_id:lead_id,
    time_on_site:timediff,
    last_interaction:lastInteraction,
    browsing_history:browsingHistory,
    user_agent:userAgent
  }

  sendTrackingData(trackingData).then(() => {
  }).catch((error) => {
    console.log(error);
  });
}

export const getCookie = (name) =>{

  var dc = document.cookie;
  var prefix = name + "=";
  var end;
  var begin = dc.indexOf("; " + prefix);
  if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
      else{
          var oneCookie = dc.indexOf(';', begin);
          if(oneCookie === -1){
              end = dc.length;
          }else{
              end = oneCookie;
          }
          return dc.substring(begin, end).replace(prefix,'');
      } 

  }
  else
  {
      begin += 2;
      end = document.cookie.indexOf(";", begin);
      if (end === -1) {
          end = dc.length;
      }
      var fixed = dc.substring(begin, end).replace(prefix,'');
  }
  // return decodeURI(dc.substring(begin + prefix.length, end));
  return fixed;
} 