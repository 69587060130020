import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';
import StarRatingComponent from 'react-star-rating-component';
import { 
  sendFeedback,
  sendCustomFieldData,
  sendAnalyticsEvent,
  getAPIkey
 } from "../WidgetAPICalls";
import CustomFields from './CustomFields';
import { showFeedbackFormForStatusCodes,processFloatLabels } from "../HelperMethods";

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      email: this.props.widgetSettings.email,
      username: this.props.widgetSettings.name,
      feedback:null,
      error: false,
      formHolderClass: "formHolder feedback"
    };
  }

  takeFeedback = (nextValue) => {
    this.setState({ rating: nextValue });
  }

  onValueChange = (event) => {
    if(event.target.name==="email"){
      this.setState({
        error_email: false
      })
      this.props.widgetSettings.email = event.target.value;
      if(event.target.value===null || event.target.value===''){
        this.props.widgetSettings.email = null;
      }
    }else if(event.target.name==="username"){
      this.props.widgetSettings.name = event.target.value;
    }
    
      this.setState({
        [event.target.name]: event.target.value,
        error: false
      })
      if(event.target.value==='' || event.target.value===null){
        this.setState({
          [event.target.value]: null
        })
      }
    
  }

  

  resetCallState(){
    this.props.resetCallState()
    this.setState({
      showLoader:false,
      feedbackSubmited:false
    })
    this.props.onFeedbackDone();
  }

  makeFormUnscrollable = () =>{
    this.setState({
      formHolderClass:'formHolder feedback overflowed-y'
    })
  }

  makeFormScrollable = () =>{
    this.setState({
      formHolderClass:'formHolder feedback'
    })
  }

  checkCustomRequiredCheckup(currentCallType){
    var tmpField
    if(currentCallType==="digital_call"){
      if(this.props.enable_cf_on_after_digitalcall){
        for(let tmpI = 0; tmpI<this.props.custom_fields_after_digitalcall_data.length; tmpI++){
          tmpField = this.props.custom_fields_after_digitalcall_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }else{
      if(this.props.enable_cf_on_after_call){
        for(let tmpI = 0; tmpI<this.props.custom_fields_after_call_data.length; tmpI++){
          tmpField = this.props.custom_fields_after_call_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    
    return true;
  }

  handleCustomDropdownChange = (name,selectedOption) =>{
    this.setState({
      customValues: {...this.state.customValues, [name]:selectedOption.value},
      error: false
    })
  }

  customFieldValueChange = (event) => {
    let tmpA = event.target.name
    let tmpB = event.target.value
    this.setState({
        customValues: {...this.state.customValues, [tmpA]:tmpB},
        error: false
    })
  }
  
  validateEmail = () => {
    if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
      return false;
    }
    return true;
  }

  validateFeedbackForm = (currentCallType) => {
    if(currentCallType==="digital_call"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="after_call"){
          if(!this.state.email || this.state.email===undefined || this.state.email===''){
            this.setState({
              error: true
            });
            return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="after_call"){
          if(!this.state.username){
            this.setState({
              error: true
            });
            return false;
          }
      }
    }else{
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="after_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
            this.setState({
              error: true
            });
            return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="after_call"){
          if(!this.state.username){
            this.setState({
              error: true
            });
            return false;
          }
      }
    }
      
    return true;
  }

  submitFeedback = () => {
    this.props.pushButtonEventToGA();
      if (this.validateFeedbackForm(this.props.currentCallType) ){
        if(this.state.email!==undefined && this.state.email!==''){
          if(!this.validateEmail()){
            this.setState({
              error_email:true
            })
            return;
          }
        }
        
        if(!this.checkCustomRequiredCheckup(this.props.currentCallType)){
          // console.log("custom form error");
          this.setState({
            error:true
          })
          return;
        }

        if(this.state.customValues!==undefined){
          if(this.props.widgetSettings.google_analytics===1){
            var apikey = null;
            var agent_id = null;
            if(this.props.widgetSettings.widget_type===2){
              apikey = this.props.widgetSettings.pId;
              agent_id = getAPIkey();
            }else{
              apikey = getAPIkey();
            }
            const params ={
              type: 'custom_field',
              pId:apikey,
              userIp: this.props.widgetSettings.ip_address
            };
            sendAnalyticsEvent(params);
          }
        }

        // console.log(this.props.currentCallType+" current call type");
        if(this.props.currentCallType==="normal_call"){
          const postData = {
            lead_id: this.props.callId,
            email: this.state.email,
            name: this.state.username,
            rating: this.state.rating,
            feedback: this.state.feedback,
          };
          this.setState({
            showLoader:true
          })
          if(this.props.enable_cf_on_after_call){
            const afterCallCustomData = {
              call_id:this.props.callId,
              ...this.state.customValues
            };
            sendCustomFieldData(afterCallCustomData).then(() => {
            }).catch((error) => {
              console.log(error);
            });
          }
          sendFeedback(postData).then(() => {
            this.setState({
              showLoader:false,
              feedbackSubmited:true
            })
            //this.props.onFeedbackDone();
          }).catch(() => {
            this.props.onFeedbackDone();
          });
        }else if(this.props.currentCallType==="digital_call"){
          const postData = {
            random_id: this.props.callId,
            email: this.state.email,
            name: this.state.username,
            rating: this.state.rating,
            feedback: this.state.feedback,
          };

          this.setState({
            showLoader:true
          })
          if(this.props.enable_cf_on_after_digitalcall){
            const afterCallCustomData = {
              random_id:this.props.callId,
              ...this.state.customValues
            };
            sendCustomFieldData(afterCallCustomData).then(() => {
            }).catch((error) => {
              console.log(error);
            });
          }
          sendFeedback(postData).then(() => {
            this.setState({
              showLoader:false,
              feedbackSubmited:true
            })
            //this.props.onFeedbackDone();
          }).catch(() => {
            this.props.onFeedbackDone();
          });
        }
      
    } else {
      this.setState({
        error: true
      });
    }
  }

  renderFeedbackForm = () => (
    (this.state.showLoader)?
    (<div style={{width:'100%'}}><img alt="loader" src="https://cdn-images-1.medium.com/max/1600/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" style={{width:'inherit'}} /></div>)
    :
    (this.state.feedbackSubmited)?
      <div>
        <h3 style={{textAlign:'center',fontFamily:(this.props.widgetSettings.widget_font), fontSize:"26px"}} className="limecall-title">Thanks for your feedback.</h3>
        <div className="button-group" style={{marginTop:'10px'}}>
                        <button  onClick={() => this.resetCallState()} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
                            <span className="button-text" >Back</span>
                        </button>
                        </div>
                        
      </div>
    :
    (<div className={this.state.formHolderClass}>
    <div className="tel-input form__form-group">
    <div className="call_now__top">
      <h3 className="limecall-title" style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"26px", marginBottom:"0px", marginTop:"-10px"}}>{this.props.scheduleCall ? "Call is completed." : null} How would you like to rate this call?</h3> 
    </div>
    {!this.props.scheduleCall && !showFeedbackFormForStatusCodes.includes(this.props.statusCode) ? (
      <div className="feedbackForm form__form-group">
        <StarRatingComponent
          name="rate-lime-call"
          value={this.state.rating}
          starCount={5}
          onStarClick={this.takeFeedback}
        />
      </div>
    ) : null}
    {(this.props.currentCallType==="digital_call")?
      <React.Fragment>
        {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="after_call" )? [
          (this.props.widgetSettings.email_required_before_call===1) ? (
          <div id="floatContainer17" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail17" />
          </div>
          ) : (
          <div id="floatContainer17" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail17" />
          </div>
          )
        ] : null}

        {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="after_call")? [
          (this.props.widgetSettings.full_name_required_before_call===1) ? (
          <div id="floatContainer18" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName18" />
          </div>
          ): (
          <div id="floatContainer18" className="float-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName18" />
          </div>
          )
        ] : null}

        {(this.props.enable_cf_on_after_digitalcall)?(
            <CustomFields
              widgetSettings={this.props.widgetSettings}
              customFieldsData = {this.props.custom_fields_after_digitalcall_data}
              customFieldValueChange = {this.customFieldValueChange.bind(this)}
              makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
              makeFormScrollable = {this.makeFormScrollable.bind(this)}
              handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
              customValues = {this.state.customValues}
              currentScreen="after_call"
            />
          ):null}
      </React.Fragment>
    :
      <React.Fragment>
        {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="after_call" )? [
          (this.props.widgetSettings.email_required_before_call===1) ? (
          <div id="floatContainer17" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail17" />
          </div>
          ) : (
          <div id="floatContainer17" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail17" />
          </div>
          )
        ] : null}

        {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="after_call")? [
          (this.props.widgetSettings.full_name_required_before_call===1) ? (
          <div id="floatContainer18" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName18" />
          </div>
          ): (
          <div id="floatContainer18" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
            <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName18" />
          </div>
          )
        ] : null}

        {(this.props.enable_cf_on_after_call)?(
            <CustomFields
              widgetSettings={this.props.widgetSettings}
              customFieldsData = {this.props.custom_fields_after_call_data}
              customFieldValueChange = {this.customFieldValueChange.bind(this)}
              makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
              makeFormScrollable = {this.makeFormScrollable.bind(this)}
              handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
              customValues = {this.state.customValues}
              currentScreen="after_call"
            />
          ):null}
      </React.Fragment>
    }
    
    
    <div className="tel-input position-relative form-group">
      <div id="textareafloatContainer19" className="textarea-float-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
        <label for="feedback2">Please leave your feedback here.</label>
        <textarea style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px", paddingTop:"20px"}} rows="2" required  onChange={event => this.onValueChange(event)} name="feedback" data-placeholder="Please leave your feedback here." id="feedback2" >
        </textarea>
      </div>
    </div>
    {showFeedbackFormForStatusCodes.includes(this.props.statusCode)
      ? (
        <div style={{ marginBottom: '20px' }} className="animate fadeIn display-timer-message limecall-title">
          {this.props.callStatusMessage} Leave us your info, we&#39;ll reach out.
        </div>
      )
      : null}
    
    {this.state.error ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to contact you!</div> : null}
    {this.state.error_email ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
    <div className="button-group" style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
      <button onClick={this.submitFeedback} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
        <span className="button-text">Submit</span>
      </button>
    </div>
    </div>
  </div>)
  )

  render() {
    return (
      <React.Fragment>
        {this.renderFeedbackForm()}
        {processFloatLabels()}
      </React.Fragment>
    );
  }
}


export default FeedbackForm;
