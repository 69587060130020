import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Countdown from 'react-countdown-now';
import FeedbackForm from "./FeedbackForm";

import * as EmailValidator from 'email-validator';
import CustomFields from './CustomFields';
import {
  sendFeedback,
  sendCustomFieldData,
  sendAnalyticsEvent,
  getAPIkey
} from "../WidgetAPICalls";


class CountdownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      afterScheduleScreen:this.props.afterScheduleScreen,
      formHolderClass: "formHolder"
    }
  }

  renderer = ({ seconds, milliseconds }) => {
    const timeleft = 28 - seconds;
    const percentage = this.props.currentCallComplete ? '100.0' : ((timeleft / 28) * 100).toFixed(1);
    let formattedMilli = milliseconds === 0 ? '000' : milliseconds.toString();
    formattedMilli = formattedMilli.length === 2 ? `${formattedMilli}0` : formattedMilli;
    const formattedSeconds = seconds >= 10 ? seconds : `0${seconds}`;
    let progressbarStyles = {
      root: {},
      path: {
        stroke: this.props.widgetSettings.popup_timer_color,
        transition: 'stroke-dashoffset 0.5s ease 0s'
      },
      trail: {
        stroke: this.props.widgetSettings.popup_timer_color+"6b"
      },
      text: {
        fontWeight: '800',
        fontSize: '25px',
        fill: this.props.widgetSettings.popup_timer_color,
        animation: 'fadeout .5s 28s 1 linear'
      }
    };
    return (
      <div style={{width:'inherit'}}  >
        {(!this.props.thisCallStarted && !this.props.hideLoadingArea && (!this.props.afterScheduleScreen || this.state.afterScheduleScreenSubmit))?
          (!this.props.showLoader)
          ?((!(this.props.scheduleCallFormSecond || this.props.currentCallComplete)) || this.props.countdownBegun )?(
            <CircularProgressbar
              percentage={percentage}
              styles={progressbarStyles}
              initialAnimation
              strokeWidth={1}
              text={this.props.currentCallComplete ? `✔` : <React.Fragment><tspan>{formattedSeconds}:</tspan><tspan>{formattedMilli.slice(0, -1)}</tspan></React.Fragment>}
            />
          ):null
          :(this.props.currentCallType==="digital_call")?
          (<img src="https://assets.materialup.com/uploads/63bd53b1-446a-42d5-be93-5e2bcffb4345/preview.gif" alt="loader" style={{width:'130px', marginLeft:'-30px'}} />)
          : (<img src="https://cdn-images-1.medium.com/max/1600/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" alt="loader" style={{width:'inherit'}} />)
          : null
        }
       {( (this.props.countdownBegun && this.props.scheduleCallFormSecond && this.props.currentCallComplete && !this.props.showFeedbackForm && !this.props.thisCallStarted) || (this.props.showBackButton || this.state.showBackButton) )?(
        
        <div className="button-group" style={{marginTop:'10px'}}>
          <button  onClick={() => this.props.resetCallState()} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
            <span className="button-text" >Back</span>
          </button>
        </div>
      ):null}
    </div>
    );
  }
  
  handleCustomDropdownChange = (name,selectedOption) =>{
    this.setState({
      customValues: {...this.state.customValues, [name]:selectedOption.value},
      error: false
    })
  }

  makeFormUnscrollable = () =>{
    this.setState({
      formHolderClass:'formHolder overflowed-y'
    })
  }

  makeFormScrollable = () =>{
    this.setState({
      formHolderClass:'formHolder'
    })
  }

  onValueChange = (event) => {
    if(event.target.name==="email"){
      this.setState({
        error_email: false
      })
      this.props.widgetSettings.email = event.target.value;
      if(event.target.value===null || event.target.value===''){
        this.props.widgetSettings.email = null;
      }
    }else if(event.target.name==="username"){
      this.props.widgetSettings.name = event.target.value;
    }else if(event.target.name==="team"){
      this.props.widgetSettings.team = event.target.value;
    }
    
      this.setState({
        [event.target.name]: event.target.value,
        error: false
      })
      if(event.target.value==='' || event.target.value===null){
        this.setState({
          [event.target.value]: null
        })
      }
    
  }

  validateEmail = () => {
    if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
      return false;
    }
    return true;
  }

  validateBasicFormAfterSchedule = () => {
    if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="after_schedule"){
      if(!this.state.email || this.state.email===undefined || this.state.email===''){
          return false;
      }
    }
    if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="after_schedule"){
        if(!this.props.widgetSettings.name){
            return false;
        }
    }
    return true;
  }

  validateBasicFormDuringCall = (currentCallType) => {
    if(currentCallType==="digital_call"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="during_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
            return false;
        }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="during_call"){
          if(!this.props.widgetSettings.name){
              return false;
          }
      }
    }else{
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="during_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
            return false;
        }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="during_call"){
          if(!this.props.widgetSettings.name){
              return false;
          }
      }
    }
    return true;
  }

  sendrecord = () => {
    this.props.pushButtonEventToGA();
    const params = {
      lead_id: this.props.callId,
      name:!(this.props.widgetSettings.name)?' ':this.props.widgetSettings.name,
      email:!(this.props.widgetSettings.email)?' ':this.props.widgetSettings.email,
    }

    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicFormAfterSchedule()){
      // console.log('basic form error');
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckupAfterSchedule()){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    }

    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        var apikey = null;
        var agent_id = null;
        if(this.props.widgetSettings.widget_type===2){
          apikey = this.props.widgetSettings.pId;
          agent_id = getAPIkey();
        }else{
          apikey = getAPIkey();
        }
        const params ={
          type: 'custom_field',
          pId: apikey,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    if(this.props.enable_cf_on_after_schedule){
      const afterScheduleCustomData = {
        call_id:this.props.callId,
        ...this.state.customValues
      };
      sendCustomFieldData(afterScheduleCustomData).then(() => {
      }).catch((error) => {
        console.log(error);
      });
    }

    if(params){
      sendFeedback(params).then((response) => {
        this.setState({
          afterScheduleScreenSubmit:true,
          showBackButton:true
        })
        this.forceUpdate()
      })
    }
  }

  submitduringcall = () => {
    this.props.pushButtonEventToGA();
    if (this.validateBasicFormDuringCall(this.props.currentCallType) ){
      if(this.state.email!==undefined && this.state.email!==''){
        if(!this.validateEmail()){
          this.setState({
            error_email:true
          })
          return;
        }
      }

      if(!this.checkCustomRequiredCheckupDuringCall(this.props.currentCallType)){
        // console.log("custom form error");
        this.setState({
          error:true
        })
        return;
      }
      
      if(this.state.customValues!==undefined){
        if(this.props.widgetSettings.google_analytics===1){
          var apikey = null;
          var agent_id = null;
          if(this.props.widgetSettings.widget_type===2){
            apikey = this.props.widgetSettings.pId;
            agent_id = getAPIkey();
          }else{
            apikey = getAPIkey();
          }
          const params ={
            type: 'custom_field',
            pId:apikey,
            userIp: this.props.widgetSettings.ip_address
          };
          sendAnalyticsEvent(params);
        }
      }

      // console.log(this.props.currentCallType+" current call type");
      if(this.props.currentCallType==="normal_call"){
        const postData = {
          lead_id: this.props.callId,
          name:!(this.props.widgetSettings.name)?'':this.props.widgetSettings.name,
          email:!(this.props.widgetSettings.email)?'':this.props.widgetSettings.email,
        };
        this.setState({
          showLoader:true
        })
        if(this.props.enable_cf_on_during_call){
          const duringCallCustomData = {
            call_id:this.props.callId,
            ...this.state.customValues
          };
          sendCustomFieldData(duringCallCustomData).then(() => {
          }).catch((error) => {
            console.log(error);
          });
        }

        sendFeedback(postData, this.props.callId).then(() => {
          this.setState({
            showLoader:false,
            hideDuringCallForm:true
          })
          //this.props.onFeedbackDone();
        }).catch(() => {
          // this.props.onFeedbackDone();
        });
      }else if(this.props.currentCallType==="digital_call"){
        const postData = {
          random_id: this.props.callId,
          email: this.state.email,
          name: this.state.username,
        };

        this.setState({
          showLoader:true
        })
        if(this.props.enable_cf_on_during_digitalcall){
          const duringCallCustomData = {
            random_id:this.props.callId,
            ...this.state.customValues
          };
          sendCustomFieldData(duringCallCustomData).then(() => {
          }).catch((error) => {
            console.log(error);
          });
        }
        sendFeedback(postData, this.props.callId).then(() => {
          this.setState({
            showLoader:false,
            hideDuringCallForm:true
          })
          //this.props.onFeedbackDone();
        }).catch(() => {
          // this.props.onFeedbackDone();
        });
      }
    
  } else {
    this.setState({
      error: true
    });
  }
}

  reconnect = () => {
    // WidgetCallForm.setState({countdownBegun = false});
    this.props.widgetSettings.show_reconnect = false;
    this.props.resetCallState();
    this.forceUpdate();
  }

  triggerSchedule = () =>{
    this.props.resetCallState();
    this.props.widgetSettings.show_reconnect = false;
    this.props.toggle("calllater");
  }

  checkCustomRequiredCheckupAfterSchedule(){
    if(this.props.enable_cf_on_after_schedule){
      for(let tmpI = 0; tmpI<this.props.custom_fields_after_schedule_data.length; tmpI++){
        var tmpField = this.props.custom_fields_after_schedule_data[tmpI];
          if(tmpField['enabled']){
            if(tmpField['required']){
              if(tmpField['type']==="email-text-box"){
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                  return false;
                }
              }else{
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                  return false;
                }
              }
            }
        }
      }
    }
    return true;
  }

  checkCustomRequiredCheckupDuringCall(currentCallType){
    var tmpField;
    if(currentCallType==="digital_call"){
      if(this.props.enable_cf_on_during_digitalcall){
        for(let tmpI = 0; tmpI<this.props.custom_fields_during_digitalcall_data.length; tmpI++){
          tmpField = this.props.custom_fields_during_digitalcall_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }else{
      if(this.props.enable_cf_on_during_call){
        for(let tmpI = 0; tmpI<this.props.custom_fields_during_call_data.length; tmpI++){
          tmpField = this.props.custom_fields_during_call_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    
    return true;
  }

  customFieldValueChange = (event) => {
    let tmpA = event.target.name
    let tmpB = event.target.value
    this.setState({
        customValues: {...this.state.customValues, [tmpA]:tmpB},
        error: false
    })
  }
  showTimer = () => (
    <div>
      {this.props.show_reconnect ? 
      <div>
        <div className="animate fadeIn display-timer-message limecall-title">😔 Sorry, we were unable to connect the call.</div>
        <div className="button-group">
          <br/>
          <button onClick={this.reconnect} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
            <span className="button-text" >Reconnect</span>
          </button>
          <button onClick={this.triggerSchedule} style={{'backgroundColor': this.props.widgetSettings.popup_timer_color, 'borderColor': this.props.widgetSettings.popup_timer_color }}>
            <span className="button-text" >Schedule a call</span>
          </button>
        </div>
      </div>
      : (  
      <div className="timer-holder">
      <Countdown
        date={Date.now() + 28000}
        intervalDelay={0}
        precision={2}
        renderer={this.renderer}
      />
    </div>
      )}
    </div>
  )

  duringCallScreen = () => (
    <div>
      { ((this.props.thisCallStarted && this.props.duringCallScreen) && !this.state.hideDuringCallForm)?(
        <div className={this.state.formHolderClass}>
          {(this.props.currentCallType==="digital_call")?
            //digital call during call
            <React.Fragment>
            <div className="call_now__top" style={{fontFamily:this.props.widgetSettings.widget_font}}>
              <h4  style={{fontFamily:this.props.widgetSettings.widget_font }}>Let us know more about you</h4>
            </div>
  
            <div className="tel-input form__form-group" style={{fontFamily:this.props.widgetSettings.widget_font}}>
              <div className="form__form-group-field">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="during_call" )? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}
  
              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="during_call")? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer5" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName3" />
                </div>
                ): (
                <div id="floatContainer5" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName3" />
                </div>
                )
              ] : null}
  
              {(this.props.enable_cf_on_during_digitalcall)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_during_digitalcall_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="during_call"
                />
              ):null}
  
              </div>
            </div>
            {this.state.error ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
            {this.state.error_email ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            <div className="button-group" >
            <button onClick={this.submitduringcall} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color}}>
              <span className="button-text" >Submit</span>
            </button>
          </div>
          </React.Fragment>
          :
          //normal call during call
          <React.Fragment>
          <div className="call_now__top" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            <h4  style={{fontFamily:this.props.widgetSettings.widget_font }}>Let us know more about you</h4>
          </div>

          <div className="tel-input form__form-group" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            <div className="form__form-group-field">
            {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="during_call" )? [
              (this.props.widgetSettings.email_required_before_call===1) ? (
              <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
              </div>
              ) : (
              <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
              </div>
              )
            ] : null}

            {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="during_call")? [
              (this.props.widgetSettings.full_name_required_before_call===1) ? (
              <div id="floatContainer5" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName3" />
              </div>
              ): (
              <div id="floatContainer5" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName3" />
              </div>
              )
            ] : null}

            {(this.props.enable_cf_on_during_call)?(
              <CustomFields
                widgetSettings={this.props.widgetSettings}
                customFieldsData = {this.props.custom_fields_during_call_data}
                customFieldValueChange = {this.customFieldValueChange.bind(this)}
                makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                makeFormScrollable = {this.makeFormScrollable.bind(this)}
                handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                customValues = {this.state.customValues}
                currentScreen="during_call"
              />
            ):null}

            </div>
          </div>
          {this.state.error ? <div style={{ marginTop: '-15px', marginBottom: '20px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
          {this.state.error_email ? <div style={{ marginTop: '-15px', marginBottom: '20px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
          <div className="button-group" >
          <button onClick={this.submitduringcall} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color}}>
            <span className="button-text" >Submit</span>
          </button>
        </div>
        </React.Fragment>
          }
        
        </div>
        ) : null}
      
      <React.Fragment>
          {(this.props.showDisconnect)?
          (<div className="button-group" style={{marginTop:'10px'}}>
          <button  onClick={() => this.props.disconnectCall()} style={{'backgroundColor':'red', 'borderColor': 'red','borderRadius':'5px' }}>
            <span className="button-text" >Disconnect</span>
          </button>
        </div>)
          :null}
        </React.Fragment>
    </div>
  )
  atferScheduleScreen = () => (
    <div>
      { this.props.afterScheduleScreen && !this.state.afterScheduleScreenSubmit ? (
        <div className={this.state.formHolderClass}>
        <React.Fragment>
          <div className="call_now__top" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            <h4  style={{fontFamily:this.props.widgetSettings.widget_font }}>Let us know more about you</h4>
          </div>

          <div className="tel-input form__form-group" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            <div className="form__form-group-field">
            {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="after_schedule" )? [
              (this.props.widgetSettings.email_required_before_call===1) ? (
              <div id="floatContainer14" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail4" />
              </div>
              ) : (
              <div id="floatContainer14" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail4" />
              </div>
              )
             ] : null}

            {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="after_schedule")? [
              (this.props.widgetSettings.full_name_required_before_call===1) ? (
              <div id="floatContainer15" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName3" />
              </div>
              ): (
              <div id="floatContainer15" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName3" />
              </div>
              )
             ] : null}
            
            {(this.props.enable_cf_on_after_schedule)?(
              <CustomFields
                widgetSettings={this.props.widgetSettings}
                customFieldsData = {this.props.custom_fields_after_schedule_data}
                customFieldValueChange = {this.customFieldValueChange.bind(this)}
                makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                makeFormScrollable = {this.makeFormScrollable.bind(this)}
                handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                customValues = {this.state.customValues}
                currentScreen="after_schedule"
              />
            ):null}
            </div>
          </div>
          {this.state.error ? <div style={{ marginTop: '-15px', marginBottom: '20px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
          {this.state.error_email ? <div style={{ marginTop: '-15px', marginBottom: '20px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
          <div className="button-group" >
          <button onClick={this.sendrecord} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color}}>
            <span className="button-text" >Submit</span>
          </button>
        </div>
        </React.Fragment>
        </div>
        ) : null}

    </div>
  )

  render() {
    return this.props.showFeedbackForm || this.props.scheduleCall ? (
      <div className="animate fadeIn">
        <FeedbackForm
          statusCode={this.props.statusCode}
          callStatusMessage={this.props.callStatusMessage}
          scheduleCall={this.props.scheduleCall}
          widgetSettings={this.props.widgetSettings}
          pushButtonEventToGA={this.props.pushButtonEventToGA.bind(this)}
          callId={this.props.callId}
          custom_fields_after_call_data={this.props.custom_fields_after_call_data}
          enable_cf_on_after_call={this.props.enable_cf_on_after_call}
          custom_fields_after_digitalcall_data={this.props.custom_fields_after_digitalcall_data}
          enable_cf_on_after_digitalcall={this.props.enable_cf_on_after_digitalcall}
          currentCallType={this.props.currentCallType}
          onFeedbackDone={this.props.onFeedbackDone}
          resetCallState={this.props.resetCallState.bind(this)}
        />
        {this.props.scheduleCall ? (
          <div className="reschedule-block">
            <div className="animate fadeIn display-timer-message limecall-title">{this.props.callStatusMessage}</div>
            <a href="/" onClick={(e) => { e.preventDefault(); this.props.rescheduleCall(); }} className="reschedule-call">Reschedule</a>
          </div>
        ) : null}
      </div>
    ) : (
      <React.Fragment>
        {this.props.show_reconnect? null:
          <div className="animate fadeIn display-timer-message limecall-title">{this.props.callStatusMessage}</div>
        }
        {this.showTimer()}
        {this.duringCallScreen()}
        {this.atferScheduleScreen()}
      </React.Fragment>
    );
  }
}

export default CountdownTimer;
