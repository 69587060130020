import React from 'react';

import * as EmailValidator from 'email-validator';
import IntlTelInput from 'react-intl-tel-input';
import moment from "moment";
import { default as SelectTimezone } from "react-timezone-select";
import Select from "react-select";
import Pusher from 'pusher-js';
import { isEmpty, unset } from "lodash";
import {
  EmojiHappy,
  EmojiMad,
  EmojiNeutral,
  EmojiSad,
  EmojiSuperHappy,
} from "../HelperMethods/emoji";
import {
  widgetFormInitialState,
  pushTrackingDataForLead,
  getNextPossibleDateAndTime,
  callStatusMessages,
  triggerPublicEvent
} from "../HelperMethods";
import {
  loadTwilioClient,
  getAPIkey,
  getBaseURL,
  sendCustomFieldData,
  sendFeedback,
  requestCall,
  sendAnalyticsEvent,
  sendUTMdata,
  loadTimezonebasedSchedule
} from "../WidgetAPICalls";

import CustomFields from './CustomFields';
import Twilio from "twilio-client";


class ModernTemplate extends React.Component {
  state = {
    headingMain: "Hey 👋",
    headingSecondary: "Need help? We're here!"
  }

  constructor(props) {
    super(props);
    this.fontStyle = {
      fontFamily: this.props.widgetSettings.widget_font
    }
  }

  componentWillMount() {
    this.loadModernSettings();
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'eu',
      forceTLS: true,
      disableStats: true
    });
    this.pusher.connection.bind('error', function (err) {
      if (err.error.data.code === 4004) {
        console.log('Over limit!');
      }
    });

    loadTwilioClient().then((response) => {
      // console.log(response);
      this.device = new Twilio.Device(response.token, {
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });

      this.device.on('ready', function (device) {
        // console.log('Device Ready!');
      });

      this.device.on('error', function (error) {
        console.log('Device Error: ' + error.message);
        if (error.code === 31208) {
          this.microphoneBlocked()
        }
      }.bind(this));

      this.device.on('connect', (conn) => {
        // console.log('Successfully established call!');
        this.setState({
          // showLoader:false,
          showDisconnect: true,
          callStatusMessage: "Hold on! We are finding teams to place a call..."
          // callStatusMessage:this.props.widgetSettings.language_data["hola-finding-team-during-call-now"]
        });

      });

      this.device.on('disconnect', (conn) => {
        // console.log('Call ended.');
        // document.getElementById('button-call').style.display = 'inline';
        // document.getElementById('button-hangup').style.display = 'none';

        let finalCalldur = this.state.currentCallDuration;
        this.setState({
          finalCurrentCallDuration: finalCalldur
        })
        clearInterval(this.callTimerInterval)
        this.triggerFeedbackScreen();
      });

      this.device.on('incoming', function (conn) {
        // console.log('Incoming connection from ' + conn.parameters.From);
        // accept the incoming connection and start two-way audio
        conn.accept();
      });

    })
      .catch(function (err) {
        // console.log(err);
        // console.log('Could not get a token from server!');
      });
    this.loadIntercom();
  }

  fontStyle = {}

  match = (line, word) => {
    if (line === undefined) {
      return false;
    }
    return line.includes(word);
  }

  sleep = (ms) => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  triggererListener() {
    this.customTriggerAttacher();
    if (this.props.widgetSettings.triggers) {
      var device = 'desktop';
      if (navigator.userAgent.indexOf('Android') !== -1 || navigator.userAgent.indexOf('iPad') !== -1 || navigator.userAgent.indexOf('iPhone') !== -1) {
        device = 'mobile';
      }
      if (this.props.widgetSettings.mobile_and_desktop_targeting === 'both' || this.props.widgetSettings.mobile_and_desktop_targeting === device) {
        this.addWidgetPopupListeners();
      }
    }
  }

  addWidgetPopupListeners() {
    if (this.state.triggerAdded) {
      return;
    }
    this.setState({
      triggerAdded: true
    })
    // console.log("adding triggers");
    document.once_per_event = this.props.widgetSettings.triggers.trigger_once_per_session;
    if (this.props.widgetSettings.triggers.exit_intent_trigger) {
      // console.log("adding exit event trigger");
      this.addExitEventTrigger();
    }
    if (this.props.widgetSettings.triggers.time_trigger_btn) {
      // console.log("adding time event trigger");
      this.addTimeTriggerEvent(this.props.widgetSettings.triggers.time_trigger_value);
    }
    if (this.props.widgetSettings.triggers.scroll_trigger_btn) {
      // console.log("adding scroll event trigger");
      this.addScrollTriggerEvent(this.props.widgetSettings.triggers.scroll_trigger_value, this.props.widgetSettings.triggers.trigger_once_per_session);
    }
  }

  addTimeTriggerEventHandler() {
    if (!(document.once_per_session && document.triggerEventCalled === true)) {
      if (document.custom_triggers) {
        if (document.customTriggerAttacher) {
          return;
        }
      }
      document.customTriggerAttacher = true;
      // document.getElementById('limecall-widget-modal').style.display = 'flex'
      document.triggerEventCalled = true;
    }
  }
  addTimeTriggerEvent(triggerTime) {
    if (!(this.props.widgetSettings.triggers.trigger_once_per_session && document.triggerEventCalled === true)) {
      setTimeout(this.addTimeTriggerEventHandler, (triggerTime * 1000));
    }
  }
  addScrollTriggerEvent(scrollTime, once_per_event) {
    document.scrollTime = scrollTime;
    document.scrollTime = scrollTime;
    document.once_per_event = once_per_event;
    window.addEventListener('scroll', function (evt) {
      // console.log(this.state);
      // return;
      let bottomScrollPosition = document.body.offsetHeight - window.screen.height;
      let currentScrollPosition = window.pageYOffset;
      let scrollPosition = parseInt((currentScrollPosition / bottomScrollPosition) * 100);
      if (scrollTime === scrollPosition) {
        // console.log("triggered scroll event");
        // console.log("condition", !(document.once_per_event && document.triggerEventCalled))
        if (!(document.once_per_event && document.triggerEventCalled)) {
          document.triggerEventCalled = true;
          document.getElementById('limecall-widget-modal').style.display = 'flex'
        }
      }

    });
  }

  addExitEventTrigger() {

    return

    this.addEvent(document, "mouseout", function (event) {
      event = event ? event : window.event;
      var from = event.relatedTarget || event.toElement;
      if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
        if (!(document.once_per_event && document.triggerEventCalled)) {
          document.triggerEventCalled = true;
          document.getElementById('limecall-widget-modal').style.display = 'flex'

        }
      }
    });

  }

  toggleModal = () => {
    var apikey = getAPIkey();
    if (!this.props.modalIsOpen) {
      triggerPublicEvent('widgetOpened', null)
      if (this.props.widgetSettings.google_analytics === 1) {
        const params = {
          type: 'widget_opened',
          pId: apikey,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    } else {
      triggerPublicEvent('widgetClosed', null)
      if (this.props.widgetSettings.google_analytics === 1) {
        const params = {
          type: 'widget_closed',
          pId: apikey,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
      mainSelectionDone: false,
      headingMain: "Hey 👋",
      headingSecondary: "Need help? We're here!",
      askSelectCallType: false,
      askForTeamSelection: false,
      callRingingState: false,
      displayCallConnectedScreen: false,
      displayCallFeedbackScreen: false,
      displayCallFailedScreen: false,
      displayScheduleDateSelector: false,
      askForAdditionalDetails: false,
      displayScheduleConfirmationScreen: false,
      displayErrorScreen: false,
      askAdditionalInfoForCall: false,
      displayFeedbackSubmittedScreen: false,
    }));
    this.resetErrorState()
  }

  updateEvents = (data) => {
    // console.log(data);
    if (this.state.callId !== 0) {
      let message = "";
      let tmpFlag = false;
      let tmpFlag2 = false;
      if (data.customer_status === "customer_connected" && data.final_status === "agent_connected" && data.agent_status === "agent_connected") {
        let memberName = (data.member === undefined) ? "us" : ((data.member.name === undefined) ? "us" : data.member.name);
        let memberPic = getBaseURL() + "/storage/" + data.member.profile_image;
        let memberTitle = data.member.title;
        message = "You're connected and speaking to ";
        this.triggerCallConnectedScreen()

        // triggerPublicEvent('callStarted',null)
        var duringCall = false;
        if ((this.props.widgetSettings.email_enabled_before_call === 1 && this.props.widgetSettings.email_in_digital_calls_tab === 1 && this.props.widgetSettings.email_display_on_digital_call === "during_call") || (this.props.widgetSettings.full_name_enabled_before_call === 1 && this.props.widgetSettings.full_name_in_digital_calls_tab === 1 && this.props.widgetSettings.full_name_display_on_digital_call === "during_call")) {
          duringCall = true;
          // console.log("ths call needs to be show during call screen");
        }
        if (this.props.enable_cf_on_during_digitalcall === true) {
          duringCall = true;
        }
        this.setState({
          agent_name: memberName,
          agent_pic: memberPic,
          agent_title: memberTitle,
          thisCallStarted: true,
          headingMain: message,
          duringCallScreen: duringCall
        });

        // console.log("this call is started state ");
        // console.log("during call screeen status is  "+duringCall);
        // console.log("Your call is connected and you are speaking to agent "+memberName+".");

      } else {
        callStatusMessages.forEach(function (status) {
          data.agent_status = (data.agent_status === undefined) ? "" : data.agent_status;
          // console.log(data.call_status+'  vs '+status.customer_status+' customer status');
          // console.log(data.final_status+'  vs '+status.final_api_status+' final status');
          // console.log(data.agent_status+'  vs '+status.customer_service_status+' agent status');
          if (status.customer_status === data.customer_status && status.final_api_status === data.final_status && status.agent_status === data.agent_status) {
            message = status.message;
            //  console.log("----status------"+status.message)
            tmpFlag = status.show_reconnect;
            tmpFlag2 = status.show_feedback;
          }
        });
        this.setState({
          headingMain: message
        })
      }
      if (tmpFlag) {
        this.setState({
          show_reconnect: tmpFlag
        })
      }
      if (tmpFlag2) {
        this.setState({
          showFeedbackForm: tmpFlag2,
          thisCallStarted: false
        });
      }
      // console.log('customer_status : '+data.customer_status +" | agent_status : "+ data.agent_status +" | final_status: "+data.final_status);
      if (data.customer_status === "customer_connected" || data.customer_status === "failed_to_connect_customer") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
      }
      if (data.customer_status === "customer_connected" && data.agent_status === "failed_to_connect_agent") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        this.triggerCallFailed()
        triggerPublicEvent('callFailed', null)
      }
      if (data.customer_status === "customer_call_completed" && data.final_status === "failed_to_connect_agent") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed', null)
        this.triggerCallFailed()
      }
      if (data.customer_status === "customer_call_completed" && data.agent_status === "failed_to_connect_agent") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed', null)
        this.triggerCallFailed()
      }
      if (data.customer_status === "customer_connected" && data.agent_status === "agent_connected") {
        this.props.widgetSettings.askFieldsThatNotEntered = true;
      }
      if ((data.customer_status === "customer_call_completed" && data.agent_status === "agent_call_connected" && data.final_status === "completed") || (data.customer_status === "customer_connected" && data.agent_status === "agent_call_completed" && data.final_status === "completed")) {
        if (this.state.callType === "live") {
          let finalCalldur = this.state.currentCallDuration;
          this.setState({
            finalCurrentCallDuration: finalCalldur
          })
          clearInterval(this.callTimerInterval)
        }
        this.triggerFeedbackScreen()
        triggerPublicEvent('callEnded', null)
      }
      if (data.customer_status === "customer_connected" && data.agent_status === "agent_connected" && data.final_status === "agent_connected") {
        this.setState({
          currentCallComplete: this.props.widgetSettings.tmpCallCompleteStatus,
          showFeedbackForm: tmpFlag2,
          statusCode: data.customer_status,
          headingMain: message
        });
      }
    }
  }

  makeLiveCall = async () => {

    if (!this.state.finalNumber) {

      await this.sleep(1000)
      this.makeLiveCall()
    } else {
      let team = (this.state.selectedTeam) ? this.state.selectedTeam : null;
      let name = (this.state.name) ? this.state.name : null;
      let email = (this.state.email) ? this.state.email : null;

      var apikey = getAPIkey();
      const params = {
        phone: this.state.finalNumber,
        pId: apikey,
        agent_id: null,
        name: name,
        host_name: window.location.host,
        country_code: this.props.countryDialCode,
        email: email,
        company_name: null,
        team: team,
        customer_utm: this.props.widgetSettings.utm_data,
        source: this.props.widgetSettings.source
      };




      requestCall(params).then((response) => {


        if (!isNaN(response)) {
          this.triggerCall("We are calling you on the provided number")
          this.channel = this.pusher.subscribe(`call-channel-${response}`);
          this.pusher.bind('change-call-status', this.updateEvents);
          this.setState({
            callId: response
          });
        } else {
          this.setState({
            askAdditionalInfoForCall: false,
            headingMain: "Sorry, looks like we're unable to connect to your phone",
            displayErrorScreen: true
          }, this.resetScreen)
        }

      })
    }

  }

  makeDigitalCall = () => {
    // this.props.pushButtonEventToGA();
    let team = (this.state.selectedTeam) ? this.state.selectedTeam : null;
    let name = (this.state.name) ? this.state.name : null;
    let email = (this.state.email) ? this.state.email : null;

    var randomId = Math.random().toString(36).substring(3) + Math.floor(Date.now() / 1000);
    this.setState({
      callId: randomId,
    });

    // console.log(randomId);
    var apikey = getAPIkey();

    const params = {
      pId: apikey,
      name: name,
      source: this.props.widgetSettings.source,
      ip: this.props.widgetSettings.ip_address,
      randomId: randomId,
      team: team,
      agent_id: null,
      email: email,
      company_name: null
    };

    // console.log(params);

    // if(this.state.customValues!==undefined){
    //   if(this.props.widgetSettings.google_analytics===1){
    //     const params ={
    //       type: 'custom_field',
    //       pId:apikey,
    //       userIp: this.props.widgetSettings.ip_address
    //     };
    //     sendAnalyticsEvent(params);
    //   }
    // }
    this.triggerCall("We are connecting you with our team")
    // console.log("trigger call");
    if (this.device) {
      var outgoingConnection = this.device.connect(params);
      // this.pusher.logToConsole = true;
      // console.log("pusher-channel id is ");
      // console.log(`react-channel-random-${randomId}`);
      this.channel = this.pusher.subscribe(`react-channel-random-${randomId}`);
      this.pusher.bind('change-call-status', this.updateEvents);
      outgoingConnection.on('ringing', function () {
        // console.log('Ringing...');
      });
    }
    //   if(this.props.enable_cf_on_before_digitalcall){
    //     const beforeCallCustomData = {
    //       random_id:randomId,
    //       ...this.state.customValues
    //     };
    //     sendCustomFieldData(beforeCallCustomData).then(() => {
    //     }).catch((error) => {
    //       console.log(error);
    //     });


    //   if(this.props.widgetSettings.utm_data){
    //     const customerUTMdata = {
    //       lead_id:randomId,
    //       customer_utm:this.props.widgetSettings.utm_data
    //     };
    //     sendUTMdata(customerUTMdata).then(() => {
    //     }).catch((error) => {
    //       console.log(error);
    //     });
    //   }

    // // --------
    // } else {
    //   this.setState({
    //     errors: true
    //   });
    // }
  }

  getRegexOperator(operator, value) {
    let url = window.location.href;
    if (operator === "url-not-regex") {
      if (!url.match(value)) {
        return true;
      }
    }
    if (operator === "url-regex") {
      if (url.match(value)) {
        return true;
      }
    }
    if (operator === 'url-not-contains') {
      if (!url.match('.*' + value + '.*')) {
        return true;

      }
    }
    if (operator === 'url-contains') {
      if (url.match('.*' + value + '.*')) {
        return true;
      }
    }
    if (operator === 'url-not-ends') {
      if (!url.match('(.*' + value + '$)|(.*' + value + '$)')) {
        return true;
      }
    }
    if (operator === 'url-ends') {
      if (url.match('(.*' + value + '$)|(.*' + value + '$)')) {
        return true;
      }
    }
    if (operator === 'url-not-starts') {
      if (!url.match('(^' + value + '.*)|(^' + value + '.*)')) {
        return true;
      }
    }
    if (operator === 'url-starts') {
      if (url.match('(^' + value + '.*)|(^' + value + '.*)')) {
        return true;
      }
    }
    if (operator === 'url-ends') {
      if (url.match('.*' + value + '$')) {
        return true;
      }
    }
    if (operator === 'url-equals') {
      if (url === value || url === ('/' + value) || url === (value + '/') || url === ('/' + value + '/')) {
        return true;
      }
    }
    if (operator === 'url-match') {
      if (url.match('.*' + value + '.*')) {
        return true;
      }
    }
    if (operator === 'url-not-match') {
      if (!url.match('.*' + value + '.*')) {
        return true;
      }
    }

    return false;
  }

  customTriggerAttacher() {
    document.custom_triggers = this.props.widgetSettings.custom_triggers;
    let triggers_dataset = [];
    if (this.props.widgetSettings.custom_triggers) {
      for (var i = 0, j = 0; i < this.props.widgetSettings.custom_triggers.length; i++) {
        let tmpCustomTargetData = this.props.widgetSettings.custom_triggers[i];
        let tmpUsefulData = [];
        if (tmpCustomTargetData.key === "url") {

          if (this.getRegexOperator(tmpCustomTargetData.operator, tmpCustomTargetData.value)) {
            // console.log(tmpCustomTargetData.operator+"  "+tmpCustomTargetData.value+ " is triggering it");
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            tmpUsefulData['value'] = 1;
          } else {
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            tmpUsefulData['value'] = 0;
          }

          triggers_dataset[j] = tmpUsefulData;
          j++;
        }
      }
    }
    var finalCond = ""
    for (var tempCond, i = 0; i < triggers_dataset.length; i++) {
      let triggerSet = triggers_dataset[i];
      if (i === 0) {
        finalCond = triggerSet.value;
      } else {
        if (triggerSet.option === "and") {
          tempCond = finalCond && triggerSet.value;
        } else if (triggerSet.option === "or") {
          tempCond = finalCond || triggerSet.value;
        }
        finalCond = tempCond;
      }
    }
    if (finalCond === 1) {
      if (document.customTriggerAttacher === undefined) {
        this.addTimeTriggerEvent(2);
        document.customTargetAttacher = true;
      }
    }
  }

  pad = (val) => {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  logicalCallTimer = () => {
    if (this.state.timerGoingOn === 1) {
      return;
    } else {
      this.setState({
        timerGoingOn: 1
      })
      this.callTimerInterval = setInterval(function () {
        if (!this.state.displayCallConnectedScreen) {
          return;
        }
        let currentCallDur;
        if (this.state.currentCallDuration) {
          currentCallDur = this.state.currentCallDuration;
        } else {
          currentCallDur = 0;
        }
        var totalSeconds = currentCallDur;
        ++totalSeconds;
        this.setState({
          currentCallDuration: totalSeconds
        })
      }.bind(this),
        1000);
    }
  }

  //main functions 

  backToMainScreen = () => {
    this.toggleModal()
    this.toggleModal()
  }

  makeMainSelection = (option) => {
    if (option === "back") {
      this.setState({
        mainSelectionDone: true,
        headingMain: "You are just a click away from connecting with us.",
        headingSecondary: "",
        displayCallFailedScreen: false,
        askSelectCallType: true,
      })
    }
  }

  triggerScheduleCall = () => {
    this.setState({
      askSelectCallType: false,
      callType: "schedule",
      displayScheduleDateSelector: true,
      headingMain: "Please select a time for callback"
    })
  }

  triggerLiveCall = () => {
    this.setState({
      askSelectCallType: false,
      callType: "live"
    })
    if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_call_now_tab) {
      this.triggerTeamSelection()
    } else {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_call_now_tab) {
        this.triggerAdditionalInfoForCall("live");
      } else {
        this.makeLiveCall()
      }
    }
  }

  triggerWebCall = () => {
    this.setState({
      mainSelectionDone: true,
      headingSecondary: "",
      callType: "web"
    })
    if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_digital_calls_tab) {
      this.triggerTeamSelection()
    } else {
      this.triggerAdditionalInfoForCall("web");
    }
  }

  triggerTeamSelection = () => {
    this.setState({
      headingMain: "I want to speak to...",
      askForTeamSelection: true
    })
  }

  selectTeam = (option) => {
    this.setState({
      askForTeamSelection: false,
      selectedTeam: option
    })
    // this.triggerCall();
    this.triggerAdditionalInfoForCall(this.state.callType);
  }

  triggerAdditionalInfoForCall = (callType) => {
    let askForName = false;
    let askForEmail = false;

    if (callType === "web") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_digital_calls_tab) {
        askForName = true;
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_digital_calls_tab) {
        askForEmail = true;
      }
      if (askForName || askForEmail) {
        this.setState({
          askForName: askForName,
          askForEmail: askForEmail,
          askAdditionalInfoForCall: true,
          headingMain: "Please provide us a few more details."
        })
      } else {
        this.triggerMakeCall()
      }
    } else if (callType === "live") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_call_now_tab) {
        askForName = true;
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_call_now_tab) {
        askForEmail = true;
      }
      if (askForName || askForEmail) {
        this.setState({
          askForName: askForName,
          askForEmail: askForEmail,
          askAdditionalInfoForCall: true,
          headingMain: "Please provide us a few more details."
        })
      } else {
        this.triggerMakeCall()
      }
    } else if (callType === "schedule") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_call_later_tab) {
        askForName = true;
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_call_later_tab) {
        askForEmail = true;
      }
      if (askForName || askForEmail) {
        this.setState({
          askForName: askForName,
          askForEmail: askForEmail,
          askAdditionalInfoForCall: true,
          headingMain: "Please provide us a few more details."
        })
      } else {
        this.triggerMakeCall()
      }
    }

  }

  triggerCall = (option) => {
    this.setState({
      headingMain: option,
      callRingingState: true
    })
  }

  triggerCallMeBack = (option) => {
    if (!this.props.phoneNumberIsValid) {
      this.props.phoneNumberChangeHandler(0, "", { dialCode: "1", iso2: this.props.countryCode }, "");
      return;
    } else {
      this.setState({
        finalNumber: this.props.finalNumber
      })
    }

    if (option === "now") {
      this.setState({
        callType: "live"
      })
      this.triggerLiveCall()
    } else if (option === "later") {
      this.triggerScheduleCall()
    }
  }

  triggerCallConnectedScreen = () => {
    // console.log("trigger call connected screen")
    if (!this.state.timerGoingOn || this.state.timerGoingOn === 0) {
      this.logicalCallTimer()
    }
    this.setState({
      callRingingState: false,
      headingMain: "You're connected and speaking to",
      displayCallConnectedScreen: true
    })
  }

  triggerMuteCall = () => {
    let bol = 0;
    if (this.state.thisCallMuted) {
      bol = this.state.thisCallMuted;
    }
    if (this.device) {
      this.device.mute(bol);
      this.setState({
        thisCallMuted: !bol
      })
      console.log("mic muted")
    }
  }

  showableDateFormat = (date) => {
    if (date) {
      let humanReadableDay;
      if (moment(new Date(`${date}`)).isSame(new Date(), "day")) {
        humanReadableDay = "Today";
      } else if (moment(new Date(`${date}`)).isSame(moment(new Date()).add(1, 'days'), "day")) {
        humanReadableDay = "Tomorrow";
      }

      let readableDate = moment(date).format("MMMM D, YYYY")
      let fullDate;
      if (humanReadableDay) {
        fullDate = humanReadableDay + ", " + readableDate;
      } else {
        fullDate = readableDate;
      }

      return fullDate;
    } else {
      return true;
    }
  }

  readableDateFormat = (date) => {
    if (date != "") {
      let showDate;
      if (moment(new Date(`${date}`)).isSame(new Date(), "day")) {
        showDate = "Today";
      } else if (moment(new Date(`${date}`)).isSame(moment(new Date()).add(1, 'days'), "day")) {
        showDate = "Tomorrow";
      } else {
        // if(this.props.widgetSettings.date_format==="mm/dd/yyyy"){
        //   showDate = moment(date).format("MM/DD/YYYY");
        // }else if(this.props.widgetSettings.date_format==="dd/mm/yyyy"){
        //   showDate = moment(date).format("DD/MM/YYYY");
        // }
        showDate = moment(date).format("Do MMM");
      }
      return showDate;
    } else {
      return;
    }

  }

  processDateData = (dateData) => {
    if (!this.state.selectedScheduleDate) {
      this.setState({
        selectedScheduleDate: dateData[0],
        upcomingScheduleDate: dateData[1]
      })
    }
  }

  changeSelectedTime = (time) => {
    this.setState({
      selectedTime: time,
      scheduleTime: time.value
    })
  }

  processTimeData = (timeSlots) => {
    var currentSelectedTime;
    let availTimeSlots = [];
    {
      timeSlots[this.state.selectedScheduleDate] && timeSlots[this.state.selectedScheduleDate].map(timeVal => {
        let showTime;
        if (this.props.widgetSettings.time_format === "12") {
          showTime = moment(timeVal, 'HH:mm:ss').format("hh:mm A");
        } else {
          showTime = moment(timeVal, 'HH:mm:ss').format("HH:mm");
        }
        // console.log(showTime)
        if (!moment(new Date(`${this.state.selectedScheduleDate} ${timeVal}`)).isBefore(new Date(), "minute")) {
          availTimeSlots.push({ value: timeVal, label: showTime });
          if (!this.state.selectedTime) {
            this.setState({
              selectedTime: availTimeSlots[0],
              scheduleTime: availTimeSlots[0].value
            })
            currentSelectedTime = availTimeSlots[0];
          } else {
            currentSelectedTime = this.state.selectedTime;
          }
        }
      })
    }

    let timeSlotArray = [];
    // let i =0;
    var i, j, temparray, chunk = 2;
    for (i = 0, j = availTimeSlots.length; i < j; i += chunk) {
      temparray = availTimeSlots.slice(i, i + chunk);
      let temprow = [];
      temparray.forEach(function (rowData) {
        temprow.push(<div className={(rowData.value === currentSelectedTime.value) ? "limemodernTimeObject limemodernSelectedTime" : "limemodernTimeObject"} id={"timeObj" + rowData.value} onClick={(e) => this.changeSelectedTime(rowData)}>
          {rowData.label}
        </div>);
      }.bind(this));

      timeSlotArray.push(
        <div className="limemodernTimeRow">
          {temprow}
        </div>);
      unset(temprow);

    }

    return timeSlotArray;
  }

  triggerFeedbackScreen = () => {
    this.setState({
      displayCallConnectedScreen: false,
      displayCallFeedbackScreen: true,
      timerGoingOn: 0,
      currentCallDuration: 0,
      headingMain: "How would you like to rate this call?"
    })
  }

  sendRatingFeedback = (rating) => {
    let name = (this.state.name) ? this.state.name : null;
    let email = (this.state.email) ? this.state.email : null;
    let callId = this.state.callId;
    let callType = this.state.callType;
    if (callType === "web") {
      const postData = {
        random_id: callId,
        email: email,
        name: name,
        rating: rating,
        feedback: null,
      };

      sendFeedback(postData).then(() => {
      }).catch((error) => {
        // console.log(error);
      });
    } else if (callType === "live") {
      const postData = {
        lead_id: callId,
        email: email,
        name: name,
        rating: rating,
        feedback: null,
      };

      sendFeedback(postData).then(() => {
      }).catch((error) => {
        // console.log(error);
      });
    }
    this.setState({
      displayFeedbackSubmittedScreen: true,
      displayCallFeedbackScreen: false,
      headingMain: "We would love to see you again!"
    })
  }

  selectPreviousDate = () => {
    let timeSlots = this.props.widgetSettings.available_time;
    let dateData = [];
    {
      !isEmpty(timeSlots) && Object.keys(timeSlots).map((dateVal) => {
        dateData.push(dateVal);
      })
    }

    let selectedDateIndex = dateData.indexOf(this.state.selectedScheduleDate)
    if (selectedDateIndex === 0) {

    } else {
      let newupcoming = dateData[selectedDateIndex];
      let newcurrent = dateData[selectedDateIndex - 1];
      this.setState({
        selectedScheduleDate: newcurrent,
        upcomingScheduleDate: newupcoming,
        selectedTime: null,
        scheduleTime: null
      })
    }
  }

  selectUpcomingDate = () => {
    let timeSlots = this.props.widgetSettings.available_time;
    let dateData = [];
    {
      !isEmpty(timeSlots) && Object.keys(timeSlots).map((dateVal) => {
        dateData.push(dateVal);
      })
    }
    let selectedDateIndex = dateData.indexOf(this.state.selectedScheduleDate)
    if (selectedDateIndex === dateData.length) {

    } else {
      let newcurrent = dateData[selectedDateIndex + 1];
      let newupcoming = "";
      if (selectedDateIndex + 2 <= dateData.length) {
        newupcoming = dateData[selectedDateIndex + 2];
      }
      this.setState({
        selectedScheduleDate: newcurrent,
        upcomingScheduleDate: newupcoming,
        selectedTime: null,
        scheduleTime: null
      })

    }
  }

  readableTimeClock = (time) => {
    let secs;
    if (time) {
      secs = time;
    } else {
      secs = 0;
    }

    let readabletime = this.pad(parseInt(time / 60)) + ":" + this.pad(parseInt(time % 60));
    return readabletime;
  }

  triggerCallFailed = () => {
    this.setState({
      callRingingState: false,
      headingMain: "Sorry, we are unable to connect the call",
      displayCallFailedScreen: true
    })
  }

  resetErrorState = () => {
    this.setState({
      errorNameField: false,
      errorEmailField: false,
      error: false
    })
  }

  triggerSelectDateTimeConfirm = () => {
    this.setState({
      displayScheduleDateSelector: false,
      headingMain: "Please provide us a few more details.",
    })
    if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_call_later_tab) {
      this.triggerTeamSelection()
    } else {
      this.triggerAdditionalInfoForCall(this.state.callType);
    }
  }

  submitScheduleForm = () => {

    let team = (this.state.selectedTeam) ? this.state.selectedTeam : null;
    let name = (this.state.name) ? this.state.name : null;
    let email = (this.state.email) ? this.state.email : null;

    var apikey = getAPIkey();
    const params = {
      phone: this.state.finalNumber,
      pId: apikey,
      agent_id: null,
      name: name,
      host_name: window.location.host,
      country_code: this.props.countryDialCode,
      email: email,
      company_name: null,
      team: team,
      customer_utm: this.props.widgetSettings.utm_data,
      source: this.props.widgetSettings.source,
      type: "SCHEDULE_CALL",
      scheduled_at: `${this.state.selectedScheduleDate} ${this.state.scheduleTime}`,
      time_zone: this.props.widgetSettings.visitor_time_zone
    };

    requestCall(params).then((response) => {
      if (!isNaN(response)) {
        this.setState({
          askAdditionalInfoForCall: false,
          headingMain: "Hola! Your call is scheduled",
          displayScheduleConfirmationScreen: true
        }, this.resetScreen)
      } else {
        this.setState({
          askAdditionalInfoForCall: false,
          headingMain: "Sorry, we're unable to schedule a call for you at the moment.",
          displayErrorScreen: true
        }, this.resetScreen)
      }
    })

  }

  resetScreen = () => {
    setTimeout(() => {

      this.setState(prevState => ({
        mainSelectionDone: false,
        headingMain: "Hey 👋",
        headingSecondary: "Need help? We're here!",
        askSelectCallType: false,
        displayErrorScreen: false,
        askForTeamSelection: false,
        callRingingState: false,
        displayCallConnectedScreen: false,
        displayCallFeedbackScreen: false,
        displayCallFailedScreen: false,
        displayScheduleDateSelector: false,
        askForAdditionalDetails: false,
        displayScheduleConfirmationScreen: false,
        askAdditionalInfoForCall: false,
        displayFeedbackSubmittedScreen: false,
      }));


    }, 5000)
  }


  triggerCallEnd = () => {
    if (this.device) {
      this.device.disconnectAll();
      // console.log("disconnecting call");
    }
    clearInterval(this.callTimerInterval)
    this.triggerFeedbackScreen()
  }

  validateBasicForm = (callType) => {
    let errorNameField = false;
    let errorEmailField = false;
    let userFilledName = (this.state.name) ? this.state.name.trim() : null;
    let userFilledEmail = (this.state.email) ? this.state.email.trim() : null;
    if (callType === "web") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_digital_calls_tab) {
        if (userFilledName === undefined || !userFilledName || userFilledName === "") {
          errorNameField = true;
        } else {
          errorNameField = false;
        }
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_digital_calls_tab) {
        if (userFilledEmail === undefined || !userFilledEmail || userFilledEmail === "" || !(EmailValidator.validate(userFilledEmail))) {
          errorEmailField = true;
        } else {
          errorEmailField = false;
        }
      }

    } else if (callType === "live") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_call_now_tab) {
        if (userFilledName === undefined || !userFilledName || userFilledName === "") {
          errorNameField = true;
        } else {
          errorNameField = false;
        }
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_call_now_tab) {
        if (userFilledEmail === undefined || !userFilledEmail || userFilledEmail === "" || !(EmailValidator.validate(userFilledEmail))) {
          errorEmailField = true;
        } else {
          errorEmailField = false;
        }
      }

    } else if (callType === "schedule") {
      if (this.props.widgetSettings.full_name_enabled_before_call && this.props.widgetSettings.full_name_in_call_later_tab) {
        if (userFilledName === undefined || !userFilledName || userFilledName === "") {
          errorNameField = true;
        } else {
          errorNameField = false;
        }
      }
      if (this.props.widgetSettings.email_enabled_before_call && this.props.widgetSettings.email_in_call_later_tab) {
        if (userFilledEmail === undefined || !userFilledEmail || userFilledEmail === "" || !(EmailValidator.validate(userFilledEmail))) {
          errorEmailField = true;
        } else {
          errorEmailField = false;
        }
      }

    }

    this.setState({
      errorEmailField: errorEmailField,
      errorNameField: errorNameField
    })

    if (errorEmailField || errorNameField) {
      this.setState({
        error: true
      })
      return false;
    } else {
      return true;
    }
  }

  triggerMakeCall = () => {
    if (!this.validateBasicForm(this.state.callType)) {
      return;
    }
    this.setState({
      askAdditionalInfoForCall: false
    })

    if (this.state.callType === "web") {
      this.makeDigitalCall()
    } else if (this.state.callType === "schedule") {
      this.setState({
        headingMain: "Scheduling your call..."
      })
      this.submitScheduleForm()
    } else if (this.state.callType === "live") {
      this.makeLiveCall()
    }
  }

  onValueChange = (event) => {
    if (event.target.name === "email") {
      this.setState({
        errorEmailField: false
      })
    } else if (event.target.name === "name") {
      this.setState({
        errorNameField: false
      })
    }

    this.setState({
      [event.target.name]: event.target.value,
      error: false
    })
    if (event.target.value === '' || event.target.value === null) {
      this.setState({
        [event.target.value]: null
      })
    }

  }

  goBackFromAdditionalInfo = () => {
    this.setState({
      askAdditionalInfoForCall: false
    })
    this.resetErrorState()

    if (this.state.callType === "web") {
      if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_digital_calls_tab) {
        this.triggerTeamSelection()
      } else {
        this.backToMainScreen()
      }
    } else if (this.state.callType === "live") {
      if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_call_now_tab) {
        this.triggerTeamSelection()
      } else {
        this.makeMainSelection("back")
      }
    } else if (this.state.callType === "schedule") {
      if (this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_call_now_tab) {
        this.triggerTeamSelection()
      } else {
        this.triggerScheduleCall()
      }
    }
  }

  //render screen functions

  renderMainSelectionScreen = () => {
    if (!this.state.mainSelectionDone) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            {(this.props.widgetSettings.digital_call) ? (
              <div className="limemodernSelectorButton" onClick={(e) => { this.triggerWebCall() }}>
                <i className="material-icons limemodernSelectorIcons" style={{ color: "#25ae88" }}>phone_in_talk</i> Talk to us now
              </div>
            ) : null}
            {(this.props.widgetSettings.call_now || this.props.widgetSettings.call_me_later) ? (
              <div className="limemodernSelectorButton" onClick={(e) => { this.makeMainSelection("back") }}>
                <img style={{ width: "24.5px", marginRight: "5px" }} src={require('../images/call_me_back.png')} /> Call me back
              </div>
            ) : null}
          </div>
        </React.Fragment>
      )
    }
  }

  renderCallTypeSelectionScreen = () => {
    if (this.state.askSelectCallType) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernFormContainer">
              <div className={this.props.intlCustomCLass + " inputFieldContainerModern"}>
                <IntlTelInput
                  name="tel-no"
                  fieldName="tel-no"
                  format=""
                  fieldId="modernIntlCall"
                  value={this.props.phoneNumber}
                  useMobileFullscreenDropdown={false}
                  disabled={this.props.widgetSettings.allowedCountries.length < 1}
                  onlyCountries={this.props.widgetSettings.allowedCountries}
                  separateDialCode={true}
                  customPlaceholder={function (selectedCountryPlaceholder, selectedCountryData) {
                    return "Enter phone number";
                  }}
                  onSelectFlag={this.props.onSelectFlagHandler}
                  onPhoneNumberChange={this.props.phoneNumberChangeHandler}
                  style={{ fontFamily: this.props.widgetSettings.widget_font, fontSize: "12px", marginBottom: '0.8rem' }}
                  defaultCountry={this.props.countryCode}
                />
              </div>
              <div className="limemodernButtonRow">
                {(this.props.widgetSettings.call_now) ? (
                  <button className="limemodernSubmitButtonSmall" style={{ marginRight: "15px" }} onClick={(e) => this.triggerCallMeBack("now")}>
                    Call Now
                  </button>
                ) : null}
                {(this.props.widgetSettings.call_me_later) ? (
                  <button className="limemodernSubmitButtonSmall" style={{ backgroundColor: "#00c3fe" }} onClick={(e) => this.triggerCallMeBack("later")}>
                    Call Later
                  </button>
                ) : null}
              </div>
              {(this.props.phoneNumber && this.props.phoneNumberError) ? (
                <div className="limemodernErrorMessage">
                  Enter a valid number to proceed
                </div>
              ) : (
                <p className="limemodernSecondaryText">
                  You will recieve a call from us within 30 sec
                </p>
              )}

            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderTeamSelectionScreen = () => {
    if (this.state.askForTeamSelection) {
      let tmpTeam = [];
      let teamsData;
      teamsData = this.props.widgetSettings.teams;
      if (teamsData.length > 0) {
        for (let i = 0; i < teamsData.length; i++) {
          tmpTeam.push(<li id={"teamID" + teamsData[i].id} onClick={() => this.selectTeam(teamsData[i].id)}>{teamsData[i].name}</li>)
        }
      }

      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernTeamSelection">
              <ul>
                {tmpTeam}
              </ul>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderCallRingingScreen = () => {
    if (this.state.callRingingState) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernRingingCall">
              <img src="https://assets.materialup.com/uploads/63bd53b1-446a-42d5-be93-5e2bcffb4345/preview.gif" alt="loader" style={{ width: '140px' }} />
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderCallConnectedScreen = () => {
    if (this.state.displayCallConnectedScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernConnectedCall">
              <div className="limemodernAgentDetails">
                <div>
                  <img src={this.state.agent_pic} alt="agent_image" className="limemodernAgentImage" />
                </div>
                <div className="limemodernAgentInfoContainer">
                  <div className="limemodernAgentNameBox" >
                    {this.state.agent_name}
                  </div>
                  <div className="limemodernAgentJobDesignation" >
                    {(this.state.agent_title) ? (
                      "(" + this.state.agent_title + ")"
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="limeModernCallConnectedActionsSection">
                <div className="callConnCounterContainer" style={(this.state.callType === "live") ? ({ width: "100%" }) : null}>
                  <div className="callConnSecCounter">{this.readableTimeClock(this.state.currentCallDuration)}</div>
                  <div className="callConnSubText">Connected</div>
                </div>
                {(this.state.callType === "web") ? (
                  <div className="callConnActionButonContainer">
                    <i className="material-icons moderncallActions modernCallIconMicOff" onClick={(e) => this.triggerMuteCall()}>mic_off</i>
                    <i className="material-icons moderncallActions" style={{ color: "red" }} onClick={(e) => this.triggerCallEnd()}>call_end</i>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderCallFeedbackScreen = () => {
    if (this.state.displayCallFeedbackScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernConnectedCall">
              <div className="limemodernAgentDetails" style={{ display: "block" }}>
                <span className="smartEmoji-container">
                  <span className="smartEmojiSign modernEmojiObject" >
                    <EmojiMad
                      send_rating_feedback={this.sendRatingFeedback.bind(this)}
                      rating_stars="1"
                    ></EmojiMad>
                    <span className="smartEmojiSign-tooltip">Very bad</span>
                  </span>
                  <span className="smartEmojiSign modernEmojiObject">
                    <EmojiSad
                      send_rating_feedback={this.sendRatingFeedback.bind(this)}
                      rating_stars="2"
                    ></EmojiSad>
                    <span className="smartEmojiSign-tooltip">Poor</span>
                  </span>
                  <span className="smartEmojiSign modernEmojiObject">
                    <EmojiNeutral
                      send_rating_feedback={this.sendRatingFeedback.bind(this)}
                      rating_stars="3"
                    ></EmojiNeutral>
                    <span className="smartEmojiSign-tooltip">Fair</span>
                  </span>
                  <span className="smartEmojiSign modernEmojiObject">
                    <EmojiHappy
                      send_rating_feedback={this.sendRatingFeedback.bind(this)}
                      rating_stars="4"
                    ></EmojiHappy>
                    <span className="smartEmojiSign-tooltip">Good</span>
                  </span>
                  <span className="smartEmojiSign modernEmojiObject" style={{ paddingRight: "0px" }}>
                    <EmojiSuperHappy
                      send_rating_feedback={this.sendRatingFeedback.bind(this)}
                      rating_stars="5"
                    ></EmojiSuperHappy>
                    <span className="smartEmojiSign-tooltip">Excellent</span>
                  </span>
                </span>
              </div>
              <div className="limeModernCallEndedActionsSection">
                <div className="">
                  <div className="callEndTextSec">Call ended</div>
                  <div className="callConnSubText">{this.readableTimeClock(this.state.finalCurrentCallDuration)}</div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderFeedbackSubmittedScreen = () => {
    if (this.state.displayFeedbackSubmittedScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernConfirmationContainer">
              <div>
                <i className="material-icons limemodernDoneSign">check</i>
              </div>
              <div>Thank you for your feedback.</div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  loadModernSettings = () => {
    if (this.props.widgetSettings.widget_type === 4 || this.props.widgetSettings.widget_type === 3) {
      this.setState({
        modalIsOpen: true
      })
    }
  }

  loadIntercom = () => {
    if (this.props.widgetSettings.widget_type === 4) {
      this.triggerWebCall()
    }
  }

  renderCallFailedScreen = () => {
    if (this.state.displayCallFailedScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernSelectorButton" onClick={(e) => { this.backToMainScreen() }}>
              <i className="material-icons limemodernSelectorIcons" style={{ color: "#25ae88", fontWeight: "600" }}>autorenew</i> Reconnect
            </div>
            <div className="limemodernSelectorButton" onClick={(e) => { this.makeMainSelection("back") }}>
              <img style={{ width: "22px", marginRight: "5px" }} src={require('../images/calender.png')} /> Schedule a Callback
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderScheduleCallDateSelectionScreen = () => {

    if (this.state.displayScheduleDateSelector) {
      let timeSlots = this.props.widgetSettings.available_time;
      let dateData = [];
      {
        !isEmpty(timeSlots) && Object.keys(timeSlots).map((dateVal) => {
          dateData.push(dateVal);
        })
      }
      this.processDateData(dateData);

      let availableTiming = this.processTimeData(timeSlots);
      // console.log(availableTiming);

      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernDateTimeContainer">
              <div className="limemodernDateToggleContainer">
                <div className="limemodernDateToggle" onClick={(e) => this.selectPreviousDate()}>
                  <i className="material-icons dateToggleIcon">arrow_left</i> {this.readableDateFormat(this.state.selectedScheduleDate)}
                </div>
                {(this.state.upcomingScheduleDate) ?
                  (<div className="limemodernDateToggle limemodernDateToggleRight" onClick={(e) => this.selectUpcomingDate()}>
                    {this.readableDateFormat(this.state.upcomingScheduleDate)} <i className="material-icons dateToggleIcon">arrow_right</i>
                  </div>)
                  : null}
              </div>
              <div className="limemodernTimeContainer">
                {availableTiming}
              </div>
            </div>
            <div className="limemodernDateTimeSelectedContainer">
              <div className="limemodernDateTimerow">
                <div className="limemodernDateTimeIconContainer">
                  <i className="material-icons" style={{ color: "#4d4d4d", fontSize: "19px" }}>event</i>
                </div>
                <div className="limemodernDateTimeSelected">
                  <div className="limemodernSelectedTimeDisplay">{(this.state.selectedTime) ? this.state.selectedTime.label : ""}</div>
                  <div className="limemodernSelectedDateDisplay">{this.showableDateFormat(this.state.selectedScheduleDate)}</div>
                </div>
              </div>
            </div>
            <div className="limemodernNextBtnContainer">
              <span className="limemodernNextBtn" onClick={(e) => this.triggerSelectDateTimeConfirm()}>
                Next <i className="material-icons modernNextBtnIcon">arrow_right</i>
              </span>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderAskForAdditionalInfoForCall = () => {
    if (this.state.askAdditionalInfoForCall) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernAdditionalDetailsContainer">
              <div className="limemodernBackButton">
                <span style={{ cursor: "pointer" }} onClick={(e) => this.goBackFromAdditionalInfo()}>
                  <i className="material-icons modernNextBtnIcon" style={{ marginRight: "-4px", fontSize: "22px" }}>arrow_left</i> Back
                </span>
              </div>
              <div className="limemodernFormContainer limemodernFormScheduleContainer" >
                {(this.state.askForName) ?
                  (<div className="inputFieldContainerModern">
                    <input type="text" name="name" defaultValue={this.state.name} className={(this.state.errorNameField) ? "modernErrorField" : ""} onChange={event => this.onValueChange(event)} placeholder="Your name*" style={{ marginTop: "5px" }} />
                  </div>) : null
                }
                {(this.state.askForEmail) ?
                  (<div className="inputFieldContainerModern">
                    <input type="email" name="email" defaultValue={this.state.email} className={(this.state.errorEmailField) ? "modernErrorField" : ""} placeholder="Your email*" onChange={event => this.onValueChange(event)} style={{ marginTop: "5px" }} />
                  </div>) : null
                }

              </div>
            </div>
            <div className="limemodernScheduleSubmitContainer">
              <button className="limemodernSubmitButton" onClick={(e) => this.triggerMakeCall()}>
                {(this.state.callType === "schedule") ? "Schedule" : "Start Call"}
              </button>
            </div>
            {(this.state.error) ? (
              <div className="limemodernErrorMessage">
                Please fill required data to proceed
              </div>) : null}
          </div>
        </React.Fragment>)
    }
  }

  renderScheduleConfirmationScreen = () => {
    if (this.state.displayScheduleConfirmationScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernConfirmationContainer">
              <div>
                <i className="material-icons limemodernDoneSign">check</i>
              </div>
              <div>{this.showableDateFormat(this.state.selectedScheduleDate)}</div>
              <div style={{ padding: "5px" }}>{this.state.selectedTime.label}</div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }


  renderErrorScreen = () => {
    if (this.state.displayErrorScreen) {
      return (
        <React.Fragment>
          <div className="limemodernBodyContainer">
            <div className="limemodernConfirmationContainer">
              <div>
                <i className="material-icons limemodernDoneSign" style={{ color: "red" }}>clear</i>
              </div>
              {/* <div>{this.showableDateFormat(this.state.selectedScheduleDate)}</div> */}
              <div style={{ padding: "5px" }}>Oops, something went wrong at our end, please try in a while.</div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }



  render() {
    let modernTemplateClass = "limemodernTemplate";
    let popupVisib = " ";
    if (this.state.modalIsOpen) {
      popupVisib = " limeModalOpenWid"
    }

    let popupPosition = "limemodernPopup" + this.props.widgetSettings.bubble_position;
    let bubblePosition = this.props.widgetSettings.bubble_position;
    if (bubblePosition === "top_right") {
      modernTemplateClass = modernTemplateClass + " topRight"
    } else if (bubblePosition === "top_left") {
      modernTemplateClass = modernTemplateClass + " topLeft"
    } else if (bubblePosition === "bottom_right") {
      modernTemplateClass = modernTemplateClass + " bottomRight"
    } else if (bubblePosition === "bottom_left") {
      modernTemplateClass = modernTemplateClass + " bottomLeft"
    }
    if (this.props.widgetSettings.widget_type === 3 || this.props.widgetSettings.widget_type === 4) {
      popupVisib = " limeIntercomTrigger"
      modernTemplateClass = modernTemplateClass + " limeModernIntercom"
    }

    return (
      <React.Fragment>
        <div className={"limecall-widget popup-button limemodernWidget " + popupPosition + popupVisib} dangerouslySetInnerHTML={this.triggererListener()} >
          {(!this.state.modalIsOpen && this.match(this.props.widgetSettings['bubble_position'], "right") && this.props.widgetSettings.bubble_text_status) ? (<div className="twilio-popup" style={{ fontFamily: (this.props.widgetSettings.widget_font) }}>{(this.props.widgetSettings.bubble_text === undefined) ? "Hey there! How can we help?" : this.props.widgetSettings.bubble_text}</div>) : null}
          <div role="presentation" onClick={this.toggleModal} id="limeCallWid" className="twilio-wd-open-widget-popup" style={{ background: "#0273ff", 'borderRadius': ((this.props.widgetSettings.shape === "default") ? "50%" : "0%") }}>
            {(!this.state.modalIsOpen) ? (<i className="material-icons">call</i>) : (<i className="material-icons" style={{ fontSize: "44px" }}>expand_more</i>)}
          </div>
          {(!this.state.modalIsOpen && this.match(this.props.widgetSettings['bubble_position'], "left") && this.props.widgetSettings.bubble_text_status) ? (<div className="twilio-popup">{(this.props.widgetSettings.bubble_text === undefined) ? "Hey there! How can we help?" : this.props.widgetSettings.bubble_text}</div>) : null}
        </div>
        <div className={modernTemplateClass} style={{ display: ((this.state.modalIsOpen) ? "block " : "none"), fontFamily: this.props.widgetSettings.widget_font }}>
          <div className={(this.state.displayCallFailedScreen) ? "limemodernTempHeader limemodernFailedHeader" : "limemodernTempHeader"} style={(this.state.displayCallFailedScreen) ? null : { background: this.props.widgetSettings.widget_background }}>
            {(this.props.widgetSettings.company_logo !== "default") ?
              <div className="limemodernLogoContainer">
                <img className="limemodernCompanyLogo" src={(this.props.widgetSettings.company_logo) ? this.props.widgetSettings.company_logo : "https://app.limecall.com/assets/new_dashboard/assets/images/logo_sm.png"} />
              </div>
              : null}
            <div className="limemodernGreetingHeader">
              <h3>{this.state.headingMain}</h3>
            </div>
            <div className="limemodernGreetingBody">
              {this.state.headingSecondary}
            </div>
          </div>
          <div className="limemodernTempBody">
            {this.renderMainSelectionScreen()}
            {this.renderCallTypeSelectionScreen()}
            {this.renderTeamSelectionScreen()}
            {this.renderCallRingingScreen()}
            {this.renderCallFailedScreen()}
            {this.renderCallConnectedScreen()}
            {this.renderCallFeedbackScreen()}
            {this.renderFeedbackSubmittedScreen()}
            {this.renderScheduleCallDateSelectionScreen()}
            {this.renderAskForAdditionalInfoForCall()}
            {this.renderScheduleConfirmationScreen()}
            {this.renderErrorScreen()}
            <div className="limemodernPoweredSection">
              We run on <img style={{ height: "10px", marginLeft: "2px" }} src="https://limecall.com/wp-content/uploads/2019/12/logo_blue.png" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default ModernTemplate;
