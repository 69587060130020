import axios from 'axios';
import { get } from "lodash";

axios.defaults.headers.common['Content-Type'] = 'application/json';
let LIMECALL_URL;
let APIKEY;
let WIDGET_TYPE;

if (process.env.REACT_APP_ENV === 'development') {
    LIMECALL_URL = "https://qa.limecall.com/"
    APIKEY = "$2y$10$NTQUU8FY5YPQl.wRYJi8d.YafvwEiKRrsYAu0IjYeqjUxp.N8jvcy";
    // APIKEY = "9a7ef344"; 
} else if (process.env.REACT_APP_ENV === 'production_testing') {
    LIMECALL_URL = "https://app.limecall.com/"
    APIKEY = "$2y$10$xvbZoUFgJBWXc8cygqndmuFxqHUdKKCoW7sdq04o1SWMto9xDmzlq";
} else {
    LIMECALL_URL = localStorage.getItem('limecall-widget-url');
    APIKEY = localStorage.getItem('limecall-widget-api');
}

console.log(LIMECALL_URL)
if (localStorage.getItem('limecall-widget-type') !== null && localStorage.getItem('limecall-widget-type') !== "null") {
    WIDGET_TYPE = localStorage.getItem('limecall-widget-type');
} else {
    WIDGET_TYPE = "embedded";
    // WIDGET_TYPE = "shareable";
    // WIDGET_TYPE = "personal";
    // WIDGET_TYPE = "intercom_personal";
    // WIDGET_TYPE = "intercom_platform";
}

export const getWidgetType = () => {
    return WIDGET_TYPE;
};

export const getAPIkey = () => {
    return APIKEY;
};

export const getBaseURL = () => {
    return LIMECALL_URL;
}

export const requestCall = async (values) => {
    return axios.get(`${LIMECALL_URL}/api/request-call`, { params: values })
        .then(response => get(response, ['data', 'data', 'id'], 0))
        .catch((error) => {
            const errorMessage = get(error, ['response', 'data', 'errors'], []);
            return errorMessage && errorMessage.length ? errorMessage.join(" ") : "Unfortunately, we are unable to make this call!";
        });
};

export const sendMessage = async (values) => {
    return axios.post(`${LIMECALL_URL}/api/widget/send_message/${values.pId}`, values)
        .then(response => get(response, ['data', 'data', 'id'], 0))
        // .then(() => "Thank you for reaching out. We'll contact you soon!")
        .catch(() => "Unfortunately, we are unable to send this message. Try again later!");
}

export const sendAnalyticsEvent = async (values) => {
    return axios.post(`${LIMECALL_URL}/api/widget-analytics-events`, values)
        .then(response => response)
        .catch((error) => {
            console.log(error);
        });
};

export const sendFeedback = async (values) => axios.post(`${LIMECALL_URL}/api/update-lead-details`, values)
    .then(response => response)
    .catch((error) => {
        console.log(error);
    });

export const sendUTMdata = async (values) => axios.post(`${LIMECALL_URL}/api/update-utm`, values)
    .then(response => response)
    .catch((error) => {
        console.log(error);
    });

export const sendTrackingData = async (values) => axios.post(`${LIMECALL_URL}/api/tracking-data`, values)
    .then(response => response)
    .catch((error) => {
        console.log(error);
    });

export const sendCustomFieldData = async (values) => axios.post(`${LIMECALL_URL}/api/widgets/widget/extra-info`, values)
    .then(response => response)
    .catch((error) => {
        console.log(error);
    });

export const loadWidgetSettings = () => {
    if (WIDGET_TYPE === "personal") {
        return axios.get(`${LIMECALL_URL}/api/agent-settings/${APIKEY}/UTC`)
            .then(response => get(response, ['data', 'data']))
            .catch((error) => {
                console.log(error);
            });
    } else {
        return axios.get(`${LIMECALL_URL}/api/widget/${APIKEY}/settings`)
            .then(response => get(response, ['data', 'data']))
            .catch((error) => {
                console.log(error);
            });
    }
}

export const loadTimezonebasedSchedule = (gmtTimezone) => axios.get(`${LIMECALL_URL}/api/widget/modify-slots/${APIKEY}/${gmtTimezone}`)
    .then(response => get(response, ['data', 'data']))
    .catch((error) => {
        console.log(error);
    });

export const loadTwilioClient = () => axios.get(`${LIMECALL_URL}/api/v1/request-token-from-widget`)
    .then(response => get(response, ['data', 'data']))
    .catch((error) => {
        console.log(error);
    });
export const setWidgetInstalled = () => {
    if (WIDGET_TYPE === "embedded") {
        axios.post(`${LIMECALL_URL}/api/widget/installation`, { "script_id": APIKEY, value: 1, domain: window.location.hostname })
            .then(response => console.log("widget installed"))
            .catch((error) => {
                console.log(error);
            });
    }
}
