import React from 'react';

import * as EmailValidator from 'email-validator';
import IntlTelInput from 'react-intl-tel-input';
import moment from "moment";
import { default as SelectTimezone} from "react-timezone-select";
import Select from "react-select";
import Pusher from 'pusher-js';
import { isEmpty } from "lodash";
import{
  EmojiHappy,
  EmojiMad,
  EmojiNeutral,
  EmojiSad,
  EmojiSuperHappy,
  CheckCircleSolid,
  SmartLoading
} from "../HelperMethods/emoji";
import {
  widgetFormInitialState,
  processSmartFloatLabels,
  pushTrackingDataForLead
} from "../HelperMethods";
import {
    loadTwilioClient,
    getAPIkey,
    sendCustomFieldData,
    sendFeedback,
    requestCall,
    sendAnalyticsEvent,
    loadTimezonebasedSchedule
} from "../WidgetAPICalls";

import CustomFields from './CustomFields';
import Twilio from "twilio-client";


class SmartTemplate extends React.Component {
  floatingStyle = {
    bottom_left:{
      display:"flex",
      bottom:"37px",
      left:"37px"
    },
    bottom_right:{
      display:"flex",
      bottom:"37px",
      right:"37px"
    },
    top_right:{
      display:"flex",
      top:"37px",
      right:"37px"
    },
    top_left:{
      display:"flex",
      top:"37px",
      left:"37px"
    }
  }
  fontStyle =  {}

  constructor(props) {
    super(props);
    let smartBtnIntClass = null;
    if(this.props.widgetSettings.cta_button===1){
      smartBtnIntClass = "smart-button-wide animated infinite pulse ";
    }else{
      smartBtnIntClass = "animated infinite pulse ";
    }
    this.state = {
      ...widgetFormInitialState,
      scheduleTime: props.scheduleTime,
      smartBtnIntClass: smartBtnIntClass,
      scheduleDate: props.scheduleDate,
      selectedOption: {value:this.props.widgetSettings.visitor_time_zone,label:this.props.widgetSettings.visitor_time_zone},
      available_time: this.props.widgetSettings.available_time,
      email: this.props.widgetSettings.email,
      username: this.props.widgetSettings.name
    };
    this.fontStyle =  {
      fontFamily: this.props.widgetSettings.widget_font
    }
  }

  fontStyle =  {}

  componentWillMount() {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'eu',
      forceTLS: true,
      disableStats: true
    });
    this.pusher.connection.bind( 'error', function( err ) {
      if( err.error.data.code === 4004 ) {
        console.log('Over limit!');
      }
    });
    loadTwilioClient().then((response) => {
        // console.log(response);
        this.device = new Twilio.Device(response.token, {
            codecPreferences: ['opus', 'pcmu'],
            fakeLocalDTMF: true,
            enableRingingState: true,
          }); 

          this.device.on('ready',function (device) {
            // console.log('Device Ready!');
          });

          this.device.on('error', function (error) {
            // console.log('Device Error: ' + error.message);
            if(error.code===31208){
              this.microphoneBlocked()
            }
          }.bind(this));

          this.device.on('connect',  (conn) => {
            // console.log('Successfully established call!');
            this.setState({
                // showLoader:false,
                showDisconnect: true,
                callStatusMessage:"Hold on! We are finding teams to place a call..."
                // callStatusMessage:this.props.widgetSettings.language_data["hola-finding-team-during-call-now"]
            });

          });

          this.device.on('disconnect',  (conn) => {
            // console.log('Call ended.');
            // document.getElementById('button-call').style.display = 'inline';
            // document.getElementById('button-hangup').style.display = 'none';
            this.setState({
                hideLoadingArea:true,
                currentCallComplete:true,
                callStatusMessage:"Call Ended",
                showBackButton:true,
                showDisconnect: false
            });
          });

          this.device.on('incoming', function (conn) {
            // console.log('Incoming connection from ' + conn.parameters.From);
              // accept the incoming connection and start two-way audio
              conn.accept();
          });

    })
    .catch(function (err) {
        // console.log(err);
        // console.log('Could not get a token from server!');
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleCall !== this.props.scheduleCall
      || this.props.activeTab !== nextProps.activeTab) {
      this.setState({
        ...widgetFormInitialState
      });
    }
  }

  resetForm = () => {
    this.setState(prevState => ({
      ...widgetFormInitialState
    }));
  }

  resetCallState(){
    this.setState({
      scheduleCallFormSecond: false,
      countdownBegun:false,
      currentCallComplete:false,
      callStatusMessage:"",
      thisCallStarted:false,
      showBackButton:false
    })
  }
  
  setStateMessageBinder(){
    this.setState({
      callStatusMessage:"Your call is connected and you are speaking to agent "+this.state.agent_name+".",
      currentCallComplete:true
    })
  }

  disconnectCall(){
    if (this.device) {
        this.device.disconnectAll();
        // console.log("disconnecting call");
      }
  }

  onValueChange = (event) => {
    if(event.target.name==="email"){
      this.setState({
        error_email: false
      })
      this.props.widgetSettings.email = event.target.value;
      if(event.target.value===null || event.target.value===''){
        this.props.widgetSettings.email = null;
      }
    }else if(event.target.name==="username"){
      this.props.widgetSettings.name = event.target.value;
    }else if(event.target.name==="team"){
      this.props.widgetSettings.team = event.target.value;
    }
    
      this.setState({
        [event.target.name]: event.target.value,
        error: false
      })
      if(event.target.value==='' || event.target.value===null){
        this.setState({
          [event.target.value]: null
        })
      }
    
  }

  onChangeOptions = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  updateEvents = (data) => {
    // console.log(data);
    // console.log("pusher event binding event callled");
      // console.log('customer_status : '+data.customer_status +" | customer_service-status : "+ data.agent_status +" | final_status: "+data.final_status);

    // console.log(data.customer_status+"  customer_status")
    if (this.state.callId !== 0) {
    let message = "";
      if(data.customer_status==="customer_connected" && data.final_status==="agent_connected" && data.agent_status==="agent_connected"){
        let memberName = (data.member===undefined)? "us":((data.member.first_name===undefined)?"us": data.member.first_name);
        message = "Your call is connected and you are speaking to agent "+memberName+".";  
        this.updateCallConnectedState(memberName)
        // console.log(message);
        var duringCall = false;
        if(this.props.enable_cf_on_during_call===true){
          duringCall = true;
        }
        this.setState({
            agent_name:memberName,
            thisCallStarted:true,
            callStatusMessage:message,
            duringCallScreen:duringCall
          });

      }
      
      // console.log('customer_status : '+data.customer_status +" | customer_service-status : "+ data.agent_status +" | final_status: "+data.final_status);
      if((data.customer_status ==="customer_connected" &&  data.agent_status === "failed_to_connect_agent" && data.final_status=== "failed_to_connect_agent") || (data.customer_status ==="customer_call_completed" &&  data.agent_status === "failed_to_connect_agent" && data.final_status=== "failed_to_connect_agent")){
        if(this.device){
          this.device.disconnectAll();
        }
        this.callFailedAgentsNotAvail()
      }
      if((data.customer_status==="customer_connected" && data.agent_status ==="agent_call_completed" && data.final_status==="agent_connected") || (data.customer_status==="customer_connected" && data.agent_status ==="agent_call_completed" && data.final_status==="completed") || (data.customer_status==="customer_call_completed" && data.agent_status ==="agent_call_completed" && data.final_status==="completed") || (data.customer_status==="customer_call_completed" && data.agent_status ==="agent_connected" && data.final_status==="completed") || (data.customer_status==="customer_connected" && data.agent_status ==="agent_call_completed" && data.final_status==="completed") ){
        // console.log("end call from pusher")
        this.endSmartCall()
        this.setState({
          showFeedbackForm:true,
          smartWidgetOpen:true
        })
      }

    }
  }

  countdownTimer = () => {
    this.setState({
      countdownBegun: true,
      callStatusMessage:""
    });
  }
  allFields = () => {
    if(this.props.widgetSettings.full_name_required_before_call && !this.props.widgetSettings.name && (this.state.username===undefined || this.state.username==="")){
      this.setState({
        errors: true
      });
      return false;
    }

    if(this.props.widgetSettings.email_required_before_call && !this.props.widgetSettings.email && (this.props.widgetSettings.email===undefined || this.props.widgetSettings.email==="" || !EmailValidator.validate(this.props.widgetSettings.email)) && (this.state.email===undefined ||this.state.email==="" || !EmailValidator.validate(this.state.email))){
      this.setState({
        errors: true
      });
      return false;
    }
    return true;
  }

  customFieldValueChange = (event) => {
    let tmpA = event.target.name
    let tmpB = event.target.value
    this.setState({
        customValues: {...this.state.customValues, [tmpA]:tmpB},
        error: false
    })
  }
  
  handleDateChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedDate:selectedOption,
      scheduleDate:selectedOption.value
    })
  }

  handleTimeChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedTime:selectedOption,
      scheduleTime:selectedOption.value
    })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption: selectedOption })
    let gmtSelection = selectedOption.label.substring(1,10)
    // console.log(gmtSelection);
    // console.log(selectedOption.value)
    
    loadTimezonebasedSchedule(gmtSelection).then((response) =>{
      // console.log(response)
      this.setState({
        available_time:response
      })
    })
  }

  timezoneSelector = () => {
    const {
      selectedOption
    } = this.state
      
    if(this.props.widgetSettings.visitor_time_zone_status){
      return(
        <div className="select-wrapper" style={{paddingBottom:'0.6rem'}}>
          <SelectTimezone 
            value={selectedOption} 
            onChange={this.handleChange}
          />
        </div>
      )
    }
  }

  validateEmail = () => {
    if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
      return false;
    }
    return true;
  }

  validateBasicForm = (screen) => {
    if(screen==="before_call"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="before_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="before_call"){
          if(!this.state.username){
              return false;
          }
      }
    }

    if(screen==="during_call"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="during_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="during_call"){
          if(!this.state.username){
              return false;
          }
      }
    }

    if(screen==="after_call"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="after_call"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="after_call"){
          if(!this.state.username){
              return false;
          }
      }
    }

    if(screen==="before_schedule"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="before_schedule"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="before_schedule"){
          if(!this.state.username){
              return false;
          }
      }
    }

    if(screen==="after_schedule"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="after_schedule"){
        if(!this.state.email || this.state.email===undefined || this.state.email===''){
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="after_schedule"){
          if(!this.state.username){
              return false;
          }
      }
    }

    return true;
  }

  checkCustomRequiredCheckup(screen){
    var tmpField;
    if(screen==="before_call"){
      if(this.props.enable_cf_on_before_digitalcall){
        for(let tmpI = 0; tmpI<this.props.custom_fields_before_digitalcall_data.length; tmpI++){
           tmpField = this.props.custom_fields_before_digitalcall_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    if(screen==="before_schedule"){
      if(this.props.enable_cf_on_before_schedule){
        for(let tmpI = 0; tmpI<this.props.custom_fields_before_schedule_data.length; tmpI++){
           tmpField = this.props.custom_fields_before_schedule_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    if(screen==="after_schedule"){
      if(this.props.enable_cf_on_after_schedule){
        for(let tmpI = 0; tmpI<this.props.custom_fields_after_schedule_data.length; tmpI++){
           tmpField = this.props.custom_fields_after_schedule_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    if(screen==="during_call"){
      if(this.props.enable_cf_on_during_digitalcall){
        for(let tmpI = 0; tmpI<this.props.custom_fields_during_digitalcall_data.length; tmpI++){
           tmpField = this.props.custom_fields_during_digitalcall_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    if(screen==="after_call"){
      if(this.props.enable_cf_on_after_digitalcall){
        for(let tmpI = 0; tmpI<this.props.custom_fields_after_digitalcall_data.length; tmpI++){
           tmpField = this.props.custom_fields_after_digitalcall_data[tmpI];
            if(tmpField['enabled']){
              if(tmpField['required']){
                if(tmpField['type']==="email-text-box"){
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                    return false;
                  }
                }else{
                  if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                    return false;
                  }
                }
              }
          }
        }
      }
    }
    return true;
  }

  makeCall = () => {
     
    const isNormalFormValid = 1 || ( this.props.widgetSettings.full_name_required_before_call && this.props.widgetSettings.name) || ( this.props.widgetSettings.email_required_before_call && this.props.widgetSettings.email);
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var randomId = Math.random().toString(36).substring(3)+Math.floor(Date.now() / 1000);
    this.setState({
      callId: randomId,
      currentCallType: "digital_call"
    });
    // console.log(randomId);
    var apikey = getAPIkey();
    const params = {
      pId:apikey,
      name: !(this.state.username)?this.props.widgetSettings.name:this.state.username,
      ip:this.props.widgetSettings.ip_address,
      randomId: randomId,
      team:this.state.selected_team,
      customer_utm:this.props.widgetSettings.utm_data,
      source: this.props.widgetSettings.source,
      email: !(this.state.email)?this.props.widgetSettings.email:this.state.email
    };
    
    if (isNormalFormValid ) {
      this.countdownTimer();

        // this.setState({
        //   callStatusMessage: this.props.widgetSettings.language_data['during-call-now'],
        // })
      
      // console.log("trigger call");
      if (this.device) {
        var outgoingConnection = this.device.connect(params);
        this.channel = this.pusher.subscribe(`react-channel-random-${randomId}`);
        // console.log("pusher-channel id is ");
        // console.log(`react-channel-random-${randomId}`);

        this.pusher.bind('change-call-status', this.updateEvents);
        // console.log("pusher event binding");
        outgoingConnection.on('ringing', function() {
          // console.log('Ringing...');
        });
      }
      if(this.props.enable_cf_on_before_call){
        const beforeCallCustomData = {
          random_id:randomId,
          ...this.state.customValues
        };
        sendCustomFieldData(beforeCallCustomData).then(() => {
        }).catch((error) => {
          // console.log(error);
        });
      }
      setTimeout(
        function(){
          var widgetSetting = this.props.widgetSettings;
          pushTrackingDataForLead(randomId,widgetSetting)
        }.bind(this)
      , 10000);
    // --------
    } else {
      this.setState({
        errors: true
      });
    }
  }
  
  scheduleCallOptions = (timeSlots,optionsDate,optionsTime) => (
    <div className="call_later__wrapper">
      {!isEmpty(timeSlots) && Object.keys(timeSlots).map((dateVal) => {
          const lastTimeSlot = timeSlots[dateVal][timeSlots[dateVal].length - 1];
          let showDate;
          if(this.props.widgetSettings.date_format==="mm/dd/yyyy"){
            showDate = moment(dateVal).format("MM/DD/YYYY");
          }else if(this.props.widgetSettings.date_format==="dd/mm/yyyy"){
            showDate = moment(dateVal).format("DD/MM/YYYY");
          }
          if(moment(new Date(`${dateVal}`)).isSame(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "day")){
            showDate= "Today";
          }
          if(!moment(new Date(`${dateVal} ${lastTimeSlot}`)).isBefore(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "minute")){
            optionsDate.push({value:dateVal,label:showDate});
            if(!this.state.selectedDate){
              this.setState({
                selectedDate:optionsDate[0],
                scheduleDate:optionsDate[0].value
              })
            }
          } 
        })}
        <Select
          className="schedule-call-select-mod-smart scheduledate-selector"
          value={this.state.selectedDate}
          options={optionsDate}
          inputId="scheduledate"
          components={{
            IndicatorSeparator: () => null
          }}
          isSearchable={ false }
          onChange={this.handleDateChange}
          classNamePrefix="schedule-call-select-mod-smart"
        />
      {timeSlots[this.state.scheduleDate] && timeSlots[this.state.scheduleDate].map(timeVal => {
          let showTime;
          if(this.props.widgetSettings.time_format==="12"){
            showTime = moment(timeVal,'kk:mm:ss').format("hh:mm:ss a");
          }else{
            showTime = timeVal;
          }
          // console.log(showTime)
         if(!moment(new Date(`${this.state.scheduleDate} ${timeVal}`)).isBefore(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "minute")){
          optionsTime.push({value:timeVal,label:showTime});
          if(!this.state.selectedTime){
            this.setState({
              selectedTime:optionsTime[0],
              scheduleTime:optionsTime[0].value
            })
          }
         }
        })}

      <Select
        className="schedule-call-select-mod-smart"
        value={this.state.selectedTime}
        options={optionsTime}
        inputId="scheduleTime"
        isSearchable={ false }
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={this.handleTimeChange}
        classNamePrefix="schedule-call-select-mod-smart"
      />
    </div>
  );
  isEmptySettings(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  phoneNumberChangeHandler = (status, value, countryData, number) => {
    this.props.widgetSettings.phoneNumber = value;
    this.props.widgetSettings.country = countryData.iso2;
    this.props.widgetSettings.country_code = countryData.dialCode;
    document.phoneNumberIsValid= status;
    this.setState({
      phoneNumber: number,
      phoneNumberIsValid: status,
      error:false,
      countryDialCode: `+${countryData.dialCode}`
    });
  }

  triggerSmartBtnClick = () => {
    if(this.state.smartWidgetOpen){
      // console.log("not allowed");
    }else{
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'widget_opened',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
      this.triggerWidget()
    }
    if(this.state.connectingState){
      //use this is case you want to show slider again
      this.setState({
        openBubbleSlide:true
      })
    }
     
  }

  triggerWidget = () => {
    // console.log("connecting");
    this.setState({
      smartWidgetOpen: true,
      smartButtonClasses: 'smart-button-wide button-connecting ',
      connectingState: true,
      timerGoingOn:0
    })
    // return;
    if(this.props.enable_cf_on_before_schedule){
      this.props.widgetSettings.beforeScheduleQualificationSlide = 1;
    }
    if(this.props.enable_cf_on_after_schedule){
      this.props.widgetSettings.afterScheduleQualificationSlide = 1;
    }
    if(this.props.enable_cf_on_before_digitalcall){
      this.props.widgetSettings.beforeCallQualificationSlide = 1;
    }
    if(this.props.enable_cf_on_during_digitalcall){
      this.props.widgetSettings.duringCallQualificationSlide = 1; 
    }
    if(this.props.enable_cf_on_after_digitalcall){
      this.props.widgetSettings.afterCallQualificationSlide = 1;
    }
    if((this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab ===1 ) || (this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1)){
      if(this.props.widgetSettings.email_display_on==="before_schedule" || this.props.widgetSettings.full_name_display_on_call_later==="full_name_display_on_call_later"){
        this.props.widgetSettings.beforeScheduleQualificationSlide = 1;
        // console.log("before schedule qualification is enabled")
      }
      if(this.props.widgetSettings.email_display_on==="after_schedule" || this.props.widgetSettings.full_name_display_on_call_later==="after_schedule"){
        this.props.widgetSettings.afterScheduleQualificationSlide = 1;
        // console.log("after schedule qualification is enabled")
      }
    }

    if(this.props.widgetSettings.digital_call===0){
      this.digitalCallNotAvail()
    }else{
      if((this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab ===1 ) || (this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1)){
        if(this.props.widgetSettings.email_display_on_digital_call==="before_call" || this.props.widgetSettings.full_name_display_on_digital_call==="before_call"){
          this.props.widgetSettings.beforeCallQualificationSlide = 1;
          // console.log("before call qualification is enabled")
        }
        if(this.props.widgetSettings.email_display_on_digital_call==="during_call" || this.props.widgetSettings.full_name_display_on_digital_call==="during_call"){
          this.props.widgetSettings.duringCallQualificationSlide = 1;
          // console.log("during call qualification is enabled")
        }
        if(this.props.widgetSettings.email_display_on_digital_call==="after_call" || this.props.widgetSettings.full_name_display_on_digital_call==="after_call"){
          this.props.widgetSettings.afterCallQualificationSlide = 1;
          // console.log("after call qualification is enabled")
        }
      }


      if(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_digital_calls_tab===1){
        //ask for team selection before call
        this.setState({
          askForTeamSelection:true,
          teamQualificationBeforeCall:true,
          openBubbleSlide:true,
          slideBubbleClass:'lime-smartslide-open hdSlidesmall '
        })
        
      }else{
        this.triggerBeforeCallQualification()
      }
    }

  }

  match = (line, word) => {
    if(line===undefined){
      return false;
    }
    return line.includes(word);
  }

  digitalCallNotAvail = () =>{
    this.setState({
      hideRetryOption:true
    })
    this.callFailedAgentsNotAvail()
    this.scheduleCallScreen()
  }

  handleCustomDropdownChange = (name,selectedOption) =>{
    this.setState({
      customValues: {...this.state.customValues, [name]:selectedOption.value},
      error: false
    })
  }
  makeFormUnscrollable = () =>{
    this.setState({
      formHolderClass:'formHolder overflowed-y'
    })
  }

  makeFormScrollable = () =>{
    this.setState({
      formHolderClass:'formHolder'
    })
  }

  selectTeam = (selected_team) =>{
    // console.log(selected_team+ " is selected")
    this.setState({
      askForTeamSelection:false,
      teamSelected:true,
      selected_team:selected_team
    })
    if(this.state.teamQualificationBeforeCall){
      this.setState({
        teamQualificationBeforeCall:false,
        openBubbleSlide:false,
        slideBubbleClass:null
      })
      this.triggerBeforeCallQualification()
    }else{
      // console.log("team not selected")
    }
  }
  
  triggerBeforeCallQualification = () =>{
    if(this.props.widgetSettings.beforeCallQualificationSlide===1){
      this.setState({
        openBubbleSlide:true,
        slideBubbleClass:'lime-smartslide-open hdSlidesmall ',
        askForBeforeCallQualification:true
      })
    }else{
      this.makeCall()
    }
  }

  microphoneBlocked = () =>{
    this.setState({
      openBubbleSlide:true,
      slideBubbleClass:'lime-smartslide-open hdSlidesmall70 errorSlide ',
      thisMicrophoneBlocked:true
    })
    setTimeout(
      function() {
         this.setState({
            openBubbleSlide:false,
            smartWidgetOpen:false,
            smartButtonClasses:null,
            thisMicrophoneBlocked:false,
            connectingState:false
         })
        }
        .bind(this),
        3500
    );
  }

  scheduleCallTrigger = () =>{
    this.props.pushButtonEventToGA();
    // console.log("scheduling call");
    if(!this.state.phoneNumber || this.state.phoneNumber===undefined || this.state.phoneNumber===null){
      this.setState({
        error:true
      })
      return;
    }

    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicForm("before_schedule")){
      // console.log("basic form error");
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckup("before_schedule")){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    } 

    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'custom_field',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    this.setState({
      scheduleCallState:false,
      callScheduledSubmitted:true,
      loader:true
    })

    var username = !(this.state.username)?this.props.widgetSettings.name:this.state.username;
    if(username=== undefined){
      username = null;
    }
    var useremail = !(this.state.email)?this.props.widgetSettings.email:this.state.email;
    if(useremail=== undefined){
        useremail = null;
    }
    var apikey = null;
      if(this.props.widgetSettings.widget_type===2){
          apikey = this.props.widgetSettings.pId;
      }else{
          apikey = getAPIkey();
      }
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const scheduleCallData = { type: "SCHEDULE_CALL", scheduled_at: `${this.state.scheduleDate} ${this.state.scheduleTime}`, time_zone: this.state.selectedOption.value };
    const params = {
      phone: !(this.state.phoneNumber)?this.props.widgetSettings.phoneNumber:this.state.phoneNumber,
      name: username,
      pId: apikey,
      host_name: window.location.host,
      country_code: !(this.state.countryDialCode)?this.props.widgetSettings.country_code:this.state.countryDialCode,
      email: useremail,
      team: this.state.selected_team,
      customer_utm:this.props.widgetSettings.utm_data,
      source: this.props.widgetSettings.source,
      ...scheduleCallData
    };
    requestCall(params).then((response) => {
      this.props.widgetSettings.scheduledId = response;
      const scheduledTime = moment(new Date(params.scheduled_at), 'YYYY-MM-DD HH:mm:ss').format('LLLL');
      this.setState({
        loader:false,
        onScheduledResponse:true,
        scheduledTime:scheduledTime
       })
       pushTrackingDataForLead(response,this.props.widgetSettings);
       if(this.props.widgetSettings.afterScheduleQualificationSlide!==1){
        setTimeout(
          function() {
                this.closeSmartSlide()
            }
            .bind(this),
            3500
          );
       }

    })


  }

  callFailedAgentsNotAvail = () =>{
    this.setState({
      callFailedAgentsNotAvail:true,
      connectingState:false,
      openBubbleSlide:true,
      smartButtonClasses:'smart-button-wide smart-call-ended ',
      slideBubbleClass:'lime-smartslide-open hdSlidesmall '
    })
  }

  renderCallFailedScreen = () =>{
    if(this.state.callFailedAgentsNotAvail){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px',fontSize:'12px'}}>
            Sorry, our agents are not available. You can schedule a callback{(!this.state.hideRetryOption)?" or retry to connect with us.":"."}
            <div className="call-failed-schedule-container">
             <span className="call-schedulebutton-limeSmart" style={{fontFamily:(this.props.widgetSettings.widget_font)}} onClick={this.scheduleCallScreen}>
                <i className="material-icons schedule-icon-smartwid">date_range</i> Schedule a callback
              </span>
            </div>
            {(!this.state.hideRetryOption)?
            <React.Fragment>
              <div className="call-failed-retry-container">
                <span className="call-retrybutton-limeSmart" onClick={this.retryCall}>
                  <i className="material-icons schedule-icon-smartwid" style={{fontSize: '15px'}}>refresh</i> Retry Call
                </span>
              </div>
            </React.Fragment>
            :null
            }
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderTeamSelector = () =>{
    if(this.state.askForTeamSelection){
      let tmpTeam = [];
      let teamsData;
      if(this.state.scheduleCallState){
        teamsData =  this.props.widgetSettings.widget_teams;
      }else{
        teamsData =  this.props.widgetSettings.teams;
      }
      if(teamsData.length>0){
        for(let i = 0; i<teamsData.length; i++){
          tmpTeam.push(<div className="smartwid-team-selectorbutton" onClick={() => this.selectTeam(teamsData[i].id)}>{teamsData[i].name}</div>)
        }
      }
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px',textAlign:'left'}}>
            I want to speak to:
            <div className="smartwid-team-container">
              {tmpTeam}
            </div>
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderBeforeScheduleScreen = () =>{
    if(this.props.widgetSettings.beforeScheduleQualificationSlide===1){
      return(
        <React.Fragment>
          <div className="tel-input form__form-group">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="before_schedule") ? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}

              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="before_schedule") ? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName2" />
                </div>
                ): (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                )
              ] : null}
              {(this.props.enable_cf_on_before_schedule===true)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_before_schedule_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="before_schedule"
                />
              ): null}
              {this.state.error_email ? <div style={{ marginTop: '-10px', marginBottom: '15px' ,fontSize:'12px',fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            </div>
        </React.Fragment>
      )
    }
  }

  renderScheduleScreen = () =>{
    if(this.state.scheduleCallState){
      if((this.state.teamQualificationBeforeSchedule && this.state.teamSelected) || !this.state.teamQualificationBeforeSchedule){
        const timeSlots = this.state.available_time;
        let optionsDate = [];
        let optionsTime = [];
        return(
          <React.Fragment>
            <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px'}}>
             {(this.state.hideRetryOption)?
              "Would you like to schedule a callback at a later time?"
              :"Please provide us with convenient time for callback" 
            }
             
              <div className="smartwidget-phonenumber-container">
                <IntlTelInput
                  name="tel-no"
                  format=""
                  value={this.props.widgetSettings.phoneNumber}
                  useMobileFullscreenDropdown={false}
                  disabled={this.props.widgetSettings.allowedCountries.length<1}
                  onlyCountries={this.props.widgetSettings.allowedCountries}
                  separateDialCode={true}
                  onPhoneNumberChange={this.phoneNumberChangeHandler}
                  style={{fontFamily:this.props.widgetSettings.widget_font, fontSize:"13px", marginBottom:'0.7rem'}}
                  css={['intl-tel-input', 'form-control']}
                  defaultCountry={this.props.widgetSettings.default_country_code.toLowerCase()}
                />
                {this.timezoneSelector()}
                {this.scheduleCallOptions(timeSlots,optionsDate,optionsTime)}
                {this.renderBeforeScheduleScreen()}
                {this.state.error ? <div style={{ marginTop: '-10px', marginBottom: '15px',fontSize:'12px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
                <div style={{marginTop:'15px'}}>
                  <span className="smartschedule-btn-container" onClick={this.scheduleCallTrigger}>Schedule</span>
                </div>
              </div>
              
            </div>
          </React.Fragment>
        )
      }else{
        return;
      }
    }else{
      return;
    }
  }

  beforeCallSubmit = () =>{
    this.props.pushButtonEventToGA();
    // console.log("before call submit")
    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicForm("before_call")){
      // console.log("basic form error");
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckup("before_call")){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    } 
    
    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'custom_field',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    this.setState({
      openBubbleSlide:false,
      slideBubbleClass:null,
      askForBeforeCallQualification:false
    })

    this.makeCall()
  }

  duringCallSubmit = () =>{
    this.props.pushButtonEventToGA();
    // console.log("during call submit")
    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicForm("during_call")){
      // console.log("basic form error");
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckup("during_call")){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    } 
    
    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'custom_field',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    const postData = {
      random_id: this.state.callId,
      email: this.state.email,
      name: this.state.username,
    };

    sendFeedback(postData, this.state.callId).then(() => {
      this.setState({
        duringCallSubmitted:true
      })
      //this.props.onFeedbackDone();
    }).catch(() => {
      // this.props.onFeedbackDone();
    });

  }

  afterCallSubmit = () =>{
    this.props.pushButtonEventToGA();
    // console.log("after call submit")
    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicForm("after_call")){
      // console.log("basic form error");
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckup("after_call")){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    } 
    
    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'custom_field',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    const postData = {
      random_id: this.state.callId,
      email: this.state.email,
      name: this.state.username,
    };

    sendFeedback(postData, this.state.callId).then(() => {
      this.setState({
        afterCallSubmitted:true
      })
      if(this.state.emojiFeedbackSent){
        this.setState({
          feedbackSent:true,
          slideBubbleClass:'lime-smartslide-open hdSlidesmall70 ',
        })
        setTimeout(
          function() {
                this.closeFeedbackScreen()
            }
            .bind(this),
            3000
        );

      }
      //this.props.onFeedbackDone();
    }).catch(() => {
      // this.props.onFeedbackDone();
    });
  }

  retryCall = () =>{
    this.setState({
      callFailedAgentsNotAvail:false,
      openBubbleSlide:false,
      smartButtonClasses:null,
      slideBubbleClass:null
    })
    this.triggerWidget()
  }

  scheduleCallScreen = () =>{
    // console.log("schedule screen called")
    var teamenabled =false;
    if(this.props.widgetSettings.team_enabled_before_call && this.props.widgetSettings.team_in_call_later_tab){
      teamenabled = true;
    }else{
      teamenabled = false;
    }
    this.setState({
      scheduleCallState:true,
      callFailedAgentsNotAvail:false,
      smartButtonClasses: 'smart-button-wide smartwidget-button-fullsize ',
      askForTeamSelection:teamenabled,
      teamQualificationBeforeSchedule:teamenabled
    })
  } 
  updateCallConnectedState = (connectedAgent) =>{
    // console.log('updating call connected');
    if(!this.state.callEnded){
      let currentCallDuration;
      if(this.state.currentCallDuration){
        currentCallDuration = this.state.currentCallDuration;
      }else{
        currentCallDuration = 0;
      }
      this.setState({
        openBubbleSlide:true,
        slideBubbleClass:'lime-smartslide-open hdSlidesmall70 ',
        callConnectedState:true,
        connectingState:false,
        smartButtonClasses: 'smart-button-wide button-connected ',
        connectedAgentName:connectedAgent,
        currentCallDuration:currentCallDuration
      })
    }
    if(this.state.timerGoingOn===0){
      this.logicalCallTimer()
    }
  }

  endSmartCall = () => {
    if (this.device) {
      this.device.disconnectAll();
      // console.log("disconnecting call");
    }
    clearInterval(this.callTimerInterval)
    this.setState({
      smartButtonClasses:'smart-button-wide smart-call-ended ',
      callEnded:true,
      connectingState:false,
      connectedAgentName:null,
      timerGoingOn:0,
      currentCallDuration:0
    })
    this.feedbackScreen()
  }

  feedbackScreen = () => {
    if(!this.state.callConnectedState && !this.state.showFeedbackForm){
      this.closeFeedbackScreen()
    }else{
      this.setState({
        openBubbleSlide:true,
        callConnectedState:false,
        slideBubbleClass:'lime-smartslide-open hdSlidesmall ',
        showFeedbackSlide:true
      })

    }
  }

  closeFeedbackScreen = () =>{ 
    // console.log("close screen called");
    
    this.setState({
      openBubbleSlide:false,
      showFeedbackSlide:false,
      smartWidgetOpen:false,
      callEnded:false,
      showFeedbackForm:false,
      smartButtonClasses:null,
      feedbackSent:false,
      slideBubbleClass:null
    })
  }

  closeSmartSlide = () => {
    if(this.props.widgetSettings.google_analytics===1){
      let pId = getAPIkey();
      const params ={
        type: 'widget_closed',
        pId:pId,
        userIp: this.props.widgetSettings.ip_address
      };
      sendAnalyticsEvent(params);
    }

    this.setState({
      openBubbleSlide:false
    })

    if(this.state.callFailedAgentsNotAvail || this.state.scheduleCallState || this.state.callScheduledSubmitted || this.state.onScheduledResponse){
      this.setState({
        callFailedAgentsNotAvail:false,
        smartButtonClasses:null,
        slideBubbleClass:null,
        askForTeamSelection:false,
        scheduleCallState:false,
        smartWidgetOpen:false,
        onScheduledResponse:false,
        callScheduledSubmitted:false,
        teamSelected:false,
        postScheduleDataSubmitted:false
      })
    }
  }

  renderPostScheduledScreen = () =>{
    if(this.state.callScheduledSubmitted){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px',fontSize:'14px'}}>
            {this.state.loader?
            (<img src="https://cdn-images-1.medium.com/max/1600/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" alt="loader" style={{maxWidth:'100%'}} />) 
            :null}
            {(this.state.onScheduledResponse && !this.state.postScheduleDataSubmitted)?
            <React.Fragment>
              <div>Hola! Your call is scheduled for {this.state.scheduledTime}</div>
              <CheckCircleSolid
              style={{width:'50px',height:'50px',marginTop:'10px'}}
              ></CheckCircleSolid>
              {this.renderAfterScheduleQualification()}
            </React.Fragment>
            :null}
            {(this.state.postScheduleDataSubmitted)?
            <React.Fragment>
              Thank you for reaching out. We'll contact you soon!
            </React.Fragment>
            :null}
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

   pad = (val) =>{
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  logicalCallTimer = () =>{
    if(this.state.timerGoingOn===1){
      return;
    }else{
      this.setState({
        timerGoingOn:1
      })
     this.callTimerInterval =  setInterval(function(){
        if(!this.state.callConnectedState){
          return;
        }
        var totalSeconds = this.state.currentCallDuration;
        ++totalSeconds;
        this.setState({
          currentCallDuration:totalSeconds
        })
      }.bind(this), 
      1000);
    }
  }

  callTimer = () =>{
    // console.log("render call timer");    
    return(
      <React.Fragment>
        {this.pad(parseInt(this.state.currentCallDuration / 60))} : {this.pad(this.state.currentCallDuration % 60)}
      </React.Fragment>
    )
  }

  sendRatingFeedback = (rating) =>{
    // console.log(rating +"stars are rated");
    const postData = {
      random_id: this.state.callId,
      email: this.state.email,
      name: this.state.username,
      rating: rating,
      feedback: this.state.feedback,
    };

    sendFeedback(postData).then(() => {
    }).catch((error) => {
      // console.log(error);
    });

    if(this.props.widgetSettings.afterCallQualificationSlide===1 && !this.state.afterCallSubmitted){
      this.setState({
        emojiFeedbackSent:true
      })
    }else{
      this.setState({
        emojiFeedbackSent:true,
        feedbackSent:true,
        slideBubbleClass:'lime-smartslide-open hdSlidesmall70 ',
      })
      setTimeout(
        function() {
              this.closeFeedbackScreen()
          }
          .bind(this),
          3000
      );
    }
    
  }
  renderConnectedCallButton = () =>{
    if(this.state.callConnectedState){
      return(
        <React.Fragment>
          <span className="smart-timer-lime">{this.callTimer()}</span>
          <span className="smart-button-status smart-connected-limeStat" style={{fontFamily:(this.props.widgetSettings.widget_font)}}><span className="smart-callConnectedStat">Connected</span></span>
          <i className="material-icons smart-call-cut animated infinite pulse " onClick={this.endSmartCall}>call_end</i>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  loadwidgetBubble = () =>{
    if(this.props.widgetSettings.cta_button===1){
      return(
        <span className="smart-button-status" style={{fontFamily:(this.props.widgetSettings.widget_font),display:"inline-block"}}>
         <i className="material-icons" style={{position:"absolute",left:"20px",top:"2px"}}>call</i> 
        <span className="smartButtonMainCTA">{this.props.widgetSettings.cta_main_field}</span>
         <span className="smart-button-status smart-connected-limeStat smartButtonMainCTAsecondary">{this.props.widgetSettings.cta_secondary_field}</span>
        </span>
      )
    }else{
      return(
         <i className="material-icons">call</i>
      )
    }
    
  }

  renderDuringCallQualification = () =>{
    if(this.props.widgetSettings.duringCallQualificationSlide ===1 && !this.state.duringCallSubmitted){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px', paddingBottom:'20px'}}>
            <p style={{fontSize:'12px'}}>Let us know a few details about you</p>
            <div className="tel-input form__form-group">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="during_call") ? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}

              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="during_call") ? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName2" />
                </div>
                ): (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                )
              ] : null}
              {(this.props.enable_cf_on_during_digitalcall===true)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_during_digitalcall_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="during_digital_call"
                />
              ): null}
              {this.state.error ? <div style={{ marginTop: '-10px', marginBottom: '15px',fontSize:'12px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
              {this.state.error_email ? <div style={{ marginTop: '-10px', marginBottom: '15px' ,fontSize:'12px',fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            </div>
            <div className="call-failed-schedule-container" style={{marginBottom:'18px'}}>
             <span className="call-schedulebutton-limeSmart" style={{fontFamily:(this.props.widgetSettings.widget_font) , padding:'7px 16px'}} onClick={() => this.duringCallSubmit()}>
                Submit
              </span>
            </div>
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderAfterCallQualificationSlide = () =>{
    if(this.props.widgetSettings.afterScheduleQualificationSlide ===1 && !this.state.afterCallSubmitted){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px'}}>
            <p style={{fontSize:'12px'}}>Let us know a few details about you</p>
            <div className="tel-input form__form-group">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="after_call") ? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}

              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="after_call") ? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                ): (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                )
              ] : null}
              {(this.props.enable_cf_on_after_digitalcall===true)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_after_digitalcall_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="after_call_digital"
                />
              ): null}
              {this.state.error ? <div style={{ marginTop: '-10px', marginBottom: '15px',fontSize:'12px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
              {this.state.error_email ? <div style={{ marginTop: '-10px', marginBottom: '15px' ,fontSize:'12px',fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            </div>
            <div className="call-failed-schedule-container" style={{marginBottom:'18px'}}>
             <span className="call-schedulebutton-limeSmart" style={{fontFamily:(this.props.widgetSettings.widget_font) , padding:'7px 16px'}} onClick={() => this.afterCallSubmit()}>
                Submit
              </span>
            </div>
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderConnectedCallSlide= () =>{
    if(this.state.callConnectedState){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px'}}>
            Your call is connected and you are speaking to agent {this.state.connectedAgentName}
            {this.renderDuringCallQualification()}
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  postScheduleSubmit = () =>{
    this.props.pushButtonEventToGA();
    const params = {
      lead_id: this.props.widgetSettings.scheduledId,
      name:!(this.props.widgetSettings.name)?' ':this.props.widgetSettings.name,
      email:!(this.props.widgetSettings.email)?' ':this.props.widgetSettings.email,
    }

    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true
        })
        return;
      }
    }

    if(!this.validateBasicForm("after_schedule")){
      // console.log("basic form error");
      this.setState({
        error:true
      })
      return;
    }
    if(!this.checkCustomRequiredCheckup("after_schedule")){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return;
    } 

    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        let pId = getAPIkey();
        const params ={
          type: 'custom_field',
          pId:pId,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }
    
    if(params){
      sendFeedback(params).then((response) => {
        this.setState({
          postScheduleDataSubmitted:true,
          slideBubbleClass:'lime-smartslide-open hdSlidesmall70 '
        })
        setTimeout(
          function() {
                this.closeSmartSlide()
            }
            .bind(this),
            2000
          );

      })
    }
  }

  renderBeforeCallQualification = () =>{
    if(this.state.askForBeforeCallQualification){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px'}}>
            <p style={{fontSize:'12px'}}>Let us know a few details about you</p>
            <div className="tel-input form__form-group">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="before_call") ? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}

              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="before_call") ? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName2" />
                </div>
                ): (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                )
              ] : null}
              {(this.props.enable_cf_on_before_digitalcall===true)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_before_digitalcall_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="before_call_digital"
                />
              ): null}
              {this.state.error ? <div style={{ marginTop: '-10px', marginBottom: '15px',fontSize:'12px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
              {this.state.error_email ? <div style={{ marginTop: '-10px', marginBottom: '15px' ,fontSize:'12px',fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            </div>
            <div className="call-failed-schedule-container" style={{marginBottom:'18px'}}>
             <span className="call-schedulebutton-limeSmart" style={{fontFamily:(this.props.widgetSettings.widget_font) , padding:'7px 16px'}} onClick={() => this.beforeCallSubmit()}>
              Start Call
              </span>
            </div>
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderAfterScheduleQualification = () =>{
    if(this.props.widgetSettings.afterScheduleQualificationSlide===1){
      return(
        <React.Fragment>
          <p style={{fontSize:'12px', marginTop:'0.3rem', marginBottom:'0.6rem'}}>Let us know a few details about you</p>
          <div className="tel-input form__form-group">
              {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="after_schedule") ? [
                (this.props.widgetSettings.email_required_before_call===1) ? (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
                </div>
                ) : (
                <div id="floatContainer2" className="email-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
                </div>
                )
              ] : null}

              {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="after_schedule") ? [
                (this.props.widgetSettings.full_name_required_before_call===1) ? (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName2" />
                </div>
                ): (
                <div id="floatContainer3" className="name-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                  <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
                </div>
                )
              ] : null}
              {(this.props.enable_cf_on_after_schedule===true)?(
                <CustomFields
                  widgetSettings={this.props.widgetSettings}
                  customFieldsData = {this.props.custom_fields_after_schedule_data}
                  customFieldValueChange = {this.customFieldValueChange.bind(this)}
                  handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                  makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                  makeFormScrollable = {this.makeFormScrollable.bind(this)}
                  customValues = {this.state.customValues}
                  currentScreen="after_schedule"
                />
              ): null}
              {this.state.error ? <div style={{ marginTop: '-10px', marginBottom: '15px',fontSize:'12px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
              {this.state.error_email ? <div style={{ marginTop: '-10px', marginBottom: '15px' ,fontSize:'12px',fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
            </div>
            <div className="call-failed-schedule-container" style={{marginBottom:'18px'}}>
             <span className="call-schedulebutton-limeSmart" style={{fontFamily:(this.props.widgetSettings.widget_font) , padding:'7px 16px'}} onClick={() => this.postScheduleSubmit()}>
                Submit
              </span>
            </div>
        </React.Fragment>
      )
    }
  }

  renderBlockedSlide = () =>{
    if(this.state.thisMicrophoneBlocked){
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px',paddingBottom:'20px'}}>
          Please, check the permissions in your browser and allow access to your microphone.
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  renderPoweredbySection = () =>{
    return(
      <React.Fragment>
        <div className="poweredby-container">
          <p style={{marginBottom:'0'}}>
            <a href={`http://limecall.com/pages/?utm_source=${window.location.origin}`} target="_blank" rel="noopener noreferrer" className="poweredby-section"><img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon" />We run on LimeCall</a>
          </p>
        </div>
      </React.Fragment>
    )
  }

  renderFeedbackSlide = () =>{
    if(this.state.showFeedbackSlide){
      // console.log("rendering feedback screen");
      return(
        <React.Fragment>
          <div className="feedbackSlide" style={{fontFamily:(this.props.widgetSettings.widget_font),paddingTop:'10px'}}>
            {(this.state.feedbackSent)?
            <React.Fragment>
              Thank you for your feedback <span className="smiley-container"><EmojiHappy></EmojiHappy></span>
            </React.Fragment>
            :
            <React.Fragment>
              {!this.state.emojiFeedbackSent?
                <React.Fragment>
                  How would you like to rate this call?
                  <span className="smartEmoji-container">
                    <span className="smartEmojiSign">
                      <EmojiMad 
                        send_rating_feedback={this.sendRatingFeedback.bind(this)}
                        rating_stars="1" 
                      ></EmojiMad>
                      <span className="smartEmojiSign-tooltip">Very bad</span>
                    </span>
                    <span className="smartEmojiSign">
                      <EmojiSad 
                        send_rating_feedback={this.sendRatingFeedback.bind(this)}
                        rating_stars="2" 
                      ></EmojiSad>
                      <span className="smartEmojiSign-tooltip">Poor</span>
                    </span>
                    <span className="smartEmojiSign">
                      <EmojiNeutral 
                        send_rating_feedback={this.sendRatingFeedback.bind(this)}
                        rating_stars="3" 
                      ></EmojiNeutral>
                      <span className="smartEmojiSign-tooltip">Fair</span>
                    </span>
                    <span className="smartEmojiSign">
                      <EmojiHappy 
                        send_rating_feedback={this.sendRatingFeedback.bind(this)}
                        rating_stars="4" 
                      ></EmojiHappy>
                      <span className="smartEmojiSign-tooltip">Good</span>
                    </span>
                    <span className="smartEmojiSign">
                      <EmojiSuperHappy 
                        send_rating_feedback={this.sendRatingFeedback.bind(this)}
                        rating_stars="5" 
                      ></EmojiSuperHappy>
                      <span className="smartEmojiSign-tooltip">Excellent</span>
                    </span>
                  </span>
                </React.Fragment>
              :null}
              {this.renderAfterCallQualificationSlide()}
            </React.Fragment>
            }
          </div>
        </React.Fragment>
      )
    }else{
      return;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="limecall-widget popup-button" style={this.floatingStyle[this.props.widgetSettings['bubble_position']]}>
        {/* {(!this.state.modalIsOpen && this.match(this.state.widgetSettings['bubble_position'], "right") )? (<div className="twilio-popup" style={{fontFamily:(this.state.widgetSettings.widget_font)}}>{ (this.state.widgetSettings.bubble_text===undefined)?"Hey there! How can we help?":this.state.widgetSettings.bubble_text }</div>) : null} */}
          <div className={[(this.state.openBubbleSlide)?this.state.slideBubbleClass:null]+" lime-smart-bubbleslide "+[this.match(this.props.widgetSettings['bubble_position'], "top")?"lime-smart-bubbleslide-top":"lime-smart-bubbleslide-bottom"]+[this.match(this.props.widgetSettings['bubble_position'], "right")?" lime-smart-bubbleslide-right":""]} style={{borderWidth: "3px 0px 0px", borderStyle: "solid", borderColor: "rgb(76, 217, 100)"}}>
            {(this.state.askForBeforeCallQualification)? null :
              <div className="smartslide-close-btn" onClick={this.closeSmartSlide}>
              <i className="material-icons" style={{fontSize:'15px'}}>close</i>
            </div>
            }
            {this.renderFeedbackSlide()}
            {this.renderConnectedCallSlide()}
            {this.renderBlockedSlide()}
            {this.renderCallFailedScreen()}
            {this.renderTeamSelector()}
            {this.renderBeforeCallQualification()}
            {this.renderScheduleScreen()}
            {this.renderPostScheduledScreen()}
            {this.renderPoweredbySection()}
          </div>
          {(!this.state.smartWidgetOpen && this.match(this.props.widgetSettings['bubble_position'], "right") && this.props.widgetSettings.bubble_text_status)? (<div className="twilio-popup" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>{ (this.props.widgetSettings.bubble_text===undefined)?"Hey there! How can we help?":this.props.widgetSettings.bubble_text }</div>) : null}
          <div role="presentation" onClick={this.triggerSmartBtnClick} 
            className={[(this.state.smartWidgetOpen)? this.state.smartButtonClasses :this.state.smartBtnIntClass]+"twilio-wd-open-widget-popup"}
            style={{background:this.props.widgetSettings.circle_color, 'borderRadius':((this.props.widgetSettings.shape==="default")?"50%":"0%")}}>
              {(this.state.connectingState)?
              <React.Fragment>
                <span className="smart-button-status" style={{fontFamily:(this.props.widgetSettings.widget_font), display:'inline-block'}}><SmartLoading></SmartLoading> Connecting</span>
                <i className="material-icons smart-call-cut animated infinite pulse" onClick={this.endSmartCall}>call_end</i>
                </React.Fragment>
                :null
              }
              {(this.state.callEnded)?
                <span className="smart-button-status fullsize-smart-statusbox" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>Call Ended</span>
                :null
              }
              {(this.state.callFailedAgentsNotAvail)?
                <span className="smart-button-status fullsize-smart-statusbox" style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:'12px'}}>Sorry, we're busy at the moment.</span>
               :null
              }
              {(this.state.scheduleCallState && this.state.hideRetryOption)?
                <span className="smart-button-status fullsize-smart-statusbox" style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:'12px'}}>{(this.props.widgetSettings.offline_schedule_call_message)?this.props.widgetSettings.offline_schedule_call_message:"Sorry, we are out of the office."}</span>
                :null
              }
              {(this.state.scheduleCallState && !this.state.hideRetryOption)?
                <span className="smart-button-status fullsize-smart-statusbox" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>Schedule Call</span>
                :null
              }
              {(this.state.callScheduledSubmitted)?
                <span className="smart-button-status fullsize-smart-statusbox" style={{fontFamily:(this.props.widgetSettings.widget_font)}}>Call Scheduled</span>
                :null
              }
              {(!this.state.connectingState && !this.state.callEnded && !this.state.callConnectedState && !this.state.callFailedAgentsNotAvail && !this.state.scheduleCallState && !this.state.callScheduledSubmitted && !this.state.showFeedbackForm)?
                this.loadwidgetBubble()
              :null  }
              {this.renderConnectedCallButton()}
          </div>
          {(!this.state.smartWidgetOpen && this.match(this.props.widgetSettings['bubble_position'], "left") && this.props.widgetSettings.bubble_text_status )? (<div className="twilio-popup">{ (this.props.widgetSettings.bubble_text===undefined)?"Hey there! How can we help?":this.props.widgetSettings.bubble_text }</div>) : null}
        </div>  
        {processSmartFloatLabels()}
      </React.Fragment>
    );
  }
}


export default SmartTemplate;
