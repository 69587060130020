import React from 'react';

export function EmojiMad(props) {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.935 473.935" style={{enableBackground: 'new 0 0 473.935 473.935'}} xmlSpace="preserve" width="473.935" height="473.935" {...props}>
      <circle style={{fill: '#FFC10E'}} cx="236.967" cy="236.967" r="236.967" onClick={() => props.send_rating_feedback(props.rating_stars)} />
      <g>
        <path style={{fill: '#333333'}} d="M357.318,354.8c-66.084-68.291-174.663-68.288-240.743,0.007
        		c-8.389,8.666,4.827,21.912,13.227,13.227c59.139-61.118,155.146-61.122,214.288-0.007
        		C352.491,376.708,365.711,363.466,357.318,354.8L357.318,354.8z" />
        <circle style={{fill: '#333333'}} cx="164.938" cy="192.665" r="37.216" />
        <circle style={{fill: '#333333'}} cx="305.667" cy="192.665" r="37.216" />
        <path style={{fill: '#333333'}} d="M316.364,68.389c-28.677,28.673-57.35,57.35-86.024,86.024c4.408,0,8.819,0,13.227,0
        		c-28.673-28.677-57.347-57.35-86.02-86.024c-8.539-8.539-21.766,4.688-13.227,13.227c28.673,28.677,57.347,57.35,86.02,86.024
        		c3.603,3.603,9.624,3.603,13.227,0c28.677-28.673,57.35-57.35,86.024-86.024C338.13,73.077,324.899,59.846,316.364,68.389
        		L316.364,68.389z" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export function EmojiSad(props) {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.935 473.935" style={{enableBackground: 'new 0 0 473.935 473.935'}} xmlSpace="preserve" width="473.935" height="473.935" {...props}>
        <circle style={{fill: '#FFC10E'}} cx="236.967" cy="236.967" r="236.967" onClick={() => props.send_rating_feedback(props.rating_stars)} />
        <g>
          <path style={{fill: '#333333'}} d="M356.671,354.1c-66.226-67.618-174.255-67.337-240.096,0.703
                  c-8.389,8.666,4.827,21.912,13.227,13.227c58.87-60.83,154.386-61.204,213.641-0.703C351.896,375.96,365.116,362.721,356.671,354.1
                  L356.671,354.1z" />
          <circle style={{fill: '#333333'}} cx="164.938" cy="155.232" r="37.216" />
          <circle style={{fill: '#333333'}} cx="305.667" cy="155.232" r="37.216" />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  }
  
  export function EmojiSuperHappy(props) {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.931 473.931" style={{enableBackground: 'new 0 0 473.931 473.931'}} xmlSpace="preserve" width="473.931" height="473.931" {...props}>
        <circle style={{fill: '#FFC10E'}} cx="236.966" cy="236.966" r="236.966" onClick={() => props.send_rating_feedback(props.rating_stars)} />
        <path style={{fill: '#333333'}} d="M81.391,237.127c0,85.911,69.649,155.56,155.56,155.56c85.915,0,155.567-69.649,155.567-155.56
            H81.391z" />
        <path style={{fill: '#CA2027'}} d="M150.452,298.705c0,47.771,38.731,86.498,86.498,86.498c47.775,0,86.502-38.731,86.502-86.498
            H150.452z" />
        <g>
          <circle style={{fill: '#333333'}} cx="164.937" cy="155.231" r="37.216" />
          <circle style={{fill: '#333333'}} cx="305.664" cy="155.231" r="37.216" />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  }
  
  export function EmojiNeutral(props) {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.931 473.931" style={{enableBackground: 'new 0 0 473.931 473.931'}} xmlSpace="preserve" width="473.931" height="473.931" {...props}>
        <circle style={{fill: '#FFC10E'}} cx="236.966" cy="236.966" r="236.966" onClick={() => props.send_rating_feedback(props.rating_stars)} />
        <g>
          <circle style={{fill: '#333333'}} cx="164.937" cy="155.231" r="37.216" />
          <circle style={{fill: '#333333'}} cx="305.664" cy="155.231" r="37.216" />
          <path style={{fill: '#333333'}} d="M105.278,326.581c86.386,0,172.776,0,259.162,0c12.067,0,12.067-18.709,0-18.709
                  c-86.386,0-172.776,0-259.162,0C93.211,307.872,93.211,326.581,105.278,326.581L105.278,326.581z" />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  }
  
  export function EmojiHappy(props) {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.935 473.935" style={{enableBackground: 'new 0 0 473.935 473.935'}} xmlSpace="preserve" width="473.935" height="473.935" {...props}>
        <circle style={{fill: '#FFC10E'}} cx="236.967" cy="236.967" r="236.967" onClick={() => props.send_rating_feedback(props.rating_stars)} />
        <g>
          <circle style={{fill: '#333333'}} cx="164.938" cy="155.232" r="37.216" />
          <circle style={{fill: '#333333'}} cx="305.667" cy="155.232" r="37.216" />
          <path style={{fill: '#333333'}} d="M343.257,316.862c-59.281,60.329-154.663,59.854-213.45-0.898
                  c-8.4-8.685-21.616,4.561-13.227,13.227c65.769,67.97,173.645,68.34,239.905,0.898
                  C364.944,321.479,351.721,308.245,343.257,316.862L343.257,316.862z" />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  }

  export function CheckCircleSolid(props) {
    return (
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" className="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={512} height={512} {...props}>
        <path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
      </svg>
    );
  }
  
  export function SmartLoading(props) {
    return (
      <svg width={25} height={25} style={{verticalAlign:'middle'}} viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#fff" {...props}>
        <g fill="none" fillRule="evenodd" strokeWidth={2}>
          <circle cx={22} cy={22} r={1}>
            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
          <circle cx={22} cy={22} r={1}>
            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
        </g>
      </svg>
    );
  }