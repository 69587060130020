import React, { Component } from 'react';
import Select from "react-select";
import {
    widgetFormInitialState,
  } from "../HelperMethods";

class CustomFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...widgetFormInitialState,
        }
    }



  addCustomFields = () => {
    if(!this.props.customFieldsData){
      return;
    }
    let fieldContainerClass = null;
    let textareaContainerClass = null; 
    if(this.props.widgetSettings.template_type===2){
        fieldContainerClass = "float-containerSmart ";
        textareaContainerClass = "textarea-float-containerSmart ";
    }else{
        fieldContainerClass = "float-container ";
        textareaContainerClass = "textarea-float-container ";
    }
    let customFieldsData = this.props.customFieldsData;
    let currentScreen = this.props.currentScreen; 
    let op = [];
    let custom_fields_array = [];
    for(let tmpI = 0; tmpI<customFieldsData.length; tmpI++){
            if(customFieldsData[tmpI].type==='text-box' && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                if(customFieldsData[tmpI].required===1){
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className="custom-textinput-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type={customFieldsData[tmpI].type} required onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }else{
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className="custom-textinput-container" style={{fontFamily:(this.props.widgetSettings.widget_font)}}><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type={customFieldsData[tmpI].type}  onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }
            }
            if(customFieldsData[tmpI].type==='email-text-box' && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                if(customFieldsData[tmpI].required===1){
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={fieldContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label} <span className="lime-label-required" >*</span></label><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type="email" required onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }else{
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={fieldContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label}</label><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type="email"  onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }
            }
            if(customFieldsData[tmpI].type==='date-time' && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                if(customFieldsData[tmpI].required===1){
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={fieldContainerClass +" active"} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label} <span className="lime-label-required" >*</span></label><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type="datetime-local" required onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }else{
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={fieldContainerClass + " active"} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label}</label><input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}}  type="datetime-local" onChange={event => this.props.customFieldValueChange(event)}  name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} /></div>);
                }
            }
            if(customFieldsData[tmpI].type==="drop-down" && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                let tmpOptions = customFieldsData[tmpI].value.split(',');
                let tmpA = [];
                for(let tmpJ = 0; tmpJ<tmpOptions.length; tmpJ++){
                    tmpA.push({value:tmpOptions[tmpJ],label:tmpOptions[tmpJ]});
                }
                op.push(<div className="tel-input position-relative form-group" style={{fontFamily:this.props.widgetSettings.widget_font}}> 
                    <Select
                        className="team-dropdown-select-mod"
                        placeholder={(customFieldsData[tmpI].required===1) ? customFieldsData[tmpI].label+" *":customFieldsData[tmpI].label}
                        options={tmpA}
                        inputId={customFieldsData[tmpI].name+currentScreen}
                        components={{
                        IndicatorSeparator: () => null
                        }}
                        isSearchable={ false }
                        onMenuOpen={this.props.makeFormUnscrollable.bind(this)}
                        onMenuClose={this.props.makeFormScrollable.bind(this)}
                        onChange={this.props.handleCustomDropdownChange.bind(this,customFieldsData[tmpI].name)}
                        classNamePrefix="team-dropdown-select-mod"
                    />
                    </div>);
            }
            if(customFieldsData[tmpI].type==="radio" && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                let tmpOptions = customFieldsData[tmpI].value.split(',');
                if(customFieldsData[tmpI].required===1){
                    op.push(<p style={{fontFamily:this.props.widgetSettings.widget_font}}><label className="lime-label-normal">{customFieldsData[tmpI].label} </label><label className="lime-label-required" htmlFor={customFieldsData[tmpI].name} >*</label> </p>);
                }else{
                    op.push(<p style={{fontFamily:this.props.widgetSettings.widget_font}}><label className="lime-label-normal">{customFieldsData[tmpI].label} </label></p>);
                }
                for(let tmpJ = 0; tmpJ<tmpOptions.length; tmpJ++){
                    op.push(<div className="tel-input position-relative form-group limecall-checkbox-conttainer" style={{fontFamily:this.props.widgetSettings.widget_font}}><input onChange={event => this.props.customFieldValueChange(event)} className="feedbackInput-checkbox"  type="radio" name={customFieldsData[tmpI].name} style={{fontFamily:this.props.widgetSettings.widget_font}} value={tmpOptions[tmpJ]}/> {tmpOptions[tmpJ]}</div>);
                }
            }
            if(customFieldsData[tmpI].type==="checkbox" && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                let tmpOptions = customFieldsData[tmpI].value.split(',');
                let chkBox =[];
                for(let tmpJ = 0; tmpJ<tmpOptions.length; tmpJ++){
                    chkBox.push(<div className="tel-input position-relative form-group limecall-checkbox-conttainer"><input onChange={event => this.props.customFieldValueChange(event)}  className="feedbackInput-checkbox" type="checkbox" name={customFieldsData[tmpI].name} value={tmpOptions[tmpJ]}/> <label className="feedbackInput-checkbox-label" style={{fontFamily:this.props.widgetSettings.widget_font}}>{tmpOptions[tmpJ]}</label></div>);
                }
                if(customFieldsData[tmpI].required===1){
                op.push(<div class="customCheckboxContainer"><p style={{fontFamily:this.props.widgetSettings.widget_font}}><label className="lime-label-normal">{customFieldsData[tmpI].label} </label><label className="lime-label-required" htmlFor={customFieldsData[tmpI].name} >*</label></p> {chkBox} </div>);
                }else{
                    op.push(<div class="customCheckboxContainer"><p style={{fontFamily:this.props.widgetSettings.widget_font}}><label className="lime-label-normal">{customFieldsData[tmpI].label} </label></p> {chkBox} </div>);
                }
            }
            if(customFieldsData[tmpI].type==="paragraph" && customFieldsData[tmpI].enabled===1){
                custom_fields_array[tmpI] = customFieldsData[tmpI].type;
                if(customFieldsData[tmpI].required===1){
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={textareaContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label} <span className="lime-label-required" >*</span></label><textarea style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px", paddingTop:"20px" ,height:"50px"}} required onChange={event => this.props.customFieldValueChange(event)} rows="4" name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} ></textarea></div>);
                }else{
                    op.push(<div id={"floatContainer"+customFieldsData[tmpI].name+currentScreen} className={textareaContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}><label for={customFieldsData[tmpI].name+currentScreen}>{customFieldsData[tmpI].label} </label><textarea style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px", paddingTop:"20px" ,height:"50px"}} onChange={event => this.props.customFieldValueChange(event)} rows="4" name={customFieldsData[tmpI].name} data-placeholder={customFieldsData[tmpI].label} id={customFieldsData[tmpI].name+currentScreen} ></textarea></div>);
                }
            }
    }

    return (<div>{op}</div>);
  }

    render() {
        return (
          <React.Fragment>
            {(this.addCustomFields())}
          </React.Fragment>
        );
    }
}

export default CustomFields;
