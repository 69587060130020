import React from 'react';

import * as EmailValidator from 'email-validator';
import Pusher from 'pusher-js';
import Select from "react-select";
import {
  widgetFormInitialState,
  callStatusMessages,
  triggerPublicEvent,
  processFloatLabels,
  pushTrackingDataForLead
} from "../HelperMethods";
import CountdownTimer from "./CountdownTimer";
import {
    loadTwilioClient,
    getAPIkey,
    sendCustomFieldData,
    sendUTMdata,
    sendAnalyticsEvent
} from "../WidgetAPICalls";

import CustomFields from './CustomFields';
import Twilio from "twilio-client";


class DigitalCallForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...widgetFormInitialState,
      email: this.props.widgetSettings.email,
      username: this.props.widgetSettings.name,
      formHolderClass: "formHolder",
      emailContainerClass: "email-container",
      nameContainerClass: "name-container",
      companyNameContainerClass: "company-name-container",
      teamContainerClass : "tel-input position-relative form-group"
    };
    this.fontStyle =  {
      fontFamily: this.props.widgetSettings.widget_font
    }
  }

  fontStyle =  {}

  componentWillMount() {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'eu',
      forceTLS: true,
      disableStats: true
    });
    this.pusher.connection.bind( 'error', function( err ) {
      if( err.error.data.code === 4004 ) {
        console.log('Over limit!');
      }
    });
    loadTwilioClient().then((response) => {
        // console.log(response);
        this.device = new Twilio.Device(response.token, {
            codecPreferences: ['opus', 'pcmu'],
            fakeLocalDTMF: true,
            enableRingingState: true,
          }); 

          this.device.on('ready',function (device) {
            // console.log('Device Ready!');
          });

          this.device.on('error', function (error) {
            // console.log('Device Error: ' + error.message);
          });

          this.device.on('connect',  (conn) => {
            // console.log('Successfully established call!');
            triggerPublicEvent('callInitiation',null)
            this.setState({
                // showLoader:false,
                showDisconnect: true,
                callStatusMessage:"Hold on! We are finding teams to place a call..."
                // callStatusMessage:this.props.widgetSettings.language_data["hola-finding-team-during-call-now"]
            });

          });

          this.device.on('disconnect',  (conn) => {
            // console.log('Call ended.');
            // document.getElementById('button-call').style.display = 'inline';
            // document.getElementById('button-hangup').style.display = 'none';
            this.setState({
                hideLoadingArea:true,
                currentCallComplete:true,
                callStatusMessage:"Call Ended",
                showBackButton:true,
                showDisconnect: false
            });
            pushTrackingDataForLead(this.state.callId,this.props.widgetSettings)
          });

          this.device.on('incoming', function (conn) {
            // console.log('Incoming connection from ' + conn.parameters.From);
              // accept the incoming connection and start two-way audio
              conn.accept();
          });

    })
    .catch(function (err) {
        // console.log(err);
        // console.log('Could not get a token from server!');
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleCall !== this.props.scheduleCall
      || this.props.activeTab !== nextProps.activeTab) {
      this.setState({
        ...widgetFormInitialState
      });
    }
  }

  resetForm = () => {
    this.setState(prevState => ({
      ...widgetFormInitialState
    }));
  }

  resetCallState(){
    this.setState({
      scheduleCallFormSecond: false,
      countdownBegun:false,
      currentCallComplete:false,
      callStatusMessage:"",
      thisCallStarted:false,
      showBackButton:false,
      show_reconnect:false,
      hideLoadingArea:false
    })
  }
  
  setStateMessageBinder(){
    this.setState({
      callStatusMessage:"Your call is connected and you are speaking to agent "+this.state.agent_name+".",
      currentCallComplete:true
    })
  }

  disconnectCall(){
    if (this.device) {
        this.device.disconnectAll();
        // console.log("disconnecting call");
      }
  }

  onValueChange = (event) => {
    if(event.target.name==="email"){
      this.setState({
        error_email: false,
        emailContainerClass : "email-container"
      })
      this.props.widgetSettings.email = event.target.value;
      if(event.target.value===null || event.target.value===''){
        this.props.widgetSettings.email = null;
      }
    }else if(event.target.name==="username"){
      this.setState({
        nameContainerClass:"name-container"
      })
      this.props.widgetSettings.name = event.target.value;
    }else if(event.target.name==="team"){
      this.props.widgetSettings.team = event.target.value;
    }else if(event.target.name==="companyname"){
      this.setState({
        companyNameContainerClass: "company-name-container"
      })
      this.props.widgetSettings.companyname = event.target.value;
    }
    
      this.setState({
        [event.target.name]: event.target.value,
        error: false
      })
      if(event.target.value==='' || event.target.value===null){
        this.setState({
          [event.target.value]: null
        })
      }
    
  }

  onChangeOptions = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  updateEvents = (data) => {
    if (this.state.callId !== 0) {
    let message = "";
      let tmpFlag = false;
      let tmpFlag2 = false;
      if(data.customer_status==="customer_connected" && data.final_status==="agent_connected" && data.agent_status==="agent_connected"){
        let memberName = (data.member===undefined)? "us":((data.member.first_name===undefined)?"us": data.member.first_name);
        message = "Your call is connected and you are speaking to agent "+memberName+".";  
        triggerPublicEvent('callStarted',null)
        var duringCall= false;
        if( (this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="during_call") || (this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="during_call")){
          duringCall = true;
          // console.log("ths call needs to be show during call screen");
        }
        if(this.props.enable_cf_on_during_digitalcall===true){
          duringCall = true;
        }
        this.setState({
            agent_name:memberName,
            thisCallStarted:true,
            callStatusMessage:message,
            duringCallScreen:duringCall
          });
          // console.log("this call is started state ");
          // console.log("during call screeen status is  "+duringCall);
          // console.log("Your call is connected and you are speaking to agent "+memberName+".");
        
      }else{
        callStatusMessages.forEach(function(status){
          data.agent_status =(data.agent_status===undefined)?"":data.agent_status;
          // console.log(data.call_status+'  vs '+status.customer_status+' customer status');
          // console.log(data.final_status+'  vs '+status.final_api_status+' final status');
          // console.log(data.agent_status+'  vs '+status.customer_service_status+' agent status');
          if(status.customer_status===data.customer_status && status.final_api_status===data.final_status && status.agent_status===data.agent_status){
            message = status.message;
            //  console.log("----status------"+status.message)
            tmpFlag = status.show_reconnect;
            tmpFlag2 = status.show_feedback;
          }
       });
      }
      if(tmpFlag){
        this.setState({
          show_reconnect: tmpFlag
        })
      }
      if(tmpFlag2){
        this.setState({
          showFeedbackForm: tmpFlag2,
          thisCallStarted:false
        });
      }
      // console.log('customer_status : '+data.customer_status +" | agent_status : "+ data.agent_status +" | final_status: "+data.final_status);
      if(data.customer_status==="customer_connected" || data.customer_status==="failed_to_connect_customer" ){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
      }
      if(data.customer_status==="customer_connected" && data.agent_status ==="failed_to_connect_agent") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_call_completed" && data.final_status ==="failed_to_connect_agent"){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_call_completed" && data.agent_status ==="failed_to_connect_agent"){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_connected" && data.agent_status ==="agent_connected"){
        this.props.widgetSettings.askFieldsThatNotEntered = true;
      }
      if((data.customer_status==="customer_call_completed" && data.agent_status ==="agent_call_connected" && data.final_status==="completed") || (data.customer_status==="customer_connected" && data.agent_status ==="agent_call_completed" && data.final_status==="completed")){
        this.setState({
          showFeedbackForm: true,
          currentCallComplete:false
        });
        triggerPublicEvent('callEnded',null)
      }
      if(data.customer_status==="customer_connected" && data.agent_status==="agent_connected" && data.final_status === "agent_connected"){
      this.setState({
        currentCallComplete: this.props.widgetSettings.tmpCallCompleteStatus,
        showFeedbackForm: tmpFlag2,
        statusCode: data.customer_status,
        callStatusMessage:message
      });
      }
    }
  }

  countdownTimer = () => {
    this.setState({
      countdownBegun: true,
      callStatusMessage:""
    });
  }
  allFields = () => {
    if(this.props.widgetSettings.full_name_required_before_call && !this.props.widgetSettings.name && (this.state.username===undefined || this.state.username==="")){
      this.setState({
        errors: true
      });
      return false;
    }

    if(this.props.widgetSettings.email_required_before_call && !this.props.widgetSettings.email && (this.props.widgetSettings.email===undefined || this.props.widgetSettings.email==="" || !EmailValidator.validate(this.props.widgetSettings.email)) && (this.state.email===undefined ||this.state.email==="" || !EmailValidator.validate(this.state.email))){
      this.setState({
        errors: true
      });
      return false;
    }
    return true;
  }

  handleTeamChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedTeamOption:selectedOption,
      selectedTeam:selectedOption.value,
      error:false,
      teamContainerClass : "tel-input position-relative form-group"
    })
  }

  customFieldValueChange = (event) => {
    let tmpA = event.target.name
    let tmpB = event.target.value
    this.setState({
        customValues: {...this.state.customValues, [tmpA]:tmpB},
        error: false
    })
  }

  performValidations = (team) =>{
    if(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_digital_calls_tab===1 ){
      team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
      if(this.props.widgetSettings.team_required_before_call===1){
       if(!team || team===""){
         // console.log("team")
         this.setState({
           error:true,
           teamContainerClass : "tel-input position-relative form-group teamErrorContainer"
         })
         return false;
       }
      }
   }
    
    if(this.state.email!==undefined && this.state.email!==''){
      if(!this.validateEmail()){
        this.setState({
          error_email:true,
          emailContainerClass : "email-container errorContainer"
        })
        return false;
      }
    }

    if(!this.validateBasicForm()){
      // console.log("basic form error");
      return false;
    }
    if(!this.checkCustomRequiredCheckup()){
      // console.log("custom form error");
      this.setState({
        error:true
      })
      return false;
    }
    return true;
  }

  handleCustomDropdownChange = (name,selectedOption) =>{
    this.setState({
      customValues: {...this.state.customValues, [name]:selectedOption.value},
      error: false
    })
  }
  
  validateEmail = () => {
    if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
      return false;
    }
    return true;
  }

  makeFormUnscrollable = () =>{
    this.setState({
      formHolderClass:'formHolder overflowed-y'
    })
  }

  makeFormScrollable = () =>{
    this.setState({
      formHolderClass:'formHolder'
    })
  }

  validateBasicForm = () => {
    if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="before_call"){
      if(!this.state.email || this.state.email===undefined || this.state.email===''){
            this.setState({
              error_email:true,
              emailContainerClass : "email-container errorContainer"
            })
            return false;
        }
    }
    if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="before_call"){
        if(!this.state.username){
            this.setState({
              error:true,
              nameContainerClass : "name-container errorContainer"
            })
            return false;
        }
    }
    if(this.props.widgetSettings.company_in_digital_calls_tab===1){
      if(!this.state.companyname){
          this.setState({
            error:true,
            companyNameContainerClass : "company-name-container errorContainer"
          })
          return false;
      }
    }
    return true;
  }

  checkCustomRequiredCheckup(){
    if(this.props.enable_cf_on_before_digitalcall){
      for(let tmpI = 0; tmpI<this.props.custom_fields_before_digitalcall_data.length; tmpI++){
        var tmpField = this.props.custom_fields_before_digitalcall_data[tmpI];
          if(tmpField['enabled']){
            if(tmpField['required']){
              if(tmpField['type']==="email-text-box"){
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                  return false;
                }
              }else{
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                  return false;
                }
              }
            }
        }
      }
    }
    return true;
  }

  makeCall = () => {
    this.setState({
      showBackButton:false,
      showFeedbackForm:false,
      show_reconnect:false
    })
    this.props.pushButtonEventToGA();
    var team = null;
    team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
    if((!this.state.email && this.props.widgetSettings.email) || (!this.state.username && this.props.widgetSettings.name)){
      var temp_email = '';
      var temp_name = '';
      var temp_company_name = '';
      if(!this.state.email && this.props.widgetSettings.email){
        temp_email = this.props.widgetSettings.email;
      }else{
        temp_email = this.state.email;
      }
      if(!this.state.username && this.props.widgetSettings.name){
        temp_name = this.props.widgetSettings.name;
      }else{
        temp_name = this.state.username;
      }
      if(!this.state.companyname && this.props.widgetSettings.companyname){
        temp_company_name = this.props.widgetSettings.companyname;
      }else{
        temp_company_name = this.state.companyname;
      }
      this.setState({
        email:temp_email,
        username:temp_name,
        companyname:temp_company_name
      },()=> {
         if(!this.performValidations(team)){
            return;
          }
        })

    }else{
      if(!this.performValidations(team)){
        return;
      }
    }
    
    const isNormalFormValid = 1 || ( this.props.widgetSettings.full_name_required_before_call && this.props.widgetSettings.name) || ( this.props.widgetSettings.email_required_before_call && this.props.widgetSettings.email);
    var randomId = Math.random().toString(36).substring(3)+Math.floor(Date.now() / 1000);
    this.setState({
      callId: randomId,
      currentCallType: "digital_call"
    });
    // console.log(randomId);
    var apikey = null;
    var agent_id = null;
    if(this.props.widgetSettings.widget_type===2){
      apikey = this.props.widgetSettings.pId;
      agent_id = getAPIkey();
    }else{
      apikey = getAPIkey();
    }
    
    const params = {
      pId:apikey,
      name: !(this.state.username)?this.props.widgetSettings.name:this.state.username,
      source: this.props.widgetSettings.source,
      ip:this.props.widgetSettings.ip_address,
      randomId: randomId,
      team:team,
      agent_id:agent_id,
      email: !(this.state.email)?this.props.widgetSettings.email:this.state.email,
      company_name: !(this.state.companyname)?this.props.widgetSettings.companyname:this.state.companyname
    };

    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        const params ={
          type: 'custom_field',
          pId:apikey,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    this.props.widgetSettings.askFieldsThatNotEntered = false;
    
    if (isNormalFormValid ) {
      this.countdownTimer();

        // this.setState({
        //   callStatusMessage: this.props.widgetSettings.language_data['during-call-now'],
        // })

        this.setState({
          showLoader:true,
          callStatusMessage:'Connecting...'
        })
      
      // console.log("trigger call");
      if (this.device) {
        var outgoingConnection = this.device.connect(params);
        // this.pusher.logToConsole = true;
        // console.log("pusher-channel id is ");
        // console.log(`react-channel-random-${randomId}`);
        this.channel = this.pusher.subscribe(`react-channel-random-${randomId}`);
        this.pusher.bind('change-call-status', this.updateEvents);
        outgoingConnection.on('ringing', function() {
          // console.log('Ringing...');
        });
      }
      if(this.props.enable_cf_on_before_digitalcall){
        const beforeCallCustomData = {
          random_id:randomId,
          ...this.state.customValues
        };
        sendCustomFieldData(beforeCallCustomData).then(() => {
        }).catch((error) => {
          console.log(error);
        });
      }
      
      if(this.props.widgetSettings.utm_data){
        const customerUTMdata = {
          lead_id:randomId,
          customer_utm:this.props.widgetSettings.utm_data
        };
        sendUTMdata(customerUTMdata).then(() => {
        }).catch((error) => {
          console.log(error);
        });
      }

    // --------
    } else {
      this.setState({
        errors: true
      });
    }
  }
  
  
  isEmptySettings(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  countryFlagChangeListenter(a){
  //  console.log(a);
  }
  callForm = () => {
    if(this.isEmptySettings(this.props.widgetSettings)){
      return;
    }
    let teamsData =  this.props.widgetSettings.teams;
    let teamOptions = [];
    if(teamsData.length>0){
      for(let i = 0; i<teamsData.length; i++){
        teamOptions.push({value:teamsData[i].id,label:teamsData[i].name});
      }
    }
    // const errorStyle = { border: '1px solid red', borderRadius: "5px" };
    return (
      <React.Fragment>
        <div className="tel-input form__form-group" >
          <div className="form__form-group-field">
            
            {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_digital_calls_tab===1 && this.props.widgetSettings.email_display_on_digital_call==="before_call") ? [
              (this.props.widgetSettings.email_required_before_call===1) ? (
              <div id="emailContainer2" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">email</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail2" />
              </div>
              ) : (
              <div id="emailContainer2" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">email</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email"  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail2" />
              </div>
              )
             ] : null}

            {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_digital_calls_tab===1 && this.props.widgetSettings.full_name_display_on_digital_call==="before_call") ? [
              (this.props.widgetSettings.full_name_required_before_call===1) ? (
              <div id="floatContainer3" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">account_circle</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName2" />
              </div>
              ): (
              <div id="floatContainer3" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">account_circle</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text"  defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName2" />
              </div>
              )
             ] : null}

            {(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_digital_calls_tab===1 )?(
                <div className={this.state.teamContainerClass} style={{fontFamily:this.props.widgetSettings.widget_font}}>
                  <Select
                    className="team-dropdown-select-mod"
                    value={this.state.selectedTeamOption}
                    placeholder={(this.props.widgetSettings.team_required_before_call===1) ? "I want to talk to: *":"I want to talk to:"}
                    options={teamOptions}
                    inputId="teamSelectorDform"
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    isSearchable={ false }
                    onMenuOpen={this.makeFormUnscrollable}
                    onMenuClose={this.makeFormScrollable}
                    onChange={this.handleTeamChange}
                    classNamePrefix="team-dropdown-select-mod"
                  />
                </div>
              ) : null}
              
            {(this.props.widgetSettings.company_in_digital_calls_tab===1 ) ? 
              (<div id="floatContainer3_1" className={this.state.companyNameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.companyname} onChange={event => this.onValueChange(event)} name="companyname" placeholder="Company name *" id="limeCompanyName2" />
              </div>
              ): null }

            {(this.props.enable_cf_on_before_digitalcall===true)?(
              <CustomFields
                widgetSettings={this.props.widgetSettings}
                tab={this.props.currentTab}
                currentTab = {this.props.currentTab}
                customFieldsData = {this.props.custom_fields_before_digitalcall_data}
                customFieldValueChange = {this.customFieldValueChange.bind(this)}
                makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                makeFormScrollable = {this.makeFormScrollable.bind(this)}
                handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                customValues = {this.state.customValues}
                currentScreen="before_call_digital"
              />
            ): null}
          </div>
          {this.state.error ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill required data for us to call you!</div> : null}
          {this.state.error_email ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
        </div>
        <div className="button-group">
          <button onClick={this.makeCall} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, fontFamily:this.props.widgetSettings.widget_font, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
            <span className="button-text" >{this.props.widgetSettings.language_data["digital_call_button"] ?this.props.widgetSettings.language_data["digital_call_button"]:"Start Call"  }</span>
          </button>
        </div>

      </React.Fragment>
    );
  }
  getDynamicJsAndCss = () => {
    if(window.dynamicJsAndCss){
        return;
    }
    window.dynamicJsAndCss = true;
    eval(this.props.widgetSettings.custom_js);
    var styles=".lime-call-popup .nav .nav-item .active{border-bottom: 3px solid "+this.props.widgetSettings.color+";}"+this.props.widgetSettings.custom_css;
    var css = document.createElement('style'); 
    css.type = 'text/css';     
    css.appendChild(document.createTextNode(styles));      
    document.getElementsByTagName("head")[0].appendChild(css); 
  }
  render() {
    return (
      <React.Fragment>
           <script dangerouslySetInnerHTML={this.getDynamicJsAndCss()} type="text/javascript"></script>
         {!this.state.showFeedbackForm && !this.state.countdownBegun ? (
          <div className="call_now__top" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            
            { (this.props.widgetSettings.company_logo!=="default")? (
              <div style={{alignItems:"center"}}>
                <img src={this.props.widgetSettings.company_logo} className="company_logo_header" />
              </div>) : null
            }
            <div className="limecall-title" style={{paddingBottom:"10px", fontFamily:this.props.widgetSettings.widget_font }}>{(this.state.scheduleCallFormSecond)?"Let us know more about you":this.props.title}</div>
          </div>
        ) : null}
        <div className={this.state.formHolderClass}>
          {/* {console.log(this.state)} */}
          {this.state.countdownBegun ? (
            
            <CountdownTimer
              showLoader = {this.state.showLoader}
              resetCallState = {this.resetCallState.bind(this)}
              disconnectCall = {this.disconnectCall.bind(this)}
              setStateMessageBinder = {this.setStateMessageBinder.bind(this)}
              scheduleCall={this.state.scheduleCall}
              showFeedbackForm={this.state.showFeedbackForm}
              showBackButton={this.state.showBackButton}
              scheduleCallFormSecond={this.state.scheduleCallFormSecond}
              currentCallComplete={this.state.currentCallComplete}
              countdownBegun = {this.state.countdownBegun}
              toggle = {this.props.toggle.bind(this)}
              pushButtonEventToGA = {this.props.pushButtonEventToGA.bind(this)}
              statusCode={this.state.statusCode}
              callStatusMessage={this.state.callStatusMessage}
              enable_cf_on_after_digitalcall={this.props.enable_cf_on_after_digitalcall}
              custom_fields_after_digitalcall_data={this.props.custom_fields_after_digitalcall_data}
              custom_fields_during_digitalcall_data={this.props.custom_fields_during_digitalcall_data}
              enable_cf_on_during_digitalcall={this.props.enable_cf_on_during_digitalcall}
              currentCallType= {this.state.currentCallType}
              show_reconnect={this.state.show_reconnect}
              thisCallStarted= {this.state.thisCallStarted}
              duringCallScreen= {this.state.duringCallScreen}
              callId={this.state.callId}
              rescheduleCall={this.resetForm}
              onFeedbackDone={this.resetForm}
              showDisconnect={this.state.showDisconnect}
              hideLoadingArea={this.state.hideLoadingArea}
              widgetSettings={this.props.widgetSettings}
            />
          ) : this.callForm()}
          {/* {this.renderTermsAndConditionsLink()} */}
        </div>
        {processFloatLabels()}
      </React.Fragment>
    );
  }
}


export default DigitalCallForm;
