import React from 'react';

import * as EmailValidator from 'email-validator';
import IntlTelInput from 'react-intl-tel-input';
import moment from "moment";
import { default as SelectTimezone} from "react-timezone-select";
import Select from "react-select";
import Pusher from 'pusher-js';
import { isEmpty } from "lodash";
import {
  widgetFormInitialState,
  callStatusMessages,
  validateForms,
  triggerPublicEvent,
  processFloatLabels,
  pushTrackingDataForLead
} from "../HelperMethods";
import CountdownTimer from "./CountdownTimer";
import {
  requestCall,
  sendCustomFieldData,
  loadTimezonebasedSchedule,
  sendAnalyticsEvent,
  getAPIkey
} from "../WidgetAPICalls";

import CustomFields from './CustomFields';



const scheduleFormFields = ["phone", "scheduled_at", "type"];

class WidgetCallForm extends React.Component {
  constructor(props) {
    document.phoneNumberIsValid = false;
    super(props);
    this.state = {
      ...widgetFormInitialState,
      scheduleTime: props.scheduleTime,
      scheduleDate: props.scheduleDate,
      selectedOption: {value:this.props.widgetSettings.visitor_time_zone,label:this.props.widgetSettings.visitor_time_zone},
      available_time: this.props.widgetSettings.available_time,
      email: this.props.widgetSettings.email,
      username: this.props.widgetSettings.name,
      formHolderClass: "formHolder",
      intlCustomCLass : this.props.intlCustomCLass,
      emailContainerClass: "email-container",
      nameContainerClass: "name-container",
      companyNameContainerClass: "company-name-container",
      teamContainerClass : "tel-input position-relative form-group"
    };
    this.fontStyle =  {
      fontFamily: this.props.widgetSettings.widget_font
    }
  }

  fontStyle =  {}

  componentWillMount() {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'eu',
      forceTLS: true,
      disableStats: true
    });
    this.pusher.connection.bind( 'error', function( err ) {
      if( err.error.data.code === 4004 ) {
        console.log('Over limit!');
      }
    });
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleCall !== this.props.scheduleCall
      || this.props.activeTab !== nextProps.activeTab) {
      this.setState({
        ...widgetFormInitialState,
        scheduleTime: nextProps.scheduleTime,
        scheduleDate: nextProps.scheduleDate
      });
    }
  }

  resetForm = () => {
    this.setState(prevState => ({
      ...widgetFormInitialState,
      scheduleTime: prevState.scheduleTime,
      scheduleDate: prevState.scheduleDate
    }));
  }

  resetCallState(){
    this.resetScheduleForm();
    this.setState({
      scheduleCallFormSecond: false,
      countdownBegun:false,
      currentCallComplete:false,
      callStatusMessage:"",
      thisCallStarted:false,
      showBackButton:false,
      show_reconnect:false
    })
  }
  setStateMessageBinder(){
    this.setState({
      callStatusMessage:"Your call is connected and you are speaking to agent "+this.state.agent_name+"."
    })
  }


  onValueChange = (event) => {
    if(event.target.name==="email"){
      this.setState({
        error_email: false,
        emailContainerClass: "email-container"
      })
      this.props.widgetSettings.email = event.target.value;
      if(event.target.value===null || event.target.value===''){
        this.props.widgetSettings.email = null;
      }
    }else if(event.target.name==="username"){
      this.setState({
        nameContainerClass:"name-container"
      })
      this.props.widgetSettings.name = event.target.value;
    }else if(event.target.name==="team"){
      this.props.widgetSettings.team = event.target.value;
    }else if(event.target.name==="companyname"){
      this.setState({
        companyNameContainerClass: "company-name-container"
      })
      this.props.widgetSettings.companyname = event.target.value;
    }
    
      this.setState({
        [event.target.name]: event.target.value,
        error: false
      })
      if(event.target.value==='' || event.target.value===null){
        this.setState({
          [event.target.value]: null
        })
      }
    
  }

  onChangeOptions = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  updateEvents = (data) => {
    // console.log(data);
    if (this.state.callId !== 0 ) {
    let message = "";
      let tmpFlag = false;
      let tmpFlag2 = false;
      var duringCall = false;
      if(data.customer_status==="customer_connected" && data.final_status==="agent_connected" && data.agent_status==="agent_connected"){
        let memberName = (data.member===undefined)? "us":((data.member.first_name===undefined)?"us": data.member.first_name);
        message = "Your call is connected and you are speaking to agent "+memberName+".";  
        triggerPublicEvent('callStarted',null)
        if( (this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="during_call") || (this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="during_call")){
          duringCall = true;
        }
        if(this.props.enable_cf_on_during_call===true){
          duringCall = true;
        }
        this.setState({
            agent_name:memberName,
            thisCallStarted:true,
            callStatusMessage:message,
            duringCallScreen:duringCall
          });
          // console.log("Your call is connected and you are speaking to agent "+memberName+".");
        
      }else{
        callStatusMessages.forEach(function(status){
          data.agent_status =(data.agent_status===undefined)?"":data.agent_status;
          // console.log(data.call_status+'  vs '+status.customer_status+' customer status');
          // console.log(data.final_status+'  vs '+status.final_api_status+' final status');
          // console.log(data.agent_status+'  vs '+status.customer_service_status+' agent status');
          if(status.customer_status===data.customer_status && status.final_api_status===data.final_status && status.agent_status===data.agent_status){
            message = status.message;
            //  console.log("----status-----"+status.message)
            tmpFlag = status.show_reconnect;
            tmpFlag2 = status.show_feedback;
          }
       });
      }
      if(tmpFlag){
        this.setState({
          show_reconnect: tmpFlag
        })
      }
      if(tmpFlag2){
        this.setState({
          showFeedbackForm: tmpFlag2
        });
      }
      // console.log('customer_status : '+data.customer_status +" | customer_service-status : "+ data.agent_status +" | final_status: "+data.final_status + " | message : "+message);
      if(data.customer_status==="failed_to_connect_customer" && data.final_status ==="failed_to_connect_customer") {
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_connected" && data.agent_status ==="failed_to_connect_agent") {
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_call_completed" && data.final_status ==="failed_to_connect_agent"){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_call_completed" && data.agent_status ==="failed_to_connect_agent"){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
        triggerPublicEvent('callFailed',null)
      }
      if(data.customer_status==="customer_call_completed" && data.final_status ==="failed_to_connect_agent"){
        this.props.widgetSettings.tmpCallCompleteStatus = true;
      }
      if(data.customer_status==="customer_connected" && data.agent_status ==="agent_connected"){
        this.props.widgetSettings.askFieldsThatNotEntered = true;
      }
      if((data.customer_status==="customer_call_completed" && data.agent_status ==="agent_call_connected" && data.final_status==="completed") || (data.customer_status==="customer_connected" && data.agent_status ==="agent_call_completed" && data.final_status==="completed")){
        this.setState({
          showFeedbackForm: true,
          currentCallComplete:false
        });
        triggerPublicEvent('callEnded',null)
      }
      // if(data.customer_status==="customer_connected" && data.agent_status==="agent_connected" && data.final_status === "agent_connected"){
      this.setState({
        currentCallComplete: this.props.widgetSettings.tmpCallCompleteStatus,
        showFeedbackForm: tmpFlag2,
        statusCode: data.customer_status,
        callStatusMessage:message
      });
      // }
    }
  }

  countdownTimer = () => {
    this.setState({
      countdownBegun: true,
      callStatusMessage:""
    });
  }
  allFields = () => {
    if(this.props.widgetSettings.full_name_required_before_call && !this.props.widgetSettings.name && (this.state.username===undefined || this.state.username==="")){
      this.setState({
        errors: true
      });
      return false;
    }

    if(this.props.widgetSettings.email_required_before_call && !this.props.widgetSettings.email && (this.props.widgetSettings.email===undefined || this.props.widgetSettings.email==="" || !EmailValidator.validate(this.props.widgetSettings.email)) && (this.state.email===undefined ||this.state.email==="" || !EmailValidator.validate(this.state.email))){
      this.setState({
        errors: true
      });
      return false;
    }
    return true;
  }

  customFieldValueChange = (event) => {
    let tmpA = event.target.name
    let tmpB = event.target.value
    this.setState({
        customValues: {...this.state.customValues, [tmpA]:tmpB},
        error: false
    })
  }

  handleDateChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedDate:selectedOption,
      scheduleDate:selectedOption.value
    })
  }

  handleTeamChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedTeamOption:selectedOption,
      selectedTeam:selectedOption.value,
      error:false,
      teamContainerClass : "tel-input position-relative form-group"
    })
  }

  handleCustomDropdownChange = (name,selectedOption) =>{
    this.setState({
      customValues: {...this.state.customValues, [name]:selectedOption.value},
      error: false
    })
  }

  handleTimeChange = selectedOption =>{
    // console.log(selectedOption)
    this.setState({
      selectedTime:selectedOption,
      scheduleTime:selectedOption.value
    })
  }

  makeFormUnscrollable = () =>{
    this.setState({
      formHolderClass:'formHolder overflowed-y'
    })
  }

  makeFormScrollable = () =>{
    this.setState({
      formHolderClass:'formHolder'
    })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption: selectedOption })
    let gmtSelection = selectedOption.label.substring(1,10)
    // console.log(gmtSelection);
    // console.log(selectedOption.value)
    
    loadTimezonebasedSchedule(gmtSelection).then((response) =>{
      // console.log(response)
      this.setState({
        available_time:response
      })
    })
  }

  timezoneSelector = () => {
    const {
      selectedOption
    } = this.state
    if(this.props.scheduleCall && this.props.widgetSettings.visitor_time_zone_status){
      return(
          <div className="select-wrapper" style={{paddingBottom:'0.4rem'}}>
            <SelectTimezone 
              value={selectedOption} 
              onChange={this.handleChange}
            />
          </div>
      )
    }
  }

  performValidations = (team) =>{

    if(this.props.currentTab==="call_now_tab"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="on_call_now_screen" && this.state.email!==undefined && this.state.email!==''){
        if(!this.validateEmail()){
          this.setState({
            error_email:true,
            emailContainerClass:"email-container errorContainer"
          })
          return false;
        }
      }
      if(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_call_now_tab===1 ){
        team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
        if(this.props.widgetSettings.team_required_before_call){
         if(!team || team===""){
           this.setState({
             error:true,
             teamContainerClass : "tel-input position-relative form-group teamErrorContainer"
           })
           return false;
         }
        }
      }
    }
    
    if(this.props.currentTab==="call_later_tab"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="before_schedule"){
        if(!this.validateEmail() || this.state.email===undefined || this.state.email===''){
          this.setState({
            error_email:true,
            emailContainerClass :"email-container errorContainer"
          })
          return false;
        }
      }
      if(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_call_later_tab===1 ){
        team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
        if(this.props.widgetSettings.team_required_before_call){
         if(!team || team===""){
           this.setState({
             error:true,
             teamContainerClass : "tel-input position-relative form-group teamErrorContainer"
           })
           return false;
         }
        }
      }
    }
    
    var phoneNumber =this.props.finalNumber;
    if(!phoneNumber || phoneNumber===undefined || phoneNumber===this.props.countryDialCode){
      // console.log("empty phone number")
      this.setState({
        error:true,
        intlCustomCLass:"intlErrorContainer"
      })
      return false;
    }

    this.setState({
      currentCallType: "normal_call"
    })
    if(!this.validateBasicForm()){
      // console.log('basic form error');
      
      return false;
    }
    if(!this.checkCustomRequiredCheckup()){
      // console.log('custom form error');
      this.setState({
        error:true
      })
      return false;
    }
    return true;
  }

  validateEmail = () => {
    if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
      return false;
    }
    return true;
  }

  validateBasicForm = () => {
    if(this.props.currentTab==="call_now_tab"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="on_call_now_screen"){
          if(!this.state.email || this.state.email===undefined || this.state.email===''){
            // console.log("email issue call now tab")
              this.setState({
                error_email: true,
                emailContainerClass: "email-container errorContainer"
              })
              return false;
          }
      }
      if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="on_call_now_screen"){
          if(!this.state.username){
            // console.log("usernmae issue call now tab")
              this.setState({
                error:true,
                nameContainerClass : "name-container errorContainer"
              })
              return false;
          }
      }
      if(this.props.widgetSettings.company_in_call_now_tab===1){
        if(!this.state.companyname){
            this.setState({
              error:true,
              companyNameContainerClass : "company-name-container errorContainer"
            })
            return false;
        }
      }
    }else if(this.props.currentTab==="call_later_tab"){
      if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="before_schedule"){
        if(!this.state.email && this.state.email!==undefined && this.state.email!==''){
          // console.log("email issue call later tab")
            this.setState({
              error_email: true,
              emailContainerClass: "email-container errorContainer"
            })
            return false;
        }
    }
    if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="before_schedule"){
        if(!this.state.username){
          // console.log("usernaem issue call later tab")
            this.setState({
              error:true,
              nameContainerClass : "name-container errorContainer"
            })
            return false;
        }
      }
      if(this.props.widgetSettings.company_in_call_later_tab===1){
        if(!this.state.companyname){
            this.setState({
              error:true,
              companyNameContainerClass : "company-name-container errorContainer"
            })
            return false;
        }
      }
    }
    return true;
  }

  phoneNumberValidation = () => {
    if(!this.props.scheduleCall && (this.props.phoneNumber && (this.props.phoneNumberIsValid || this.props.finalNumber) )){
      return true;
    }else{
      return false;
    }
  } 
  checkCustomRequiredCheckup(){
    var tmpField;
    if(this.props.scheduleCall && this.props.enable_cf_on_before_schedule ){
      for(let tmpI = 0; tmpI<this.props.custom_fields_before_schedule_data.length; tmpI++){
         tmpField = this.props.custom_fields_before_schedule_data[tmpI];
          if(tmpField['enabled']){
            if(tmpField['required']){
              if(tmpField['type']==="email-text-box"){
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                  return false;
                }
              }else{
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                  return false;
                }
              }
            }
        }
      }
    }
    if(!this.props.scheduleCall && this.props.enable_cf_on_before_call){
      for(let tmpI = 0; tmpI<this.props.custom_fields_before_call_data.length; tmpI++){
         tmpField = this.props.custom_fields_before_call_data[tmpI];
          if(tmpField['enabled']){
            if(tmpField['required']){
              if(tmpField['type']==="email-text-box"){
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                  return false;
                }
              }else{
                if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                  return false;
                }
              }
            }
        }
      }
    }
    return true;
  }

  makeCall = () => {
    this.props.pushButtonEventToGA();
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const scheduleCallData = this.props.scheduleCall ? { type: "SCHEDULE_CALL", scheduled_at: `${this.state.scheduleDate} ${this.state.scheduleTime}`, time_zone: this.state.selectedOption.value } : {};
    var team = null;
    team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
    if((!this.state.email && this.props.widgetSettings.email) || (!this.state.username && this.props.widgetSettings.name)){
      var temp_email = '';
      var temp_name = '';
      var temp_company_name = '';
      if(!this.state.email && this.props.widgetSettings.email){
        temp_email = this.props.widgetSettings.email;
      }else{
        temp_email = this.state.email;
      }
      if(!this.state.username && this.props.widgetSettings.name){
        temp_name = this.props.widgetSettings.name;
      }else{
        temp_name = this.state.username;
      }
      if(!this.state.companyname && this.props.widgetSettings.companyname){
        temp_company_name = this.props.widgetSettings.companyname;
      }else{
        temp_company_name = this.state.companyname;
      }
      this.setState({
        email:temp_email,
        username:temp_name,
        companyname:temp_company_name
      },()=> {
         if(!this.performValidations(team)){
            return;
          }
        })

    }else{
      if(!this.performValidations(team)){
        return;
      }
    }

    var username = !(this.state.username)?this.props.widgetSettings.name:this.state.username;
    if(username=== undefined){
      username = null;
    }
    var useremail = !(this.state.email)?this.props.widgetSettings.email:this.state.email;
    if(useremail=== undefined){
        useremail = null;
    }
    var apikey = null;
    var agent_id = null;
    if(this.props.widgetSettings.widget_type===2){
      apikey = this.props.widgetSettings.pId;
      agent_id = getAPIkey();
    }else{
      apikey = getAPIkey();
    }

    const params = {
      phone: this.props.finalNumber,
      pId: apikey,
      agent_id:agent_id,
      name: username,
      host_name: window.location.host,
      country_code: this.props.countryDialCode,
      email: useremail,
      company_name: !(this.state.companyname)?this.props.widgetSettings.companyname:this.state.companyname,
      team: team,
      customer_utm:this.props.widgetSettings.utm_data,
      source: this.props.widgetSettings.source,
      ...scheduleCallData
    };

    if(this.state.customValues!==undefined){
      if(this.props.widgetSettings.google_analytics===1){
        const params ={
          type: 'custom_field',
          pId:apikey,
          userIp: this.props.widgetSettings.ip_address
        };
        sendAnalyticsEvent(params);
      }
    }

    this.props.widgetSettings.askFieldsThatNotEntered = false;
    const isScheduleFormValid = validateForms(scheduleFormFields, params);
    const isNormalFormValid = this.phoneNumberValidation();
    if (isNormalFormValid || isScheduleFormValid ) {
      this.countdownTimer();
      if(!this.props.scheduleCall){
        triggerPublicEvent('callInitiation',null)
        this.setState({
          callStatusMessage: this.props.widgetSettings.language_data['during-call-now'],
        })
      }else{
        this.setState({
          showLoader:true
        })
      }
      
      requestCall(params).then((response) => {
        this.setState({
          showLoader:false
        })// eslint-disable-next-line
        if (isNormalFormValid && !isNaN(response) && !this.state.scheduleCallFormSecond) {
          this.channel = this.pusher.subscribe(`call-channel-${response}`);
          this.pusher.bind('change-call-status', this.updateEvents);
          this.setState({
            callId: response
          });
          if(this.props.enable_cf_on_before_call){
            const beforeCallCustomData = {
              call_id:response,
              ...this.state.customValues
            };
            sendCustomFieldData(beforeCallCustomData).then(() => {
            }).catch((error) => {
              console.log(error);
            });
          }
          pushTrackingDataForLead(response,this.props.widgetSettings);
        // eslint-disable-next-line
        } else if (!isNaN(response)) {
          if(this.props.enable_cf_on_before_schedule){
            const beforeScheduleCustomData = {
              call_id:response,
              ...this.state.customValues
            };
            sendCustomFieldData(beforeScheduleCustomData).then(() => {
            }).catch((error) => {
              console.log(error);
            });
          }
          pushTrackingDataForLead(response,this.props.widgetSettings);
          var afterschedule = false;
          const scheduledTime = moment(new Date(params.scheduled_at), 'YYYY-MM-DD HH:mm:ss').format('LLLL');
          if( (this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="after_schedule") || (this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="after_schedule")){
            afterschedule = true;
          }
          if(this.props.enable_cf_on_after_schedule===true){
            afterschedule = true;
          }

          this.setState({
            currentCallComplete: true,
            showFeedbackForm: false,
            hideTimer: true,
            callId: response,
            callStatusMessage: `Hola! Your call is scheduled for ${scheduledTime}`,
            //TODO: remove code 
            // showFeedbackForm: true,
            afterScheduleScreen: afterschedule
          });
          
        } else {
          this.setState({
            callStatusMessage: response,
            currentCallComplete: true,
            showBackButton:true
          });
        }
      });
    } else {
      // console.log('call form error');
      this.setState({
        errors: true
      });
    }
  }
     

  scheduleCallOptions = (timeSlots, error, errorStyle,optionsDate,optionsTime) => ((this.props.scheduleCall && !this.state.scheduleCallFormSecond) ? (
    <div className="call_later__wrapper">
      {!isEmpty(timeSlots) && Object.keys(timeSlots).map((dateVal) => {
          const lastTimeSlot = timeSlots[dateVal][timeSlots[dateVal].length - 1];
          let showDate;
          if(this.props.widgetSettings.date_format==="mm/dd/yyyy"){
            showDate = moment(dateVal).format("MM/DD/YYYY");
          }else if(this.props.widgetSettings.date_format==="dd/mm/yyyy"){
            showDate = moment(dateVal).format("DD/MM/YYYY");
          }
          if(moment(new Date(`${dateVal}`)).isSame(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "day")){
            showDate= "Today";
          }
          if(!moment(new Date(`${dateVal} ${lastTimeSlot}`)).isBefore(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "minute")){
            optionsDate.push({value:dateVal,label:showDate});
            if(!this.state.selectedDate){
              this.setState({
                selectedDate:optionsDate[0],
                scheduleDate:optionsDate[0].value
              })
            }
          } 
        })}
      <Select
        className="schedule-call-select-mod scheduledate-selector"
        value={this.state.selectedDate}
        options={optionsDate}
        inputId="scheduledate"
        components={{
          IndicatorSeparator: () => null
        }}
        isSearchable={ false }
        onChange={this.handleDateChange}
        onMenuOpen={this.makeFormUnscrollable}
        onMenuClose={this.makeFormScrollable}
        classNamePrefix="schedule-call-select-mod"
      />

      {timeSlots[this.state.scheduleDate] && timeSlots[this.state.scheduleDate].map(timeVal => {
          let showTime;
          if(this.props.widgetSettings.time_format==="12"){
            showTime = moment(timeVal,'kk:mm:ss').format("hh:mm:ss a");
          }else{
            showTime = timeVal;
          }
          // console.log(showTime)
         if(!moment(new Date(`${this.state.scheduleDate} ${timeVal}`)).isBefore(new Date().toLocaleString("en-US", {timeZone: this.state.selectedOption.value}), "minute")){
          optionsTime.push({value:timeVal,label:showTime});
          if(!this.state.selectedTime){
            this.setState({
              selectedTime:optionsTime[0],
              scheduleTime:optionsTime[0].value
            })
          }
         }
        })}

      <Select
        className="schedule-call-select-mod"
        value={this.state.selectedTime}
        options={optionsTime}
        inputId="scheduleTime"
        isSearchable={ false }
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={this.handleTimeChange}
        onMenuOpen={this.makeFormUnscrollable}
        onMenuClose={this.makeFormScrollable}
        classNamePrefix="schedule-call-select-mod"
      />

    </div>
  ) : null);

  resetScheduleForm = () => {
    if(this.props.scheduleCall && this.state.scheduleCallFormSecond){
      this.setState({
        scheduleCallFormSecond: false
      });
    }
  }
  isEmptySettings(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  countryFlagChangeListenter(a){
  //  console.log(a);
  }
  callForm = () => {
    if(this.isEmptySettings(this.props.widgetSettings)){
      return;
    }
    const timeSlots = this.state.available_time;
    const phoneNumberIsNotValidError = this.state.errors && !document.phoneNumberIsValidd;
    const errorStyle = { border: '1px solid red', borderRadius: "5px" };
    let teamsData;
    let intlId;
    if(this.props.scheduleCall){
      teamsData =  this.props.widgetSettings.widget_teams;
      intlId = "scheduleNoField"
    }else{
      teamsData =  this.props.widgetSettings.teams;
      intlId = "callNoField"
    }
    let teamOptions = [];
    if(teamsData.length>0){
      for(let i = 0; i<teamsData.length; i++){
        teamOptions.push({value:teamsData[i].id,label:teamsData[i].name});
      }
    }
    let optionsDate = [];
    let optionsTime = [];
    // console.log(tmpTeam);
    return (
      <React.Fragment>
        {this.timezoneSelector()}
        {this.scheduleCallOptions(timeSlots, phoneNumberIsNotValidError, errorStyle,optionsDate,optionsTime)}
        <div className="tel-input form__form-group" >
          <div className="form__form-group-field">
            {(!this.props.scheduleCall || (!this.state.scheduleCallFormSecond))?(
              <div className={this.props.intlCustomCLass}>
                <IntlTelInput
                  name="tel-no"
                  fieldName="tel-no"
                  format= ""
                  fieldId={intlId}
                  value={this.props.phoneNumber}
                  useMobileFullscreenDropdown={false}
                  disabled={this.props.widgetSettings.allowedCountries.length<1}
                  onlyCountries={this.props.widgetSettings.allowedCountries}
                  separateDialCode={true}
                  customPlaceholder= {function(selectedCountryPlaceholder, selectedCountryData) {
                    return "Enter your number";
                  }}
                  onSelectFlag={this.props.onSelectFlagHandler}
                  onPhoneNumberChange={this.props.phoneNumberChangeHandler}
                  style={{fontFamily:this.props.widgetSettings.widget_font, fontSize:"13px", marginBottom:'0.5rem'}}
                  defaultCountry={this.props.countryCode}
                />
              </div>
            ):null}
            
            {(this.props.scheduleCall ?(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_later_tab===1 && this.props.widgetSettings.email_display_on==="before_schedule"):(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_call_now_tab===1 && this.props.widgetSettings.email_display_on_call_now==="on_call_now_screen") )? [
              (this.props.widgetSettings.email_required_before_call===1) ? (
              <div id="floatContainer4" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">email</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail3" />
              </div>
              ) : (
              <div id="floatContainer4" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">email</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail3" />
              </div>
              )
             ] : null}

            {(this.props.scheduleCall ?(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_later_tab===1 && this.props.widgetSettings.full_name_display_on_call_later==="before_schedule"):(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_call_now_tab===1 && this.props.widgetSettings.full_name_display_on_call_now==="on_call_now_screen") )? [
              (this.props.widgetSettings.full_name_required_before_call===1) ? (
              <div id="floatContainer5" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">account_circle</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName3" />
              </div>
              ): (
              <div id="floatContainer5" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <i className="material-icons custom-icon-input">account_circle</i>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName3" />
              </div>
              )
             ] : null}
            {(this.props.scheduleCall ?(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_call_later_tab===1):(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_call_now_tab===1) )? (
              
              <div className={this.state.teamContainerClass} style={{fontFamily:this.props.widgetSettings.widget_font}}>
                <Select
                  className="team-dropdown-select-mod"
                  value={this.state.selectedTeamOption}
                  placeholder={(this.props.widgetSettings.team_required_before_call===1) ? "I want to talk to: *":"I want to talk to:"}
                  options={teamOptions}
                  inputId="teamSelector"
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  isSearchable={ false }
                  onMenuOpen={this.makeFormUnscrollable}
                  onMenuClose={this.makeFormScrollable}
                  onChange={this.handleTeamChange}
                  classNamePrefix="team-dropdown-select-mod"
                />
              </div>
               ) : null}
            
            {(this.props.widgetSettings.company_in_call_now_tab===1 || this.props.widgetSettings.company_in_call_later_tab===1) ? 
              (<div id="floatContainer5_1" className={this.state.companyNameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.companyname} onChange={event => this.onValueChange(event)} name="companyname" placeholder="Company name *" id="limeCompanyName3" />
              </div>
              ): null }

            {(this.props.scheduleCall && this.props.enable_cf_on_before_schedule)?(
              <CustomFields
                widgetSettings={this.props.widgetSettings}
                tab={this.props.currentTab}
                currentTab = {this.props.currentTab}
                customFieldsData = {this.props.custom_fields_before_schedule_data}
                customFieldValueChange = {this.customFieldValueChange.bind(this)}
                makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                makeFormScrollable = {this.makeFormScrollable.bind(this)}
                handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                customValues = {this.state.customValues}
                currentScreen="before_schedule"
              />
            ):null}
            {(!this.props.scheduleCall && this.props.enable_cf_on_before_call)?(
              <CustomFields
                widgetSettings={this.props.widgetSettings}
                tab={this.props.currentTab}
                currentTab = {this.props.currentTab}
                customFieldsData = {this.props.custom_fields_before_call_data}
                customFieldValueChange = {this.customFieldValueChange.bind(this)}
                makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                makeFormScrollable = {this.makeFormScrollable.bind(this)}
                handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                customValues = {this.state.customValues}
                currentScreen="before_call"
              />
            ):null}
            
          </div>
          {phoneNumberIsNotValidError ? <div className="form-errors">Please fill required data for us to call you!</div> : null}
            {this.props.phoneNumberError ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please enter a valid phone number</div> : null}
            {this.state.error ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">{!(this.props.phoneNumberIsValid) ? "Please enter a valid phone number" : "Please fill required data for us to call you!"}</div> : null}
          {this.state.error_email ? <div style={{  marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}
        </div>
        <div className="button-group">
          <button onClick={this.makeCall} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, fontFamily:this.props.widgetSettings.widget_font, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
            <span className="button-text" >{this.props.scheduleCall ? ((this.props.widgetSettings.language_data['call-latter-button']===undefined)?"Call me later":((this.state.scheduleCallFormSecond)?"Submit":this.props.widgetSettings.language_data['call-latter-button'])) : ((this.props.widgetSettings.submit_request_call_text===undefined)?"Call me now":this.props.widgetSettings.submit_request_call_text) }</span>
          </button>
        </div>

      </React.Fragment>
    );
  }
  getDynamicJsAndCss = () => {
    if(window.dynamicJsAndCss){
        return;
    }
    window.dynamicJsAndCss = true;
    eval(this.props.widgetSettings.custom_js);
    var styles=".lime-call-popup .nav .nav-item .active{border-bottom: 3px solid "+this.props.widgetSettings.submit_request_call_color+";}"+this.props.widgetSettings.custom_css;
    var css = document.createElement('style'); 
    css.type = 'text/css';     
    css.appendChild(document.createTextNode(styles));      
    document.getElementsByTagName("head")[0].appendChild(css); 
  }
  render() {
    return (
      <React.Fragment>
           <script dangerouslySetInnerHTML={this.getDynamicJsAndCss()} type="text/javascript"></script>
         {!this.state.showFeedbackForm && !this.state.countdownBegun ? (
          <div className="call_now__top" style={{fontFamily:this.props.widgetSettings.widget_font}}>
            { (this.props.widgetSettings.company_logo!=="default")? (
              <div style={{alignItems:"center"}}>
                <img src={this.props.widgetSettings.company_logo} className="company_logo_header" />
              </div>) : null
            }
            <div className="limecall-title" style={{fontFamily:this.props.widgetSettings.widget_font }}>{(this.state.scheduleCallFormSecond)?"Let us know more about you":this.props.title}</div>
          </div>
        ) : null}
        <div className={this.state.formHolderClass}>
          {/* {console.log(this.state)} */}
          {this.state.countdownBegun ? (
            
            <CountdownTimer
              showLoader = {this.state.showLoader}
              resetCallState = {this.resetCallState.bind(this)}
              setStateMessageBinder = {this.setStateMessageBinder.bind(this)}
              scheduleCall={this.state.scheduleCall}
              showFeedbackForm={this.state.showFeedbackForm}
              showBackButton={this.state.showBackButton}
              scheduleCallFormSecond={this.state.scheduleCallFormSecond}
              currentCallComplete={this.state.currentCallComplete}
              countdownBegun = {this.state.countdownBegun}
              toggle = {this.props.toggle.bind(this)}
              pushButtonEventToGA = {this.props.pushButtonEventToGA.bind(this)}
              statusCode={this.state.statusCode}
              show_reconnect={this.state.show_reconnect}
              currentCallType= {this.state.currentCallType}
              thisCallStarted={this.state.thisCallStarted}
              enable_cf_on_after_schedule={this.props.enable_cf_on_after_schedule}
              custom_fields_after_schedule_data={this.props.custom_fields_after_schedule_data}
              enable_cf_on_after_call={this.props.enable_cf_on_after_call}
              custom_fields_after_call_data={this.props.custom_fields_after_call_data}
              custom_fields_during_call_data={this.props.custom_fields_during_call_data}
              enable_cf_on_during_call={this.props.enable_cf_on_during_call}
              afterScheduleScreen= {this.state.afterScheduleScreen}
              duringCallScreen= {this.state.duringCallScreen}
              callStatusMessage={this.state.callStatusMessage}
              callId={this.state.callId}
              rescheduleCall={this.resetForm}
              onFeedbackDone={this.resetForm}
              widgetSettings={this.props.widgetSettings}
            />
          ) : this.callForm()}
        </div>

        {processFloatLabels()}
      </React.Fragment>
    );
  }
}


export default WidgetCallForm;
