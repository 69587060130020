import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';
import Select from "react-select";

import {
    processFloatLabels,
    pushTrackingDataForLead
} from "../HelperMethods";
import {
    sendMessage,
    sendCustomFieldData,
    sendAnalyticsEvent,
    getAPIkey
} from "../WidgetAPICalls";
import CustomFields from './CustomFields';


const messageFormInitialState = {
    email: null,
    username: null,
    message: null,
    error: false,
    serverMessage: null,
    formHolderClass : "formHolder message",
    emailContainerClass: "email-container",
    nameContainerClass: "name-container",
    companyNameContainerClass: "company-name-container",
    teamContainerClass : "tel-input position-relative form-group",
    messageContainerClass : "textarea-float-container"
};


class MessageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...messageFormInitialState
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.activeTab !== nextProps.activeTab) {
            this.setState({
                ...messageFormInitialState
            });
        }
    }

    handleCustomDropdownChange = (name,selectedOption) =>{
        this.setState({
          customValues: {...this.state.customValues, [name]:selectedOption.value},
          error: false
        })
    }
    
    customFieldValueChange = (event) => {
        let tmpA = event.target.name
        let tmpB = event.target.value
        this.setState({
            customValues: {...this.state.customValues, [tmpA]:tmpB},
            error: false
        })
      }

      makeFormUnscrollable = () =>{
        this.setState({
          formHolderClass:'formHolder message overflowed-y'
        })
      }
    
      makeFormScrollable = () =>{
        this.setState({
          formHolderClass:'formHolder message'
        })
      }
    
      
    onValueChange = (event) => {
        if(event.target.name==="email"){
            this.setState({
                error_email: false,
                emailContainerClass : "email-container"
              })
            this.props.widgetSettings.email = event.target.value;
            if(event.target.value===null || event.target.value===''){
              this.props.widgetSettings.email = null;
            }
          }else if(event.target.name==="username"){
            this.setState({
                nameContainerClass : "name-container"
              })
            this.props.widgetSettings.name = event.target.value;
          }else if(event.target.name==="team"){
            this.props.widgetSettings.team = event.target.value;
          }else if(event.target.name==="message"){
              this.setState({
                messageContainerClass: "textarea-float-container"
              })
          }else if(event.target.name==="companyname"){
            this.setState({
              companyNameContainerClass: "company-name-container"
            })
            this.props.widgetSettings.companyname = event.target.value;
          }
          
            this.setState({
              [event.target.name]: event.target.value,
              error: false
            })
            if(event.target.value==='' || event.target.value===null){
              this.setState({
                [event.target.value]: null
              })
            }
          
        }

    handleTeamChange = selectedOption =>{
        // console.log(selectedOption)
        this.setState({
        selectedTeamOption:selectedOption,
        selectedTeam:selectedOption.value,
        error:false,
        teamContainerClass: "tel-input position-relative form-group"
        })
    }
    
    checkCustomRequiredCheckup(){
        if(this.props.enable_cf_on_leave_message){
            for(let tmpI = 0; tmpI<this.props.custom_fields_leave_message_data.length; tmpI++){
              var tmpField = this.props.custom_fields_leave_message_data[tmpI];
                if(tmpField['enabled']){
                  if(tmpField['required']){
                    if(tmpField['type']==="email-text-box"){
                      if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]==="" || !EmailValidator.validate(this.state.customValues[tmpField.name])){
                        return false;
                      }
                    }else{
                      if( this.state.customValues===undefined || this.state.customValues[tmpField.name]===undefined || this.state.customValues[tmpField.name]===""){
                        return false;
                      }
                    }
                  }
              }
            }
          }
        return true;
      }
    
    validateEmail = () => {
        if(!(EmailValidator.validate(this.state.email) || EmailValidator.validate(this.props.widgetSettings.email))){
          return false;
        }
        return true;
      }

    performValidations = (team) =>{
        if(!this.validateMessageForm()){
            return false;
        }
        if(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_leave_message===1 ){
            team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
            if(this.props.widgetSettings.team_required_before_call===1){
                if(!team || team===""){
                // console.log("team")
                this.setState({
                    error:true,
                    teamContainerClass: "tel-input position-relative form-group teamErrorContainer"
                })
                return false;
                }
            }
        }
        if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_leave_message_tab===1 && this.state.email!==undefined && this.state.email!==''){
            if(!this.validateEmail()){
                this.setState({
                    error_email:true,
                    emailContainerClass : "email-container errorContainer"
                  })
              return false;
            }
          }

        if(!this.checkCustomRequiredCheckup()){
            this.setState({
            error:true
            })
            return false;
        }

        return true;
    }
      
    validateMessageForm = () => {
        if(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_required_before_call===1 && this.props.widgetSettings.full_name_in_leave_message_tab===1){
            if(!this.state.username){
                this.setState({
                    error:true,
                    nameContainerClass : "name-container errorContainer"
                  })
                return false;
            }
        }
        if(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_required_before_call===1 && this.props.widgetSettings.email_in_leave_message_tab===1){
            if(!this.state.email || this.state.email===undefined || this.state.email===''){
                this.setState({
                    error_email:true,
                    emailContainerClass : "email-container errorContainer"
                  })
                return false;
            }
        }
        if(this.props.widgetSettings.company_in_leave_message===1){
          if(!this.state.companyname){
              this.setState({
                error:true,
                companyNameContainerClass : "company-name-container errorContainer"
              })
              return false;
          }
        }

        if(!this.state.message){
            this.setState({
                error:true,
                messageContainerClass : "textarea-float-container errorContainer"
              })
            return false;
        }
        return true;
    }

    submitFeedback = () => {
        this.props.pushButtonEventToGA();
        var team = null;
        team = !(this.state.selectedTeam)?this.props.widgetSettings.selectedTeam:this.state.selectedTeam;
        if((!this.state.email && this.props.widgetSettings.email) || (!this.state.username && this.props.widgetSettings.name)){
            var temp_email = '';
            var temp_name = '';
            var temp_company_name = '';
            if(!this.state.email && this.props.widgetSettings.email){
              temp_email = this.props.widgetSettings.email;
            }else{
              temp_email = this.state.email;
            }
            if(!this.state.username && this.props.widgetSettings.name){
              temp_name = this.props.widgetSettings.name;
            }else{
              temp_name = this.state.username;
            }
            if(!this.state.companyname && this.props.widgetSettings.companyname){
              temp_company_name = this.props.widgetSettings.companyname;
            }else{
              temp_company_name = this.state.companyname;
            }
            this.setState({
              email:temp_email,
              username:temp_name,
              companyname:temp_company_name
            },()=> {
               if(!this.performValidations(team)){
                  return;
                }
              })
      
          }else{
            if(!this.performValidations(team)){
              return;
            }
          }

        var apikey = null;
        var agent_id = null;
        if(this.props.widgetSettings.widget_type===2){
            apikey = this.props.widgetSettings.pId;
            agent_id = getAPIkey();
        }else{
            apikey = getAPIkey();
        }

        const postData = {
            email: !(this.state.email)?this.props.widgetSettings.email:this.state.email,
            name: !(this.state.username)?this.props.widgetSettings.name:this.state.username,
            message: !(this.state.message)?this.props.widgetSettings.message:this.state.message,
            company_name: !(this.state.companyname)?this.props.widgetSettings.companyname:this.state.companyname,
            team: team,
            customer_utm:this.props.widgetSettings.utm_data,
            source: this.props.widgetSettings.source,
            pId:apikey,
            agent_id:agent_id,
            ...this.state.customValues
        };


        if(this.state.customValues!==undefined){
            if(this.props.widgetSettings.google_analytics===1){
                const params ={
                type: 'custom_field',
                pId:apikey,
                userIp: this.props.widgetSettings.ip_address
                };
                sendAnalyticsEvent(params);
            }
          }

        if (this.validateMessageForm()) {
            this.setState({
                showLoader: true,
            });
            sendMessage(postData).then((response) => {
                this.setState({
                    showLoader: false,
                    serverMessage: this.props.widgetSettings.language_data['thanks-after-leave-message']
                });
                pushTrackingDataForLead(response,this.props.widgetSettings);
                if(this.props.enable_cf_on_leave_message){
                    const afterMessageCustomData = {
                      call_id:response,
                      ...this.state.customValues
                    };
                    sendCustomFieldData(afterMessageCustomData).then(() => {
                    }).catch((error) => {
                      console.log(error);
                    });
                  }
            }).catch((errorMsg) => {
                this.setState({
                    serverMessage: errorMsg
                });
            });
        } 
    }
    resetCallState(){
        this.setState({
            serverMessage: undefined
        });
    }

  isEmptySettings(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }
    renderMessageForm = () => {
        // const errorStyle = { border: '1px solid red', borderRadius: "5px" };
        if(this.isEmptySettings(this.props.widgetSettings)){
            return;
        }
        let teamOptions = [];
        let teamsData =  this.props.widgetSettings.widget_teams;
        if(teamsData.length>0){
            for(let i = 0; i<teamsData.length; i++){
              teamOptions.push({value:teamsData[i].id,label:teamsData[i].name});
            }
        }
        return ( 
            (!this.state.showLoader)
                ?
                (<div className={this.state.formHolderClass}>
                <div className="tel-input form__form-group">
                    {(this.props.widgetSettings.full_name_enabled_before_call===1 && this.props.widgetSettings.full_name_in_leave_message_tab===1) ? [
                        (this.props.widgetSettings.full_name_required_before_call===1) ? (
                            <div id="floatContainer7" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                                <i className="material-icons custom-icon-input">account_circle</i>
                                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name *" id="limeUserName4" />
                            </div>
                        ) : (
                            <div id="floatContainer7" className={this.state.nameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                                <i className="material-icons custom-icon-input">account_circle</i>
                                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.name} onChange={event => this.onValueChange(event)} name="username" placeholder="Name" id="limeUserName4" />
                            </div>
                        )
                        ] : null}
                    {(this.props.widgetSettings.email_enabled_before_call===1 && this.props.widgetSettings.email_in_leave_message_tab===1) ? [
                        (this.props.widgetSettings.email_required_before_call===1) ? (
                            <div id="floatContainer6" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                                <i className="material-icons custom-icon-input">email</i>
                                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email *" id="limeUserEmail4" />
                            </div>
                        ) : (
                            <div id="floatContainer4" className={this.state.emailContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                                <i className="material-icons custom-icon-input">email</i>
                                <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="email" required  value={this.props.widgetSettings.email} onChange={event => this.onValueChange(event)} name="email" placeholder="Email" id="limeUserEmail3" />
                            </div>
                        )
                        ] : null}
                    {(this.props.widgetSettings.team_enabled_before_call===1 && this.props.widgetSettings.team_in_leave_message===1 )? (
                       <div className={this.state.teamContainerClass} style={{fontFamily:this.props.widgetSettings.widget_font}}>
                            <Select
                                className="team-dropdown-select-mod"
                                value={this.state.selectedTeamOption}
                                placeholder={(this.props.widgetSettings.team_required_before_call===1) ? "I want to talk to: *":"I want to talk to:"}
                                options={teamOptions}
                                inputId="teamSelectorLmessage"
                                components={{
                                IndicatorSeparator: () => null
                                }}
                                isSearchable={ false }
                                onMenuOpen={this.makeFormUnscrollable}
                                onMenuClose={this.makeFormScrollable}
                                onChange={this.handleTeamChange}
                                classNamePrefix="team-dropdown-select-mod"
                            />
                        </div>
                     ) : null}
                    
                    {(this.props.widgetSettings.company_in_leave_message===1 ) ? 
                      (<div id="floatContainer4_1" className={this.state.companyNameContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                        <input style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px"}} type="text" required defaultValue={this.props.widgetSettings.companyname} onChange={event => this.onValueChange(event)} name="companyname" placeholder="Company name *" id="limeCompanyName2" />
                      </div>
                      ): null }

                    {(this.props.enable_cf_on_leave_message)?(
                        <CustomFields
                            widgetSettings={this.props.widgetSettings}
                            tab={this.props.currentTab}
                            customFieldsData={this.props.custom_fields_leave_message_data}
                            currentTab = {this.props.currentTab}
                            customFieldValueChange = {this.customFieldValueChange.bind(this)}
                            makeFormUnscrollable = {this.makeFormUnscrollable.bind(this)}
                            makeFormScrollable = {this.makeFormScrollable.bind(this)}
                            handleCustomDropdownChange = {this.handleCustomDropdownChange.bind(this)}
                            currentScreen="leave_message"
                        />
                    ):null}
                    <div id="textareafloatContainer6" className={this.state.messageContainerClass} style={{fontFamily:(this.props.widgetSettings.widget_font)}}>
                        <label htmlFor="message">Your message <span className="lime-label-required" >*</span></label>
                        <textarea style={{fontFamily:(this.props.widgetSettings.widget_font), fontSize:"13px", paddingTop:"20px"}} required  value={this.props.widgetSettings.message} onChange={event => this.onValueChange(event)} name="message" data-placeholder="Your message" id="message" >
                            { this.props.widgetSettings.message }
                        </textarea>
                    </div>

                    { this.state.error ? 
                    < div style = {{  marginBottom: '10px' } }
                            className = "form-errors" > Please help us with your valid information!
                        </div> : null}
                    {this.state.error_email ? <div style={{ marginBottom: '10px' ,fontWeight:'600'}} className="form-errors">Please fill a valid email address.</div> : null}

                    <div className="button-group">
                        <button onClick = {() => this.submitFeedback() } type="button" 
                            style={{ 'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color, fontFamily:(this.props.widgetSettings.widget_font) }}>
                                <span className="button-text"> { this.props.widgetSettings.language_data['leave-message-button'] } </span> 
                        </button>
                    </div>
                    </div>
                </div>)
                :
                (<div style={{width:'100%'}}><img alt="loadder" src="https://cdn-images-1.medium.com/max/1600/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" style={{width:'inherit'}} /></div>)
              
            );
        }

        render() {
            return ( 
                <React.Fragment> {
                    this.state.serverMessage ? (
                        <div>
                        <div className = "call_now__top" >
                        { (this.props.widgetSettings.company_logo!=="default")? (
                          <div style={{alignItems:"center"}}>
                            <img src={this.props.widgetSettings.company_logo} className="company_logo_header" />
                          </div>) : null
                        }
                        <div className = "limecall-title" style={{margin:"auto", paddingBottom:"10px", fontFamily:(this.props.widgetSettings.widget_font), fontSize:"26px"}} > { this.state.serverMessage } </div> </div>
                        <div className="button-group" style={{marginTop:'10px'}}>
                        <button  onClick={() => this.resetCallState()} style={{'backgroundColor': this.props.widgetSettings.submit_request_call_color, 'borderColor': this.props.widgetSettings.submit_request_call_color }}>
                            <span className="button-text" >Back</span>
                        </button>
                        </div>
                        
                        </div>
                    ) : ( <React.Fragment>
                        <div className = "call_now__top" >
                        { (this.props.widgetSettings.company_logo!=="default")? (
                          <div style={{alignItems:"center"}}>
                            <img src={this.props.widgetSettings.company_logo} className="company_logo_header" />
                          </div>) : null
                        }
                        <div className = "limecall-title" style={{margin:"auto", paddingBottom:"10px", fontFamily:(this.props.widgetSettings.widget_font), fontSize:"26px"}} > { ((this.state.showLoader)?"":this.props.title) } </div> 
                        
                        </div> { this.renderMessageForm() }
                        </React.Fragment>
                    )
                }
                {processFloatLabels()}
                </React.Fragment>
            );
        }
    }


    export default MessageForm;